import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, FormControl, Checkbox, Grid, Button, Switch } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from '../attributes/EditAttributesView';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import FormLabel from '@material-ui/core/FormLabel';
import { addEditString } from '../common/stringUtils';
import { getOdometerValue } from '../map/mapUtil';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const MaintenancePage = () => {
  const classes = useStyles();
  const history = useHistory();

  const [item, setItem] = useState({});

  moment.locale((global && global.language) ? global.language.replace('pt', 'pt-br').replace('en', 'en-gb').replace('ar', 'en-gb') : 'en-gb');

  var devices = useSelector(state => Object.values(state.devices.items));
  var positions = useSelector(state => Object.values(state.positions.items));

  //var deviceId = history.location.data.selectedId;
  var deviceId = global.extraData;

  if (!deviceId) history.push(`/management/summary`)
  
  const get_device_name = (id) => {
    var result = ''
    devices.forEach(function(device, index) {
      if (device.id == id) {
        result = device.attributes.license_plate
      }
    });
    return result;
  }
  
  const get_device_current_km = (deviceId) => {
    var result = ''
    positions.forEach(function(position, index) {
      if (position.deviceId == deviceId) {
        result = getOdometerValue(position.attributes, false)
      }
    });
    return result;
  }
  
  const handleToggleMaintenanceComplete = () => {
    setItem({...item, complete: item.complete == 0 ? 1 : 0})
  }
  
  const handleSetCurrentKm = () => {
    setItem({...item, km: get_device_current_km(deviceId)})
  }

  useEffect(() => {
    if (!item.hasOwnProperty('date_start')) {
      item.date_start = moment().format('YYYY-MM-DD')
    }
    if (!item.hasOwnProperty('date_end')) {
      item.date_end = moment().format('YYYY-MM-DD')
    }
    if (item.hasOwnProperty('date_end') && item.date_end == null) {
      item.date_end = moment().format('YYYY-MM-DD')
    }
    if (!item.hasOwnProperty('complete')) {
      item.complete = 0
    }
  }, [item]);

  return (
    <EditItemView endpoint={`../report/api.php/maintenance/${deviceId}`} item={item} setItem={setItem} title={ t('maintenances') + ' > ' + get_device_name(deviceId) + ' > ' + addEditString(t('addMaintenance'), t('editMaintenance')) }>
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>

              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Início da manutenção"
                  format="DD/MM/yyyy"
                  ampm={false}
                  variant="dialog"
                  value={item.date_start}
                  onChange={event => setItem({...item, date_start: event.format('YYYY-MM-DD')})}
                />
              </MuiPickersUtilsProvider>

              <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                <Switch
                  color="primary"
                  onClick={handleToggleMaintenanceComplete}
                  checked={item.complete == 1}
                />
                <FormLabel>Manutenção concluída</FormLabel>                
              </div>
              
              {
                item.complete == 1 &&
                <>
                  <MuiPickersUtilsProvider utils={MomentUtils} >
                    <DatePicker
                      label="Fim da manutenção"
                      format="DD/MM/yyyy"
                      ampm={false}
                      variant="dialog"
                      value={item.date_end}
                      onChange={event => setItem({...item, date_end: event.format('YYYY-MM-DD')})}
                    />
                  </MuiPickersUtilsProvider>             
                </>
              }

              <TextField
                margin="normal"
                variant="outlined"
                multiline
                value={item.description || ''}
                onChange={event => setItem({...item, description: event.target.value})}
                label="Descrição da manutenção"
                />

              <Grid container spacing={0}>
                <Grid item>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    value={item.km || ''}
                    onChange={event => setItem({...item, km: event.target.value})}
                    label="Km do veículo"
                    />
                </Grid>
                <Grid item style={{position: 'relative'}}>
                  <Button
                    variant="outlined"
                    style={{position: 'absolute', bottom: '8px', left: '10px'}}
                    color="primary"
                    onClick={handleSetCurrentKm}>Km&nbsp;Actuais
                    </Button>
                </Grid>  
              </Grid>

              <TextField
                margin="normal"
                variant="outlined"
                value={item.periodicity_km || ''}
                onChange={event => setItem({...item, periodicity_km: event.target.value})}
                label="Periodicidade (Km)"
                />

              <TextField
                margin="normal"
                variant="outlined"
                multiline
                value={item.location || ''}
                onChange={event => setItem({...item, location: event.target.value})}
                label="Nome da oficina"
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.cost || ''}
                onChange={event => setItem({...item, cost: event.target.value})}
                label={"Custo (" + global.currency + ")"}
                />
                
              <TextField
                margin="normal"
                variant="outlined"
                multiline
                value={item.invoice || ''}
                onChange={event => setItem({...item, invoice: event.target.value})}
                label="Nr. da factura"
                />

            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default MaintenancePage;
