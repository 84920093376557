import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles, IconButton, Button } from '@material-ui/core';

import t from '../common/localization';
import { formatDate, formatBigNumber, formatTimeAgo } from '../common/formatter';
import SetKm from '../SetKm';
import SetBlocking from '../SetBlocking';

import StreetImage from './StreetImage';

import EditIcon from '@material-ui/icons/Edit';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import BlockIcon from '@material-ui/icons/Block';
import SignalCellularConnectedNoInternet4BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet4Bar';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCarBattery } from '@fortawesome/free-solid-svg-icons'
import { faGasPump } from '@fortawesome/free-solid-svg-icons'
import { faChargingStation } from '@fortawesome/free-solid-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faHandPaper } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faRoad } from '@fortawesome/free-solid-svg-icons'
import { faSatelliteDish } from '@fortawesome/free-solid-svg-icons'
import { faThermometerFull } from '@fortawesome/free-solid-svg-icons'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { faDrawPolygon } from '@fortawesome/free-solid-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import { getFuelColor, getIgnitionColor, getVehicleIconColor, getBatteryColor, getBatteryValue, getFuelValue, getFuelUnit, getTemperatureValue, immobilizationState, getImmobilizationColor, getOdometerTypeCanBus, getOdometerValue, getDeviceIconPath, getClosestGeofence, getDeviceStatus, minutesToHM, timestampToDate } from './mapUtil'

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BuildIcon from '@material-ui/icons/Build';

import VideocamIcon from '@material-ui/icons/Videocam';

const useStyles = makeStyles(() => ({
  menuItem: {
    fontSize: '1rem',
    fontFamily: 'Proxima Nova Regular,Roboto,Arial',
    fontWeight: '600',
    lineHeight: '1.5'
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0 }, 
  },  
  blink: {
      animationName: '$blinker',
      animationDuration: '0.5s',
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinite',
      animationDirection: 'alternate',
  },
}));

const DeviceStatusView = ({
  device, 
  position,
  driver,
  tags,
  onEditClick, 
  onFixKmClick, 
  onHistoryClick, 
  onFollowClick, 
  onRefillsClick, 
  onBlockClick,
  setCameraDialogOpen,
}) => {
  const classes = useStyles();

  const session = useSelector((state) => state.session);
  const temporaryAccess = useSelector(state => state.session.user && state.session.user.email.startsWith('temp_') );
  
  const geofences = useSelector(state => Object.values(state.geofences.items));
  const [closestGeofence, setClosestGeofence] = React.useState({name: false, type: false});

  const [showExtraInfo , setShowExtraInfo ] = React.useState(global.showExtraInfo[device.id]);
  const [showLiters, setShowLiters] = React.useState(global.showLiters[device.id]);
  const [levelDisplay, setLevelDisplay] = React.useState(global.levelDisplay[device.id] || 0);
  const [showHours, setShowHours] = React.useState(device && device.attributes && device.attributes.show_hours);
  const [tachoAvailable, setTachoAvailable] = React.useState(position && position.attributes && position.attributes.io48 && position.attributes.io48>0);

  useEffect(() => {
    setClosestGeofence(getClosestGeofence(geofences, position.latitude, position.longitude, true, true))
  }, [])

  const handleEdit = (e) => {
    e.preventDefault();
    closeMenu();
    onEditClick(device.id);
  };

  const handleSetKm = (e) => {
    e.preventDefault();
    closeMenu();
    setSetKmShown(true);
    //onFixKmClick(device.id);
  };

  const handleHistory = (e) => {
    e.preventDefault();
    closeMenu();
    onHistoryClick(device.id);
  };

  const handleFollowDevice = (e) => {
    e.preventDefault();
    closeMenu();
    onFollowClick(device);
  };

  const handleRefills = (e) => {
    e.preventDefault();
    closeMenu();
    onRefillsClick(device.id);
  };

  const handleBlock = (e) => {
    e.preventDefault();
    closeMenu();
    setBlockingShown(true);
  };

  const handleCamera = (e) => {
    e.preventDefault();
    closeMenu();
    setCameraDialogOpen(device.id)
  };

  const toggleLevelDisplay = (e) => {
    e.preventDefault();
    closeMenu();
    const maxLevelDisplay = device.attributes.fuel_type==1 ? 2 : 1;
    global.levelDisplay[device.id] = levelDisplay>=maxLevelDisplay ? 0 : levelDisplay + 1;
    setLevelDisplay(global.levelDisplay[device.id])
  };

  const toggleShowHours = (e) => {
    e.preventDefault();
    closeMenu();
    setShowHours(!showHours)
  };

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const closeMenu = () => {
    setMenuAnchorEl(null);
  }

  const [setKmShown, setSetKmShown] = useState(false);
  const [blockingShown, setBlockingShown] = useState(false);

  const handleCloseSetKm = () => {
    setSetKmShown(false);
  }
  const handleCloseSetBlocking = () => {
    setBlockingShown(false);
  }

  const userHasPermission = (permission) => {
    return session.user.attributes.permissions && session.user.attributes.permissions.includes(permission)
  }

  const getWorkingState = (workingState) => {
    switch (workingState) {
      case 0:
        return "Pausa/Repouso" //Rest
        break;
      case 1:
        return "Disponível" //Driver available
        break;
      case 2:
        return "Trabalho" //Work
        break;
      case 3:
        return "Condução" //Drive
        break;
      case 6:
        return "Erro" //Error
        break;
      case 7:
        return "Indisponível" //Not available
        break;
      default:
        return "-"
        break;
    }
  }

  return (
    <>
      <SetKm
        open={setKmShown}
        setOpen={handleCloseSetKm}
        deviceId={device.id}
        />

      <SetBlocking
        open={blockingShown}
        setOpen={handleCloseSetBlocking}
        deviceId={device.id}
        />

    <div style={{ display: 'flex', cursor: 'default' }}>
      <table style={{ padding: '10px 0px 0px 0px', fontFamily: 'Proxima Nova Regular', fontWeight: '600', float: 'left' }}>
        <tbody>
            <tr>
              <td colSpan={2} style={{ textAlign: 'center', paddingTop: '5px' }}>
                <StreetImage position={position}/>
              </td>
            </tr>
          <tr>
            <td colSpan={2} style={{ textAlign: 'left', paddingTop: '0px' }}>
              <div style={{height: '35px', fontWeight: 'bold', fontSize: '1.1rem', display: 'contents'}}>
                <span style={{ paddingTop: '12px' }}>
                { device.name }
                { tags.length>0 ?
                  <span style={{ color: '#78A9FF', fontWeight: 'bold' }}>
                    &nbsp;+&nbsp;{tags.join(', ')}
                  </span>
                  :
                  ""
                }
                </span>
              </div>
            </td>
          </tr>
          <tr style={{ paddingTop: '5px' }}>
            <td colSpan={2} style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '0.75rem' }}>
              <span>
                { device.attributes.license_plate == device.name ? "" : device.attributes.license_plate}
              </span>
            { !temporaryAccess &&
              <Button
                variant="outlined"
                style={{color: '#78A9FF', fontSize: '10px', padding: '0px 10px', float: 'right'}}
                color="primary"
                onClick={ (event) => {setMenuAnchorEl(event.currentTarget)} }
                >
                  {t('options')}
              </Button>
            }
            </td>
          </tr>
          <tr>
            <td>
              <div style={{textAlign: 'center', paddingLeft: '5px', paddingRight: '10px'}}>
                <ListItemAvatar>
                  <Avatar style={{
                    backgroundColor: 'transparent',
                    borderStyle: 'solid',
                    borderColor: getIgnitionColor(position)+'30',
                    borderWidth: '1px',
                    width: '60px',
                    height: '60px',
                    margin: '0 auto'
                }}>
                    <img src={ getDeviceIconPath(device.category, position) } alt="" style={{ width: '50px', height: '50px' }}/>
                  </Avatar>
                </ListItemAvatar>
                <div style={{fontSize: '1em', color: getIgnitionColor(position) }}>
                  {!isNaN(position.speed) ? Math.round(position.speed*1.852) + " km/h" : ''}
                </div>
              </div>
            </td>
            <td style={{ maxWidth: '250px' }}>
              {driver && driver.name &&
                <div style={{display: 'flex', marginBottom: '2px'}}>
                  <div style={{textAlign: 'center', minWidth: '15px'}}>
                    <FontAwesomeIcon icon={faUser}  style={{color: '#78A9FF'}}/>
                  </div>
                  <div style={{float: 'right', paddingLeft: '5px'}}>
                    { driver.name }
                    { driver.attributes && driver.attributes.phone && !driver.name.includes(driver.attributes.phone) &&
                      <span>
                        &nbsp;(
                        <FontAwesomeIcon icon={faPhoneAlt} style={{marginLeft: '2px', color: '#78A9FF', fontSize: '0.7rem'}}/>
                        &nbsp;
                        <a href={'tel:' + driver.attributes.phone} style={{ marginRight: '2px', textDecoration: 'none' }}>
                          {driver.attributes.phone}
                        </a>
                        )
                        
                      </span>
                    }
                  </div>
                </div>
              }

              <div style={{display: 'flex', marginBottom: '2px'}}>
                <div style={{textAlign: 'center', minWidth: '15px'}}>
                  <FontAwesomeIcon icon={faHome}  style={{color: '#78A9FF'}}/>
                </div>
                <div style={{float: 'right', paddingLeft: '5px'}}>
                  <a href={'https://www.google.com/maps/place/' + position.latitude + ',' + position.longitude + '/@' + position.latitude + ',' + position.longitude + ',12z'} target='_blank' style={{color: 'black', textDecoration: 'none'}}>
                  { position.address }
                  </a>
                </div>
              </div>

              { closestGeofence.name &&
                <div style={{display: 'flex', marginBottom: '2px'}}>
                  <div style={{textAlign: 'center', minWidth: '15px'}}>
                    <FontAwesomeIcon icon={closestGeofence.type == "point" ? faMapMarker : faDrawPolygon}  style={{color: '#78A9FF'}}/>
                  </div>
                  <div style={{float: 'right', paddingLeft: '5px'}}>
                    <a href={'https://www.google.com/maps/place/' + position.latitude + ',' + position.longitude + '/@' + position.latitude + ',' + position.longitude + ',12z'} target='_blank' style={{color: 'black', textDecoration: 'none'}}>
                      { closestGeofence.name }
                    </a>
                  </div>
                </div>
              }

              <div style={{display: 'flex', marginBottom: '2px'}}>
                <div style={{textAlign: 'center', minWidth: '15px'}}>
                  <FontAwesomeIcon icon={faGlobe}  style={{color: '#78A9FF'}}/>
                </div>
                <div style={{float: 'right', paddingLeft: '5px'}}>
                  { position.latitude.toFixed(6) + ', ' + position.longitude.toFixed(6) }
                    <a className="tooltip" href={'https://www.google.com/maps/place/' + position.latitude + ',' + position.longitude + '/@' + position.latitude + ',' + position.longitude + ',12z'} target='_blank' style={{color: 'black', textDecoration: 'none', marginLeft: '5px', position: 'relative', top: '2px', fontSize: '1rem'}}>
                      <FontAwesomeIcon icon={faLocationArrow}  style={{color: '#78A9FF'}}/>
                      <span className="tooltiptext" style={{ marginLeft: '0px' }}>
                        Navegar
                      </span>
                    </a>
                </div>
              </div>

              <div style={{display: 'flex', marginBottom: '2px', cursor: 'default'}} className="tooltip">
                <span className="tooltiptext">
                  { t('lastPosition') + ': ' + formatDate(position.fixTime) }
                </span>
                <div style={{textAlign: 'center', minWidth: '15px'}}>
                  <FontAwesomeIcon icon={faInfoCircle} style={{color: '#78A9FF'}}/>
                </div>
                <div style={{float: 'right', paddingLeft: '5px'}}>
                  { getDeviceStatus(position) }
                </div>                  
              </div>

            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <div style={{marginBottom: '5px', marginTop: '5px'}}>
                <div style={{float: 'left'}}>
                  <span style={{ marginLeft: '20px', color: getBatteryColor(position.attributes) }}>
                    <span className="tooltip">
                      <span className="tooltiptext">
                        { t('batteryStatus') }
                      </span>
                      <span className={ getBatteryColor(position.attributes) == '#990000' ? classes.blink : null }>
                        <FontAwesomeIcon icon={faCarBattery} /> { getBatteryValue(position.attributes, device.attributes) }
                      </span>
                    </span>
                  </span>

                  { getFuelValue(position, device) &&
                    (
                      <span onClick={toggleLevelDisplay}>
                        <span style={ device.attributes && device.attributes.fuel_type==1 ? {
                          marginLeft: '20px',
                          cursor: 'pointer',
                          color: getFuelColor(position, device),

                          padding: '5px 10px 5px 10px',
                          borderRadius: '15px',
                          backgroundColor: 'rgba(120, 169, 255, 0.20)',
                          borderRadius: '15px',
                          whiteSpace: 'nowrap',
                        } : {
                          marginLeft: '20px',
                          cursor: 'pointer',
                          color: getFuelColor(position, device),
                        }}>
                          <span className="tooltip">
                            <span className="tooltiptext">
                              { device.attributes && device.attributes.fuel_type==1 ? t('batteryLevel') : t('fuelLevel') }
                            </span>
                            <span className={ getFuelColor(position, device) == '#990000' ? classes.blink : null }>
                              <FontAwesomeIcon icon={device.attributes && device.attributes.fuel_type==1 ? faChargingStation : faGasPump} />
                              &nbsp;
                              { getFuelValue(position, device, levelDisplay) }
                              &nbsp;
                              { getFuelUnit(device, levelDisplay) }
                            </span>
                          </span>
                        </span>
                      </span>
                    )
                  }

                  { getTemperatureValue(position.attributes) &&
                    (
                      <span style={{ marginLeft: '20px', color: '#000099' }}>
                        <FontAwesomeIcon icon={faThermometerFull} /> { getTemperatureValue(position.attributes) }
                      </span>
                    )
                  }

                </div>
                <div style={{float: 'right', paddingLeft: '20px', color: getIgnitionColor(position.attributes.ignition, position.speed*1.852)}}>
                  
                  { 
                      device.attributes.in_maintenance &&
                      (
                        <span style={{ marginLeft: '20px' }} className={classes.blink} title={t('inMaintenance')}>
                          <BuildIcon style={{fontSize: 14, color: '#BB0000'}}/>
                        </span>
                      )
                  }

                  { !position.valid &&
                    position.attributes.hasOwnProperty('event') && position.event!=150 && position.event!=151 && position.event!=152 &&
                    !(position.attributes.io200 > 0) &&
                    (
                      <span style={{ marginLeft: '20px'}}>
                        <span className="tooltip">
                          <span className="tooltiptext">
                            { t('noGpsSignal') }
                          </span>
                          <span className={classes.blink}>
                            <FontAwesomeIcon icon={faSatelliteDish}  style={{color: '#BB0000'}}/>
                          </span>
                        </span>
                      </span>
                    )
                  }

                  { position.attributes.hasOwnProperty('rssi') && position.attributes.rssi<=1 &&
                    (
                      <span style={{ marginLeft: '20px'}}>
                        <span className="tooltip">
                          <span className="tooltiptext">
                            { t('lowGsmSignal') }
                          </span>
                          <span className={classes.blink}>
                            <SignalCellularConnectedNoInternet4BarIcon style={{fontSize: 16, color: '#BB0000'}}/>
                          </span>
                        </span>
                      </span>
                    )
                  }

                  { device.attributes.has_immobilization == 1 && immobilizationState(device, position) &&
                      (
                        <span style={{ marginLeft: '20px', color: getImmobilizationColor(true) }} className={global.pendingCommands.includes(position.id) ? classes.blink : ''} title={t('blockedVehicle')}>
                          <FontAwesomeIcon icon={faLock} />&nbsp;
                        </span>
                      )
                  }

                  { device.attributes.has_immobilization == 1 && !immobilizationState(device, position) &&
                      (
                        <span style={{ marginLeft: '20px', color: getImmobilizationColor(false) }} className={global.pendingCommands.includes(position.id) ? classes.blink : ''} className="tooltip">
                          <span className="tooltiptext">
                            { t('unBlockedVehicle') }
                          </span>
                          <FontAwesomeIcon icon={faLockOpen} />&nbsp;
                        </span>
                      )
                  }

                  <span
                    className="tooltip"
                    onClick={toggleShowHours}
                    style={{ marginLeft: '20px', marginRight: '10px', color: '#000000', cursor: 'pointer' }}
                    >
                    { !showHours &&
                      <>
                        <span className="tooltiptext">
                          { t('odometerVirtual') + (getOdometerTypeCanBus(position.attributes) ? " (CAN-Bus)" : " (GPS)")}
                        </span>
                        <FontAwesomeIcon icon={faRoad} /> { getOdometerValue(position.attributes) }
                      </>
                    }
                    { showHours &&
                      <>
                        <span className="tooltiptext">
                          { t('hoursVirtual') }
                        </span>
                        <FontAwesomeIcon icon={faClock} /> { position.attributes.hours ? Math.floor(position.attributes.hours/1000/3600) + " h" : '' }
                      </>
                    }                   
                  </span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      { position && position.attributes && position.attributes.io48>0 &&
        <div style={{ display: 'inline', fontFamily: 'Proxima Nova Regular', float: 'right', borderLeft: '2px solid rgba(0,0,0,0.2)', marginLeft: '5px', paddingLeft: '5px' }}>
          <table>
            <tbody>

              <tr style={{lineHeight: 1, marginBottom: '10px'}}>
                <td colspan="2">
                  <h3>
                    TACÓGRAFO
                  </h3>
                </td>
              </tr>

              { position.attributes.hasOwnProperty('io194') && //Timestamp
              <tr style={{lineHeight: 0.85}}>
                <td>Hora do tacógrafo:</td>
                <td>{timestampToDate(position.attributes.io194)}</td>
              </tr>
              }

              { position.attributes.hasOwnProperty('io192') && //Odometer
                <tr style={{lineHeight: 0.85}}>
                  <td>Odómetro:</td>
                  <td>{Math.round(position.attributes.io192/100)/10} km</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io184') && //Driver 1 Working State
                <tr style={{lineHeight: 0.85}}>
                  <td>Actividade actual:</td>
                  <td>{getWorkingState(position.attributes.io184)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io187') && //Driver 1 Card Presence
                <>
                  <tr style={{lineHeight: 0.85}}>
                    <td>Cartão inserido:</td>
                    <td>{position.attributes.io187 ? position.attributes.driverUniqueId : 'Não'}</td>
                  </tr>
                  { position.attributes.io187>0 && driver.name &&
                    <tr style={{lineHeight: 0.85}}>
                      <td></td>
                      <td>{driver.name}</td>
                    </tr>
                  }
                </>
              }

            </tbody>
          </table>

          <p></p>

          <table>
            <tbody>

              { position.attributes.hasOwnProperty('io56') && //Driver 1 Continuous Driving Time
                <tr style={{lineHeight: 0.85}}>
                  <td>Tempo de condução contínua:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io56)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io58') && //Driver 1 Cumulative Break Time
                <tr style={{lineHeight: 0.85}}>
                  <td>Tempo de pausa acumulada:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io58)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io69') && //Driver 1 Cumulative Driving Time
                <tr style={{lineHeight: 0.85}}>
                  <td>Tempo de condução acumulada:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io69)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io10507') && //Driver 1 current daily driving time
                <tr style={{lineHeight: 0.85}}>
                  <td>Tempo de condução diária:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io10507)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io10508') && //Driver 1 current weekly driving time
                <tr style={{lineHeight: 0.85}}>
                  <td>Tempo de condução semanal:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io10508)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io10509') && //Driver 1 time left until new daily rest period
                <tr style={{lineHeight: 0.85}}>
                  <td>Tempo restante até ao próximo repouso diário:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io10509)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io10522') && //Driver 1 Time Left Until New Weekly Rest Period
                <tr style={{lineHeight: 0.85}}>
                  <td>Tempo restante até ao próximo repouso semanal:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io10522)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io10524') && //Driver 1 Minimum Daily Rest
                <tr style={{lineHeight: 0.85}}>
                  <td>Repouso mínimo diário:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io10524)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io10526') && //Driver 1 Minimum Weekly Rest
                <tr style={{lineHeight: 0.85}}>
                  <td>Repouso mínimo semanal:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io10526)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io10530') && //Driver 1 Remaining Time Until Next Break Or Rest
                <tr style={{lineHeight: 0.85}}>
                  <td>Tempo restante até à próxima pausa/repouso:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io10530)}</td>
                </tr>
              }

              { position.attributes.hasOwnProperty('io10528') && //Driver 1 Duration Of Next Break Rest
                <tr style={{lineHeight: 0.85}}>
                  <td>Duração da próxima pausa/repouso:</td>
                  <td style={{fontFamily: 'monospace'}}>{minutesToHM(position.attributes.io10528)}</td>
                </tr>
              }

              <tr style={{height: '15px'}}>
                <td></td>
              </tr>

            </tbody>
          </table>
        </div>
      }
    </div>

      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={closeMenu}
        >
        
        <MenuItem onClick={handleEdit}>
          <ListItemIcon style={{minWidth: '30px'}}>
            <EditIcon style={{color: '#78A9FF' }} />
          </ListItemIcon>
          <div className={classes.menuItem}>
            {t('edit')}
          </div>
        </MenuItem>

        { session.user && session.user.hasOwnProperty('userLimit') && session.user.userLimit != 0 &&
        <MenuItem onClick={handleSetKm}>
          <ListItemIcon style={{minWidth: '30px'}}>
            <FontAwesomeIcon icon={faRoad} style={{color: '#78A9FF' }} />
          </ListItemIcon>
          <div className={classes.menuItem}>
            {t('fixKm')}
          </div>
        </MenuItem>
        }

        { device.attributes.has_immobilization && device.attributes.has_immobilization == "1" && (session.user && !session.user.readonly) && userHasPermission('global.block') &&
        <MenuItem onClick={handleBlock}>
          <ListItemIcon style={{minWidth: '30px'}}>
            <BlockIcon style={{color: '#78A9FF' }} />
          </ListItemIcon>
          <div className={classes.menuItem}>
            {t('blocking')}
          </div>
        </MenuItem>
        }

        <MenuItem onClick={handleHistory}>
          <ListItemIcon style={{minWidth: '30px'}}>
            <AccessTimeIcon style={{color: '#78A9FF' }} />
          </ListItemIcon>
          <div className={classes.menuItem}>
            {t('seeHistory')}
          </div>
        </MenuItem>

        <MenuItem onClick={handleFollowDevice}>
          <ListItemIcon style={{minWidth: '30px'}}>
            <VisibilityIcon style={{color: '#78A9FF' }} />
          </ListItemIcon>
          <div className={classes.menuItem}>
            {t('followVehicle')}
          </div>
        </MenuItem>

        <MenuItem onClick={handleRefills}>
          <ListItemIcon style={{minWidth: '30px'}}>
            <LocalGasStationIcon style={{color: '#78A9FF' }} />
          </ListItemIcon>
          <div className={classes.menuItem}>
            {t('refills')}
          </div>
        </MenuItem>

        { device.attributes.device_type == "13" &&
        <MenuItem onClick={handleCamera}>
          <ListItemIcon style={{minWidth: '30px'}}>
            <VideocamIcon style={{color: '#78A9FF' }} />
          </ListItemIcon>
          <div className={classes.menuItem}>
            {t('cameras')}
          </div>
        </MenuItem>
        }

      </Menu>

    </>
  );
};

export default DeviceStatusView;
