import React, { useState, useEffect, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import MaterialTable from 'material-table'
import { makeStyles, Button, IconButton, Paper, Switch, Typography } from '@material-ui/core';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import MainToolbar from '../MainToolbar';
import MenuTitle from '../MenuTitle';
import t from '../common/localization';
import EditCollectionView from '../EditCollectionView';
import ExportButton from '../common/ExportButton';
import { useEffectAsync } from '../reactHelper';
import { formatBigNumber } from '../common/formatter';
import { useWindowDimensions } from '../common/WindowDimensions';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const tableTranslations={
  body: {
    emptyDataSourceMessage: "Sem dados",
    filterRow: {
      filterTooltip: 'Procurar'
    }
  },
  pagination: {
    labelDisplayedRows: '{from}-{to} de {count}',
    labelRowsSelect: 'itens por página',
    firstTooltip: 'Primeira página',
    previousTooltip: 'Página anterior',
    nextTooltip: 'Página seguinte',
    lastTooltip: 'Última página'
  }
}

const useStyles = makeStyles(theme => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#78A9FF11 !important"
    }
  },
  paper: {
    padding: theme.spacing(1, 2, 2), minWidth: 'max-content'
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    padding: '5px'
  },
}));

const MaintenanceSummaryView = ({ updateTimestamp, onMenuClick, extraData}) => {

  const [items, setItems] = useState([]);

  const { pageHeight } = useWindowDimensions();
  document.getElementById('root').style.overflow = 'hidden';

  useEffectAsync(async () => {
    const response = await fetch('/report/api.php/maintenance_summary');
    if (response.ok) {
      var maintenances = await response.json();

      if (maintenances.data) {
        for (let index = 0; index < maintenances.data.length; index++) {
          const maintenance = maintenances.data[index];
          if (maintenance.maintenance_km_days_in_maintenance > 0) {
            maintenances.data[index].column_next_maintenance = maintenance.maintenance_km_days_in_maintenance + ' ' + (maintenance.maintenance_km_days_in_maintenance>1 ? t('days') : t('day'))
            maintenances.data[index].column_next_km = ''
          }
          if (maintenance.maintenance_km_days_in_maintenance == 0) {
            maintenances.data[index].column_next_maintenance = (maintenance.maintenance_type == 'automatic' ? '(Auto) ' : '') + formatBigNumber(maintenance.maintenance_km_next)
            maintenances.data[index].column_next_km = formatBigNumber(maintenance.maintenance_km_left)
          }
        }
        setItems(maintenances.data);
      }
      
      if (maintenances.notification != null) {
        maintenances.notification.maintenance = maintenances.notification.maintenance == "1";
        maintenances.notification.maintenance_km = maintenances.notification.maintenance_km || 1000;
        maintenances.notification.cost = maintenances.notification.cost == "1";
        maintenances.notification.cost_days = maintenances.notification.cost_days || 30;
        maintenances.notification.document = maintenances.notification.document == "1";
        maintenances.notification.document_days = maintenances.notification.document_days || 30;
        maintenances.notification.communication = maintenances.notification.communication == "1";
        maintenances.notification.communication_hours = maintenances.notification.communication_hours || 24;

        extraData(maintenances.notification)
      }
    }
  }, [updateTimestamp]);

  const red = '#ff000040'
  const yellow = '#ffff0040'
  const green = '#00ff0040'
  const gray = '#00000030'

  const maintenance_color = (km_left, days_in_maintenance) => {
    if (days_in_maintenance>0) return gray;
    if (km_left) {
      if (km_left<0) return red;
      if (km_left<1000) return yellow;
    }
    return '';
  }

  const days_left_color = (days_left) => {
    if (days_left) {
      if (days_left<0) return red;
      if (days_left<30) return yellow;
    }
    return '';
  }

  const columns = [
    {
      title: '',
      field: 'id',
      cellStyle: {padding: '0px'},
      width: "1%",
      render: rowData =>
        <IconButton onClick={(event) => onMenuClick(event.currentTarget, rowData.id)}>
          <MoreVertIcon />
        </IconButton>
    },

    {
      title: t('vehicle'),
      field: 'name',
      defaultSort: 'asc',
    },
    {
      title: t('licensePlate'),
      field: 'license_plate',
      align: "center",
    },
    {
      title: t('group'),
      field: 'group',
      align: "center",
    },

    {
      title: t('currentKm'),
      field: 'total_distance',
      align: "center",
      render: row => formatBigNumber(row.total_distance),
    },

    {
      title: t('nextMaintenance'),
      field: 'column_next_maintenance',
      align: "center",
      render: row => row.column_next_maintenance,
      cellStyle: (column, row) => ({
        backgroundColor: maintenance_color(row.maintenance_km_left, row.maintenance_km_days_in_maintenance),
        borderLeft: '1px solid rgba(0, 0, 0, 0.3)'
      }),
      headerStyle: { borderLeft: '1px solid rgba(0, 0, 0, 0.3)' },
    },
    {
      title: t('kmLeft'),
      field: 'column_next_km',
      align: "center",
      render: row => row.column_next_km,
      cellStyle: (column, row) => ({
        backgroundColor: maintenance_color(row.maintenance_km_left, row.maintenance_km_days_in_maintenance),
      }),
    },

    {
      title: t('nextCost'),
      field: 'cost_next',
      align: "center",
      cellStyle: (column, row) => ({
        backgroundColor: days_left_color(row.cost_days_left),
        borderLeft: '1px solid rgba(0, 0, 0, 0.3)'
      }),
      headerStyle: { borderLeft: '1px solid rgba(0, 0, 0, 0.3)' },
    },
    {
      title: t('daysRemaining'),
      field: 'cost_days_left',
      align: "center",
      cellStyle: (column, row) => ({
        backgroundColor: days_left_color(row.cost_days_left),
      }),
    },

    {
      title: t('nextDocument'),
      field: 'document_next',
      align: "center",
      cellStyle: (column, row) => ({
        backgroundColor: days_left_color(row.cost_days_left),
        borderLeft: '1px solid rgba(0, 0, 0, 0.3)',
        whiteSpace: 'nowrap'
      }),
      headerStyle: { borderLeft: '1px solid rgba(0, 0, 0, 0.3)' },
    },
    {
      title: t('daysRemaining'),
      field: 'document_days_left',
      align: "center",
      cellStyle: (column, row) => ({
        backgroundColor: days_left_color(row.cost_days_left),
      }),
    },
  ];
  
  return (
    <>
      <MenuTitle title={t('maintenanceGrid')} style={{ position: 'fixed', top: '50px', left: '20px', zIndex: '100' }}/>

      <MaterialTable
        title=""
        icons={tableIcons}
        localization={tableTranslations}
        columns={columns}
        data={items}
        onSearchChange={(value) => global.list_search_maintenance_summary = value}
        options={{
          sorting: false,
          toolbar: true,
          
          search: true,
          searchText: global.list_search_maintenance_summary ? global.list_search_maintenance_summary : '',
          searchFieldVariant: 'outlined',
          searchFieldStyle: {
            height: '35px',
            padding: '0px 0px 0px 10px',
            marginTop: '10px',
            borderRadius: '50px',
          },
          
          paging: false,
          pageSize: 50,
          pageSizeOptions: [10, 50, 100],
          emptyRowsWhenPaging: false,

          padding: "dense",
          maxBodyHeight: pageHeight - 160,
        }}
        actions={[
          {
            isFreeAction: true,
          }
        ]}
        components={{
          Action: props => (
            <div style={{ marginLeft: '20px', position: 'relative' }}>
              <ExportButton
                filename="Maintenances"
                title={t('maintenances')}
                columns={columns}
                data={items}
              />
            </div>
          )
        }}
      />
    </>
  );
}

const MaintenanceSummaryPage = () => {
  global.selectedMenu = "/management/summary"
  const classes = useStyles();

  const user = useSelector(state => state.session.user)

  const [notificationSettingsDialog, setNotificationSettingsDialog] = useState(false);
  const [notificationStatusText, setNotificationStatusText] = useState('');
  const [notificationSettings, setNotificationSettings] = useState({
    maintenance: false,
    maintenance_km: 1000,
    cost: false,
    cost_days: 30,
    document: false,
    document_days: 30,
    communication: false,
    communication_hours: 24,
    emails: ''
  });

  useEffect(() => {
    if (notificationSettings.maintenance || notificationSettings.cost || notificationSettings.communication) {
      setNotificationStatusText(t('notificationsEnabled'));
    } else {
      setNotificationStatusText(t('notificationsDisabled'));
    }
    if (!notificationSettings.hasOwnProperty('emails')) {
      notificationSettings.emails = user.email;
    }
  }, [notificationSettings])
  
  const handleNotificationTypeChange = (event) => {
    setNotificationSettings({ ...notificationSettings, [event.target.name]: event.target.checked });
  };

  const handleNotificationEmailChange = (event) => {
    setNotificationSettings({ ...notificationSettings, emails: event.target.value });
  };

  const handleConfirmNotificationSelectedOK = async () => {
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(notificationSettings)
    };
    const response = await fetch('/report/api.php/maintenance_summary', options);
    if (response.ok) {
      setNotificationSettingsDialog(false)
    }
  };

  const red = '#ff000040'
  const yellow = '#ffff0040'
  const green = '#00ff0040'
  const gray = '#00000030'

  return (
    <>
      <Dialog open={notificationSettingsDialog}>
        <DialogTitle>
          {t('managementNotificationsDialogTitle')}
        </DialogTitle>
        <DialogContent>

          <DialogContentText>
            {t('managementNotificationsDialogTip')}
          </DialogContentText>

          <FormControl component="fieldset" variant="standard">
            
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={notificationSettings.maintenance}
                  onChange={handleNotificationTypeChange}
                  name="maintenance"
                  />}
              label={t('maintenances')}
              />
            { notificationSettings.maintenance &&
              (
                <>
                <ul style={{marginTop: '0px', marginLeft: '25px'}}>
                  <li>{t('maintenanceTipMaintenance1')}</li>
                  <li>{t('maintenanceTipMaintenance2')}</li>
                  <li>{t('maintenanceTipMaintenance3')}</li>
                </ul>
                <TextField
                    style={{marginLeft: '50px', marginTop: '0px', width: '200px'}}
                    margin="normal"
                    variant="outlined"
                    value={notificationSettings.maintenance_km || 1000}
                    onChange={event => setNotificationSettings({...notificationSettings, maintenance_km: event.target.value})}
                    label={t('advance') + " (Km)"}
                    />
                </>
              )
            }

            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={notificationSettings.cost}
                  onChange={handleNotificationTypeChange}
                  name="cost"
                  />}
              label={t('costs')}
              />
            { notificationSettings.cost &&
              (
                <>
                <ul style={{marginTop: '0px', marginLeft: '25px'}}>
                  <li>{t('maintenanceTipCost1')}</li>
                  <li>{t('maintenanceTipCost2')}</li>
                </ul>
                <TextField
                    style={{marginLeft: '50px', marginTop: '0px', width: '200px'}}
                    margin="normal"
                    variant="outlined"
                    value={notificationSettings.cost_days || 30}
                    onChange={event => setNotificationSettings({...notificationSettings, cost_days: event.target.value})}
                    label={`${t('advance')} (${t('days')})`}
                    />
                </>
              )
            }

            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={notificationSettings.document}
                  onChange={handleNotificationTypeChange}
                  name="document"
                  />}
              label={t('documents')}
              />
            { notificationSettings.document &&
              (
                <>
                <ul style={{marginTop: '0px', marginLeft: '25px'}}>
                  <li>{t('maintenanceTipDocument1')}</li>
                  <li>{t('maintenanceTipDocument2')}</li>
                </ul>
                <TextField
                    style={{marginLeft: '50px', marginTop: '0px', width: '200px'}}
                    margin="normal"
                    variant="outlined"
                    value={notificationSettings.document_days || 24}
                    onChange={event => setNotificationSettings({...notificationSettings, document_days: event.target.value})}
                    label={`${t('advance')} (${t('days')})`}
                    />
                </>
              )
            }

            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={notificationSettings.communication}
                  onChange={handleNotificationTypeChange}
                  name="communication"
                  />}
              label={t('communicationFailure')}
              />
            { notificationSettings.communication &&
              (
                <>
                <ul style={{marginTop: '0px', marginLeft: '25px'}}>
                  <li>{t('maintenanceTipCommunication')}</li>
                </ul>
                <TextField
                    style={{marginLeft: '50px', marginTop: '0px', width: '200px'}}
                    margin="normal"
                    variant="outlined"
                    value={notificationSettings.communication_hours || 24}
                    onChange={event => setNotificationSettings({...notificationSettings, communication_hours: event.target.value})}
                    label={`${t('advance')} (${t('hours')})`}
                    />
                </>
              )
            }
            
          </FormControl>

          <TextField
            autoFocus
            multiline
            value={notificationSettings.emails}
            margin="dense"
            label={t('emailDestinationTip')}
            type="text"
            onChange={event => handleNotificationEmailChange(event)}
            fullWidth
          />

        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleConfirmNotificationSelectedOK} style={{paddingLeft: '40px', paddingRight: '40px' }}>
            {t('save')}
          </Button>
          <Button autoFocus onClick={e => setNotificationSettingsDialog(false)} style={{paddingLeft: '40px', paddingRight: '40px' }}>
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <MainToolbar />

      <div className={classes.content}>
        <Paper className={classes.paper} style={{display: 'contents'}}>
          <EditCollectionView
            content={MaintenanceSummaryView}
            editPath=""
            endpoint="/report/api.php/maintenance_summary"
            allowAddRemove = {false}
            allowEdit = {false}
            searchInput = {false}
            extraData = {setNotificationSettings}
          />
          
          <div style={{ display: 'flow-root', marginTop: '10px' }}>
            <div style={{ float: 'left', display: 'inline-flex' }}>
              <Typography style={{paddingTop: '5px', paddingRight: '10px', color: (notificationSettings.maintenance || notificationSettings.cost || notificationSettings.communication) ? 'green' : 'red'}}>
                {notificationStatusText}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={e => setNotificationSettingsDialog(true)}>
                  {t('configure')}
              </Button>
            </div>
            <div style={{ float: 'right' }}>
              <div style={{ borderStyle: 'none', paddingTop: '10px', textAlign: 'center' }}>
                <h4 style={{display: 'inline'}}>
                  {t('legend')}:
                </h4>
                <span style={{borderStyle: 'solid', borderWidth: '1px', borderColor: '#bbbbbb', borderRadius: '15px', backgroundColor: gray, marginLeft: '10px', padding: '5px 15px 5px 15px'}}>{t('maintenanceIncomplete')}</span>
                <span style={{borderStyle: 'solid', borderWidth: '1px', borderColor: '#bbbbbb', borderRadius: '15px', backgroundColor: yellow, marginLeft: '10px', padding: '5px 15px 5px 15px'}}>{t('maintenanceSoon')}</span>
                <span style={{borderStyle: 'solid', borderWidth: '1px', borderColor: '#bbbbbb', borderRadius: '15px', backgroundColor: red, marginLeft: '10px', padding: '5px 15px 5px 15px'}}>{t('maintenancePastDue')}</span>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </>
  );
}

export default MaintenanceSummaryPage;
