export const styleCustom = (url, attribution) => ({
  version: 8,
  sources: {
    osm: {
      type: 'raster',
      tiles: [url],
      tileSize: 256,
      attribution: attribution,
    },
  },
  glyphs: 'https://api.mapbox.com/fonts/v1/mapbox/{fontstack}/{range}.pbf', //?access_token=
  layers: [{
    id: 'osm',
    type: 'raster',
    source: 'osm',
  }],
});

export const styleOsm = () => styleCustom(
  'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
  '© <a target="_top" rel="noopener" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
);

export const styleGoogleMapsRoad = () => styleCustom(
  'https://mt0.google.com/vt/lyrs=m,traffic&hl=pt&x={x}&y={y}&z={z}&s=Ga',
  '<span>Map data ©2022 Google</span>'
);

export const styleGoogleMapsSatellite = () => styleCustom(
  'https://mt0.google.com/vt/lyrs=y&hl=pt&x={x}&y={y}&z={z}&s=Ga',
  '<span>Map data ©2022 Google</span>'
);

/*
Google Maps layrs
h = roads only
m = standard roadmap
p = terrain
r = somehow altered roadmap
s = satellite only
t = terrain only
y = hybrid
*/

export const styleCarto = () => ({
  'version': 8,
  'sources': {
    'raster-tiles': {
      'type': 'raster',
      'tiles': [
          'https://a.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@2x.png',
          'https://b.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@2x.png',
          'https://c.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@2x.png',
          'https://d.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@2x.png'
      ],
      'tileSize': 256,
      'attribution': '© <a target="_top" rel="noopener" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, © <a target="_top" rel="noopener" href="https://carto.com/attribution">CARTO</a>'
    }
  },
  'glyphs': 'https://cdn.traccar.com/map/fonts/{fontstack}/{range}.pbf',
  'layers': [
    {
      'id': 'simple-tiles',
      'type': 'raster',
      'source': 'raster-tiles',
      'minzoom': 0,
      'maxzoom': 18,
    }
  ]
});

export const styleMapbox = (style) => `mapbox://styles/mapbox/${style}`;

export const styleMapTiler = (style, key) => `https://api.maptiler.com/maps/${style}/style.json?key=${key}`;
