import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Paper, makeStyles } from '@material-ui/core';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import MainToolbar from '../MainToolbar';
import t from '../common/localization';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Accordion, AccordionSummary, AccordionDetails, Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, Button, TextField, ButtonGroup, IconButton, FormGroup, FormControlLabel, FormLabel, FormHelperText, Switch, Menu, Typography } from '@material-ui/core';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile, faFilePdf, faFileExcel, faFileCode, faFileCsv } from '@fortawesome/free-solid-svg-icons'

import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardHeader from '@material-ui/core/CardHeader';

import { deviceHasFuelSensor } from './../map/mapUtil';
import MenuTitle from '../MenuTitle';
import TransferList from '../TransferList';

import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import DownloadIcon from '@material-ui/icons/CloudDownload';

import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { useIsVisible } from 'react-is-visible'
import Tooltip from '@material-ui/core/Tooltip';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

import { validHM } from '../common/formatter';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    padding: '5px'
  },
  form: {
    padding: theme.spacing(1, 2, 2),
    minWidth: 'max-content'
  },


  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 'auto',
	  paddingRight: '20px',
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
    minWidth: '40px',
    height: '30px',
    //backgroundColor: '#2399CC',
    //color: '#FFFFFF'
  },
  checkbox: {
    padding: '0px',
  },
  transferList: {
    margin: 'auto',
    justifyContent: 'left'
  }
}));

const ReportLayoutPage = ({ children, setItems, endpoint, reportName,
  showYear = false,
  showCostSelector = false,
  showEventSelector = false,
  showVehicles = true,
  showDrivers = false,
  showVehiclesOrDrivers = false,
  showPassengers = false,
  showGeofences = false,
  showGroups = false,
  showSingleDaySelector = false,
  showMonthSelector = false,
  extraFieldList = {
    "order": "week,check1",
    "text1_value": false,
    "text1_name": "",
    "text2_value": false,
    "text2_name": "",
    "check1_enabled": false,
    "check1_value": false,
    "check1_name": '',
    "check2_enabled": false,
    "check2_value": false,
    "check2_name": '',
    "select1_name": '',
    "select1_options": false,
    "select1_value": 0,
    "geofence_filter_enabled": false,
    "geofence_filter_with_speedLimit": false,
    "geofence_filter_value": false,
    "week": false,
    "weekdays_value": true,
    "weekdays_start": '09:00',
    "weekdays_end": '18:00',
    "saturday_value": true,
    "saturday_start": '09:00',
    "saturday_end": '13:00',
    "sunday_value": false,
    "sunday_start": '09:00',
    "sunday_end": '18:00',
  },
  availableFormats = ["json", "pdf", "excel", "csv", "xml"],
  devicesWithCanbus = false,
  devicesWithFuelSensor = false,
  devicesWithPto = false,
  maximumPeriodDays = 31
  }) => {

  moment.locale((global && global.language) ? global.language.replace('pt', 'pt-br').replace('en', 'en-gb').replace('ar', 'en-gb') : 'en-gb');

  const classes = useStyles();

  const [extraFields, setExtraFields] = React.useState(extraFieldList);

  const [exportMenu, setExportMenu] = React.useState(null);
 
  const [resultStatus, setResultStatus] = useState(false);
  const [statusDialog, setStatusDialog] = useState({title: '', message: ''});

  const showStatusDialog = (title, message) => {
    setStatusDialog({title: title, message: message});
    setResultStatus(true);
  }

  const hideStatusDialog = () => {
    setResultStatus(false);
  };

  useEffect(() => {
    if (endpoint == '/report/report_trips.php') {
      var showWeek = (extraFields.select1_value == 1 || extraFields.select1_value == 2);
      if (extraFields.week != showWeek) {
        setExtraFields({...extraFields, week: showWeek })
      }
    }
  }, [extraFields]);

  const handleExportShow = (event) => {
    setExportMenu(event.currentTarget);
  };

  const handleExport = (event) => {
    var mode = event.currentTarget.id

    if (mode) generateReport(mode)

    setExportMenu(null);
  };

  const handleSubmit = async (devices, itemsType, from, to, month, mode) => {

    if (moment(to).diff(moment(from), 'days')+1 > maximumPeriodDays && devices.length>1) {
      showStatusDialog('Oops!', `Por favor seleccione um perído máximo de ${maximumPeriodDays} dias`)
      setLoading(false);
      return false
    }

    if (extraFields.week &&
      !(validHM(extraFields.weekdays_start)
      && validHM(extraFields.weekdays_end)
      && validHM(extraFields.saturday_start)
      && validHM(extraFields.saturday_end)
      && validHM(extraFields.sunday_start)
      && validHM(extraFields.sunday_end)
      )) {
        showStatusDialog('Oops!', 'Por favor introduza as horas de trabalho no formato HH:MM')
        setLoading(false);
        return false
    }

    if (devices.length == 0 || (devices.length == 1 && devices[0] == '')) {
      showStatusDialog('Oops!', 'Por favor seleccione os veículos pretendidos')
      setLoading(false);
      return false
    }

    //if (mode == "json") setItems({});
    if (document.getElementById("reportResults")) document.getElementById("reportResults").style.filter = 'blur(5px)'

    var parameters = {
      devices,
      itemsType,
      from,
      to,
      mode,
      text1: extraFields.text1_value,
      text2: extraFields.text2_value,
      check1: extraFields.check1_value,
      check2: extraFields.check2_value,
      select1: extraFields.select1_value,
      week: {
        weekdays: extraFields.weekdays_value,
        weekdays_start: extraFields.weekdays_start,
        weekdays_end: extraFields.weekdays_end,
        saturday: extraFields.saturday_value,
        saturday_start: extraFields.saturday_start,
        saturday_end: extraFields.saturday_end,
        sunday: extraFields.sunday_value,
        sunday_start: extraFields.sunday_start,
        sunday_end: extraFields.sunday_end,
      },
      keyValueItems,
    }

    if (showCostSelector) {
      parameters.cost_types = costType;
    }

    if (showGeofences || extraFields.geofence_filter_value) {
      parameters.geofences = selectedGeofences.report_ids ? selectedGeofences.report_ids.split(',') : [];
    }

    if (showGroups) {
      parameters.groups = selectedItems.report_ids.split(',');
      parameters.devices = [];
    }

    if (showMonthSelector) {
      parameters.month = month;
    }

    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(parameters)
    };

    const response = await fetch(endpoint, options);
    if (response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType) {

        if (contentType === 'application/json') {
          //Mostrar inline
          try {
            setItems(await response.json());
            scrollToReportStart();            
          } catch (error) {
            setLoading(false);
            console.log(error)
          }
        } else if (contentType === 'application/pdf') {
          //Mostrar em novo tab
          window.open(window.URL.createObjectURL(await response.blob()), '_blank');
        } else {
          //Download

          var filename = "Report";
          if (contentType.includes('excel')) filename += ".xlsx";
          if (contentType.includes('csv')) filename += ".csv";
          if (contentType.includes('xml')) filename += ".xml";

          var temporaryElement = document.createElement("a");
          temporaryElement.setAttribute('style', 'display:none;');
          temporaryElement.href = window.URL.createObjectURL(await response.blob());
          temporaryElement.setAttribute("download", filename);
          temporaryElement.click();

        }
      }
    }
    setLoading(false);
  }

  const [period, setPeriod] = useState(global.reports.period);
  const [from, setFrom] = useState(global.reports.from || moment().startOf('day'));
  const [to, setTo] = useState(global.reports.to || moment().endOf('day'));
  const [month, setMonth] = useState(moment().subtract(0, 'month').startOf('month').format('YYYY-MM'));
  const [loading, setLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedGeofences, setSelectedGeofences] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [availableDevices, setAvailableDevices] = useState([]);
  const [availableDrivers, setAvailableDrivers] = useState([]);
  const [availablePassengers, setAvailablePassengers] = useState([]);
  const [availableGeofences, setAvailableGeofences] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [vehiclesOrDriversSelection, setVehiclesOrDriversSelection] = useState('V');

  const [keyValueItems, setKeyValueItems] = useState([]);
  const updateItem = (key, value) => {
    document.cookie = "groupId_"+key+"="+value;
    var newItem = Object.assign([], keyValueItems)
    var index = newItem.findIndex(obj => obj.key == key)
    if (index == -1) {
      newItem.push({key: key, value: value})
    } else {
      newItem[index] = {key, value}
    }
    setKeyValueItems(newItem)
  }

  useEffect(() => {
    if (availableDevices.length == 0) {
      (async () => {
        const response = await fetch('/api/devices');
        if (response.ok) {
          var availableDevices = await response.json()
          setAvailableDevices(availableDevices.filter(deviceFilter));
        }
      })();
    }
    
    var cookies = document.cookie.replaceAll(" ", "").split(';')
    var newItem = []
    cookies.forEach(cookie => {
      cookie = cookie.split('=')
      if (cookie[0].startsWith('groupId_') && !isNaN(cookie[0].replace('groupId_', ''))) {
        newItem.push({key: cookie[0].replace('groupId_', ''), value: cookie[1]})
      }
    });
    setKeyValueItems(newItem)

  }, []);

  useEffect(() => {
    if (availableDrivers.length == 0) {
      (async () => {
        const response = await fetch('/report/api.php/drivers');
        if (response.ok) {
          setAvailableDrivers(await response.json());
        }
      })();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (showPassengers) {

        const response = await fetch('/api/groups');
        if (response.ok) {
          var groups = await response.json()
          var passengerGroupId = false;
          groups.forEach(group => {
            if (group.name.toLowerCase().startsWith('passageiros ') ) passengerGroupId = group.id
          });
          if (passengerGroupId) {
            const response = await fetch('/api/drivers?groupId='+passengerGroupId);
            if (response.ok) {
              setAvailablePassengers(await response.json());
            }
          }

        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (showGeofences || extraFields.geofence_filter_enabled) {
        const response = await fetch('/api/geofences');
        if (response.ok) {
          var geofences = await response.json();

          if (extraFields.geofence_filter_with_speedLimit) {
            geofences = geofences.filter(geofence => geofence.attributes && geofence.attributes.speedLimit && geofence.attributes.speedLimit>0)

            for (let index = 0; index < geofences.length; index++) {
              var geofence = geofences[index];
              if (geofence.attributes && geofence.attributes.speedLimit && geofence.attributes.speedLimit>0) {
                geofences[index].name = geofences[index].name + " (" + Math.round((geofence.attributes.speedLimit * 1.852)) + " km/h)";
              }
            }
          }
          setAvailableGeofences(geofences);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (showGroups) {
        const response = await fetch('/api/groups');
        if (response.ok) {
          setAvailableGroups(await response.json());
        }
      }
    })();
  }, []);

  const scrollToReportStart = () => {
    var element = document.getElementById("reportResult");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const scrollToTop = () => {
    var element = document.getElementById("reportTop");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const setTimeRange = (period) => {
    setPeriod(period)
    global.reports.period = period;
  }


  useEffect(() => {
    switch (period) {
      case 'today':
        setFrom(moment().startOf('day'));
        setTo(moment().endOf('day'));
        break;
      case 'yesterday':
        setFrom(moment().subtract(1, 'day').startOf('day'));
        setTo(moment().subtract(1, 'day').endOf('day'));
        break;
      case 'dayBeforeYesterday':
        setFrom(moment().subtract(2, 'day').startOf('day'));
        setTo(moment().subtract(2, 'day').endOf('day'));
        break;
      case 'thisWeek':
        setFrom(moment().startOf('week'));
        setTo(moment().endOf('week'));
        break;
      case 'previousWeek':
        setFrom(moment().subtract(1, 'week').startOf('week'));
        setTo(moment().subtract(1, 'week').endOf('week'));
        break;
      case 'thisMonth':
        setFrom(moment().startOf('month'));
        setTo(moment().endOf('month'));
        break;
      case 'previousMonth':
        setFrom(moment().subtract(1, 'month').startOf('month'));
        setTo(moment().subtract(1, 'month').endOf('month'));
        break;
      case 'thisYear':
        setFrom(moment().startOf('year'));
        setTo(moment().endOf('year'));
        break;
      case 'previousYear':
        setFrom(moment().subtract(1, 'year').startOf('year'));
        setTo(moment().subtract(1, 'year').endOf('year'));
        break;
    }
  }, [period]);

  const generateReport = (mode) => {
    setLoading(true);
    handleSubmit(
      selectedItems.report_ids.split(','),
      vehiclesOrDriversSelection,
      from.format('YYYY-MM-DD HH:mm:ss'),
      to.format('YYYY-MM-DD HH:mm:ss'),
      month,
      mode
    );
  }

  const [costType, setCostType] = React.useState({
    maintenance: false,
    refill: false,
    insurance: false,
    license: false,
    tax: false,
    cleaning: false,
    other: false
  });

  const handleCostTypeChange = (event) => {
    setCostType({ ...costType, [event.target.name]: event.target.checked });
  };
  
  const updateEvents = (event) => {
    if (event.target.checked) {
      setExtraFields({...extraFields, text1_value: addToCsvString(extraFields.text1_value, event.target.name) })
    } else {
      setExtraFields({...extraFields, text1_value: removeFromCsvString(extraFields.text1_value, event.target.name) })
    }
  }

  const addToCsvString = (csvString, element) => {
    if (!csvString) csvString = "";
    return csvString + (csvString != '' ? ',' : '') + element
  }
  const removeFromCsvString = (csvString, element) => {
    return csvString.replace(',' + element, '').replace(element, '')
  }
  
  const nodeRef = useRef()
  const isVisible = useIsVisible(nodeRef)

  const deviceFilter = (item) => {
    if (devicesWithCanbus && devicesWithFuelSensor) {
      return item.hasOwnProperty('attributes') && item.attributes.hasOwnProperty('has_canbus') && item.attributes.has_canbus
            || item.hasOwnProperty('attributes') && deviceHasFuelSensor(item);
    }

    if (devicesWithCanbus) {
      return item.hasOwnProperty('attributes') && item.attributes.hasOwnProperty('has_canbus') && item.attributes.has_canbus;
    }
    if (devicesWithFuelSensor) {
      return item.hasOwnProperty('attributes') && deviceHasFuelSensor(item);
    }
    if (devicesWithPto) {
      return item.hasOwnProperty('attributes') && item.attributes.hasOwnProperty('pto_port') && item.attributes.pto_port > 0;
    }
    return true;
  };

  const extraFieldOrder = (name) => {
    var elementList = (extraFieldList.order || "").split(",")
    for (let index = 0; index < elementList.length; index++) {
      if (elementList[index] == name) return index
    }
  };

  
  return (
    <div className={classes.root}>
      
      <MainToolbar />

      <div className={classes.content}>
        <Paper className={classes.form} ref={nodeRef} id="reportTop">

          <MenuTitle title={reportName} />

          <Grid container spacing={0}>
            <Grid item style={{minWidth: '200px'}}>

{ !showSingleDaySelector && !showMonthSelector &&
              <FormControl margin="normal" fullWidth>
                <InputLabel shrink>{t('period')}</InputLabel>
                <Select value={period} onChange={(e) => setTimeRange(e.target.value)} label={t('period')}>
                  <MenuItem key='today' value="today">{t('reportToday')}</MenuItem>
                  <MenuItem key='yesterday' value="yesterday">{t('reportYesterday')}</MenuItem>
                  <MenuItem key='dayBeforeYesterday' value="dayBeforeYesterday">{t('dayBeforeYesterday')}</MenuItem>
                  <MenuItem key='thisWeek' value="thisWeek">{t('reportThisWeek')}</MenuItem>
                  <MenuItem key='previousWeek' value="previousWeek">{t('reportPreviousWeek')}</MenuItem>
                  <MenuItem key='thisMonth' value="thisMonth">{t('reportThisMonth')}</MenuItem>
                  <MenuItem key='previousMonth' value="previousMonth">{t('reportPreviousMonth')}</MenuItem>
                  
                  <MenuItem style={showYear ? {} : {display: 'none'}} key='thisYear' value="thisYear">{t('reportThisYear')}</MenuItem>
                  <MenuItem style={showYear ? {} : {display: 'none'}} key='previousYear' value="previousYear">{t('reportPreviousYear')}</MenuItem>
                  <MenuItem key='custom' value="custom">{t('reportPickDates')}</MenuItem>
                </Select>
              </FormControl>
}
{ showSingleDaySelector &&
              <FormControl margin="normal" fullWidth>
                <InputLabel shrink>{t('date')}</InputLabel>
                <Select value={period} onChange={(e) => setTimeRange(e.target.value)} label={t('period')}>
                  <MenuItem key='today' value="today">{t('reportToday')}</MenuItem>
                  <MenuItem key='yesterday' value="yesterday">{t('reportYesterday')}</MenuItem>
                  <MenuItem key='dayBeforeYesterday' value="dayBeforeYesterday">{t('dayBeforeYesterday')}</MenuItem>
                  <MenuItem key='customDay' value="customDay">{t('reportPickDate')}</MenuItem>
                </Select>
              </FormControl>
}
{ showMonthSelector &&
              <FormControl margin="normal" fullWidth>
                <InputLabel shrink>{t('month')}</InputLabel>
                <Select
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  label={t('month')}
                  >
                  {[...Array(24).keys()].map((month) => (
                    <MenuItem key={moment().subtract(month, 'month').startOf('month').format('YYYY-MM')} value={moment().subtract(month, 'month').startOf('month').format('YYYY-MM')}>
                      {moment().subtract(month, 'month').startOf('month').format('MMMM YYYY')}
                    </MenuItem>
                  )
                  )}
                </Select>
              </FormControl>
}
            </Grid>

            {period === 'custom' && (
              <>
                <Grid item style={{marginLeft: '10px'}}>
                  <FormControl margin="normal" fullWidth>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DateTimePicker
                        label={t('from')}
                        format="DD/MM/yyyy HH:mm:ss"
                        ampm={false}
                        variant="dialog"
                        value={from}
                        onChange={e => { setFrom(e); global.reports.from = e }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>

                <Grid item style={{marginLeft: '10px'}}>
                  <FormControl margin="normal" fullWidth>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DateTimePicker
                        label={t('to')}
                        format="DD/MM/yyyy HH:mm:ss"
                        ampm={false}
                        variant="dialog"
                        value={to}
                        onChange={e => { setTo(e); global.reports.to = e }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </>
            )}
            {period === 'customDay' && (
              <>
                <Grid item style={{marginLeft: '10px'}}>
                  <FormControl margin="normal" fullWidth>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DatePicker
                        label={t('reportPickDate')}
                        format="DD/MM/yyyy"
                        variant="dialog"
                        value={from}
                        onChange={e => setFrom(e)}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>

          { showVehiclesOrDrivers &&
            <RadioGroup
              row
              value={vehiclesOrDriversSelection}
              onChange={(event) => setVehiclesOrDriversSelection(event.target.value)}
              color="primary"
              style={{marginTop: '10px'}}
              >
                <FormControlLabel value="V" control={<Radio color="primary"/>} label={t('byVehicle')} />
                <FormControlLabel value="D" control={<Radio color="primary"/>} label={t('byDriver')} />
            </RadioGroup>
          }
          
          { (showVehicles || (showVehiclesOrDrivers && vehiclesOrDriversSelection == 'V')) &&
            <TransferList
              title={t('vehicles')}
              allowShowGroups={true}
              availableItems={availableDevices}
              selectedItems={global.reports.devices.report_ids ? global.reports.devices.report_ids.split(',').map(item => parseInt(item)) : []}
              item={selectedItems}
              setItem={(e) => { setSelectedItems(e); global.reports.devices = e }}
              />
          }
          
          { (showDrivers || (showVehiclesOrDrivers && vehiclesOrDriversSelection == 'D')) &&
            <TransferList
              title={t('drivers')}
              allowShowGroups={true}
              availableItems={availableDrivers}
              selectedItems={global.reports.drivers.report_ids ? global.reports.drivers.report_ids.split(',').map(item => parseInt(item)) : []}
              item={selectedItems}
              setItem={(e) => { setSelectedItems(e); global.reports.drivers = e }}
              />
          }
          
          { showPassengers &&
            <TransferList
              title={t('passengers')}
              allowShowGroups={false}
              availableItems={availablePassengers}
              selectedItems={global.reports.passengers.report_ids ? global.reports.passengers.report_ids.split(',').map(item => parseInt(item)) : []}
              item={selectedItems}
              setItem={(e) => { setSelectedItems(e); global.reports.passengers = e }}
              />
          }

          { extraFields.geofence_filter_enabled &&
            <FormControlLabel
              style={{ display: 'block', marginTop: '20px' , marginBottom: '20px' }}
              label={t('filterOnlySelectedGeofences')}
              control={
                <Switch color="primary"
                  checked={extraFields.geofence_filter_value}
                  onChange={event => setExtraFields({...extraFields, geofence_filter_value: event.target.checked})}
                />}
              />
          }
          
          { (showGeofences || extraFields.geofence_filter_value) &&
            <TransferList
              title={t('zones')}
              allowShowGroups={false}
              availableItems={availableGeofences}
              selectedItems={global.reports.geofences.report_ids ? global.reports.geofences.report_ids.split(',').map(item => parseInt(item)) : []}
              item={selectedGeofences}
              setItem={(e) => { setSelectedGeofences(e); global.reports.geofences = e }}
              />
          }
          
          { showGroups &&
            <TransferList
              title={t('groups')}
              allowShowGroups={false}
              availableItems={availableGroups}
              selectedItems={global.reports.groups.report_ids ? global.reports.groups.report_ids.split(',').map(item => parseInt(item)) : []}
              item={selectedGroups}
              setItem={(e) => { setSelectedItems(e); global.reports.groups = e }}
              />
          }

          {showCostSelector &&
            <FormControl component="fieldset" variant="standard" style={{marginTop: '20px'}}>
              <FormHelperText>{t('costTypes')}:</FormHelperText>
              <FormControlLabel control={<Switch color="primary" checked={costType.maintenance} onChange={handleCostTypeChange} name="maintenance" />} label={t('typeMaintenance')} />
              <FormControlLabel control={<Switch color="primary" checked={costType.refill} onChange={handleCostTypeChange} name="refill" />} label={t('typeRefill')} />
              <FormControlLabel control={<Switch color="primary" checked={costType.insurance} onChange={handleCostTypeChange} name="insurance" />} label={t('typeInsurance')} />
              <FormControlLabel control={<Switch color="primary" checked={costType.license} onChange={handleCostTypeChange} name="license" />} label={t('typeLicense')} />
              <FormControlLabel control={<Switch color="primary" checked={costType.tax} onChange={handleCostTypeChange} name="tax" />} label={t('typeTax')} />
              <FormControlLabel control={<Switch color="primary" checked={costType.cleaning} onChange={handleCostTypeChange} name="cleaning" />} label={t('typeCleaning')} />
              <FormControlLabel control={<Switch color="primary" checked={costType.other} onChange={handleCostTypeChange} name="other" />} label={t('typeOther')} />
            </FormControl>
          }

          {showEventSelector &&
            <FormControl component="fieldset" variant="standard" style={{marginTop: '20px'}}>
              <FormHelperText>{t('eventTypes')}:</FormHelperText>
              <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('geofenceEnter')} onChange={updateEvents} name="geofenceEnter" />} label={t('eventGeofenceEnter')} />
              <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('geofenceExit')} onChange={updateEvents} name="geofenceExit" />} label={t('eventGeofenceExit')} />
              <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('deviceOverspeed')} onChange={updateEvents} name="deviceOverspeed" />} label={t('eventDeviceOverspeed')} />
              { global.has_canbus &&
                <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('deviceFuelDrop')} onChange={updateEvents} name="deviceFuelDrop" />} label={t('eventDeviceFuelDrop')} />
              }
              <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('alarmLowBattery')} onChange={updateEvents} name="alarmLowBattery" />} label={t('eventLowBattery')} />
              <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('alarmSos')} onChange={updateEvents} name="alarmSos" />} label={t('eventSos')} />
              
              <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('alarmHardAcceleration')} onChange={updateEvents} name="alarmHardAcceleration" />} label={t('eventHardAcceleration')} />
              <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('alarmHardBraking')} onChange={updateEvents} name="alarmHardBraking" />} label={t('eventHardBraking')} />
              <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('alarmHardCornering')} onChange={updateEvents} name="alarmHardCornering" />} label={t('eventHardCornering')} />
              <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('alarmTampering')} onChange={updateEvents} name="alarmTampering" />} label={t('eventTampering')} />

              { global.user_details.attributes.permissions && global.user_details.attributes.permissions.includes('global.alert_seatbelt_lights') &&
                <FormControlLabel control={<Switch color="primary" checked={extraFields.text1_value && extraFields.text1_value.includes('alarmSeatbeltAndLights')} onChange={updateEvents} name="alarmSeatbeltAndLights" />} label={t('eventSeatbeltAndLights')} />
              }
            </FormControl>
          }
          
          <div id="extraFields" style={{marginTop: '30px', width: '100%', display: 'inline-grid'}}>
            { extraFields.text1_name && 
              <TextField
                fullWidth
                variant="outlined"
                style={{display: 'block', marginBottom: '30px', order: extraFieldOrder('text1')}}
                onChange={event => setExtraFields({...extraFields, text1_value: event.target.value})}
                label={extraFields.text1_name}
                value={extraFields.text1_value}
                />
            }
            { extraFields.text2_name && 
              <TextField
                fullWidth
                variant="outlined"
                style={{display: 'block', marginBottom: '30px', order: extraFieldOrder('text2')}}
                onChange={event => setExtraFields({...extraFields, text2_value: event.target.value})}
                label={extraFields.text2_name}
                value={extraFields.text2_value}
                />
            }
            { extraFields.check1_name &&
              <FormControlLabel
                style={{ display: 'block', marginBottom: '20px', order: extraFieldOrder('check1') }}
                label={extraFields.check1_name}
                control={
                  <Switch color="primary"
                    checked={extraFields.check1_value}
                    onChange={event => setExtraFields({...extraFields, check1_value: event.target.checked})}
                  />}
                />
            }
            { extraFields.check2_name &&
              <FormControlLabel
                style={{ display: 'block', marginBottom: '20px', order: extraFieldOrder('check2') }}
                label={extraFields.check2_name}
                control={
                  <Switch color="primary"
                    checked={extraFields.check2_value}
                    onChange={event => setExtraFields({...extraFields, check2_value: event.target.checked})}
                  />}
                />
            }
            { extraFields.select1_options &&
              <FormControl style={{ display: 'block', marginBottom: '30px', order: extraFieldOrder('select1') }}>
                <InputLabel>{extraFields.select1_name}</InputLabel>
                <Select
                  value={extraFields.select1_value}
                  onChange={event => setExtraFields({...extraFields, select1_value: event.target.value})}
                  style={{minWidth: '200px', order: extraFieldOrder('select1')}}
                  >
                    {extraFields.select1_options.map(option => (
                      <MenuItem key={option[0]} value={option[0]}>
                        {option[1]}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            }

            { extraFields.week &&

              <Accordion
                defaultExpanded={true}
                style={{ width: 'fit-content', marginBottom: '30px', paddingRight: '20px', order: extraFieldOrder('week') }}
                >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {t('workingHours')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                    <div>
                      <span style={{ minWidth: '150px', display: 'inline-block' }}>
                        <Switch color="primary"
                          checked={extraFields.weekdays_value}
                          onChange={event => setExtraFields({...extraFields, weekdays_value: event.target.checked})}
                          /> {t('week_weekdays')}
                      </span>
                      <span style={{ marginRight: '20px' }}>
                        {t('start')}:&nbsp;
                        <TextField
                          style={{width: '50px'}}
                          InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                          value={extraFields.weekdays_start}
                          onChange={event => setExtraFields({...extraFields, weekdays_start: event.target.value})}
                          />                
                      </span>
                      <span>
                        {t('end')}:&nbsp;
                        <TextField
                          style={{width: '50px'}}
                          InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                          value={extraFields.weekdays_end}
                          onChange={event => setExtraFields({...extraFields, weekdays_end: event.target.value})}
                          />
                      </span>
                    </div>
                    <div>
                      <span style={{ minWidth: '150px', display: 'inline-block' }}>
                        <Switch color="primary"
                          checked={extraFields.saturday_value}
                          onChange={event => setExtraFields({...extraFields, saturday_value: event.target.checked})}
                          /> {t('week_saturday')}
                      </span>
                      <span style={{ marginRight: '20px' }}>
                        {t('start')}:&nbsp;
                        <TextField
                          style={{width: '50px'}}
                          InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                          value={extraFields.saturday_start}
                          onChange={event => setExtraFields({...extraFields, saturday_start: event.target.value})}
                          />                
                      </span>
                      <span>
                        {t('end')}:&nbsp;
                        <TextField
                          style={{width: '50px'}}
                          InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                          value={extraFields.saturday_end}
                          onChange={event => setExtraFields({...extraFields, saturday_end: event.target.value})}
                          />
                      </span>
                    </div>
                    <div>
                      <span style={{ minWidth: '150px', display: 'inline-block' }}>
                        <Switch color="primary"
                          checked={extraFields.sunday_value}
                          onChange={event => setExtraFields({...extraFields, sunday_value: event.target.checked})}
                          /> {t('week_sunday')}
                      </span>
                      <span style={{ marginRight: '20px' }}>
                        {t('start')}:&nbsp;
                        <TextField
                          style={{width: '50px'}}
                          InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                          value={extraFields.sunday_start}
                          onChange={event => setExtraFields({...extraFields, sunday_start: event.target.value})}
                          />                
                      </span>
                      <span>
                        {t('end')}:&nbsp;
                        <TextField
                          style={{width: '50px'}}
                          InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                          value={extraFields.sunday_end}
                          onChange={event => setExtraFields({...extraFields, sunday_end: event.target.value})}
                          />
                      </span>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
                  
            }

            { endpoint.includes("report_group_hours") && selectedItems.report_ids &&
            <div>
              Horas mensais previstas para cada veículo do grupo (Seg-Sex):
              <br/><br/>

              { selectedItems.report_ids && availableGroups.filter((group) => selectedItems.report_ids.split(",").includes(group.id+'')).map((group) => 
                <div>
                  <TextField
                    variant="outlined"
                    style={{ marginBottom: '20px', minWidth: '30%' }}
                    label={group.name}
                    id={group.id}
                    onChange={event => updateItem(event.target.id, event.target.value)}
                    value={keyValueItems[keyValueItems.findIndex(obj => obj.key == group.id)] ? keyValueItems[keyValueItems.findIndex(obj => obj.key == group.id)].value : ''}
                    />
                </div>
                )
              }
            </div>
            }

          </div>

          <Grid container spacing={0} style={{marginTop: '20px'}}>

            <Grid item style={{display: 'flex', width: '100%'}}>

            { loading && 
            <span style={{marginRight: '10px'}}>
              <CircularProgress size={30} />
            </span>
            }              

              { availableFormats.includes('json') &&
              <Button disabled={selectedItems.length == 0} color="primary" variant="outlined" onClick={() => generateReport('json')} style={{marginLeft: '5px'}}>
                <SearchIcon />
                &nbsp;
                {t('query')}
              </Button>
              }

              { (
                availableFormats.includes('pdf')
                || availableFormats.includes('excel')
                || availableFormats.includes('csv')
                || availableFormats.includes('xml')
                ) &&
              <>
              <Button
                variant="outlined"
                color="primary"
                style={{marginLeft: '10px'}}
                onClick={handleExportShow}
                disabled={selectedItems.length == 0}>
                  <DownloadIcon />
                  &nbsp;
                  {t('export')}
              </Button>
              <Menu
                anchorEl={exportMenu}
                keepMounted
                open={Boolean(exportMenu)}
                onClose={handleExport}
                disabled={selectedItems.length == 0}
              >
                { availableFormats.includes('pdf') &&
                <MenuItem onClick={handleExport} id="pdf">
                  <ListItemIcon style={{minWidth: '20px'}}>
                    <FontAwesomeIcon icon={faFilePdf} style={{color: '#78A9FF', fontSize: '1.4em'}} />
                  </ListItemIcon>&nbsp;
                  <Typography>PDF</Typography>
                </MenuItem>
                }
                { availableFormats.includes('excel') &&
                <MenuItem onClick={handleExport} id="excel">
                  <ListItemIcon style={{minWidth: '20px'}}>
                    <FontAwesomeIcon icon={faFileExcel} style={{color: '#78A9FF', fontSize: '1.4em'}} />
                  </ListItemIcon>&nbsp;
                  <Typography>Excel</Typography>
                </MenuItem>
                }
                { availableFormats.includes('csv') &&
                <MenuItem onClick={handleExport} id="csv">
                  <ListItemIcon style={{minWidth: '20px'}}>
                    <FontAwesomeIcon icon={faFileCsv} style={{color: '#78A9FF', fontSize: '1.4em'}} />
                  </ListItemIcon>&nbsp;
                  <Typography>CSV</Typography>
                </MenuItem>
                }
                { availableFormats.includes('xml') &&
                <MenuItem onClick={handleExport} id="xml">
                  <ListItemIcon style={{minWidth: '20px'}}>
                    <FontAwesomeIcon icon={faFileCode} style={{color: '#78A9FF', fontSize: '1.4em'}} />
                  </ListItemIcon>&nbsp;
                  <Typography>XML</Typography>
                </MenuItem>
                }
              </Menu>
              </>
              }
            </Grid>   
          </Grid>
        </Paper>

        <br></br>

        <div>
          {children}
        </div>

        {!isVisible &&
          <Tooltip title={t('scrollToTop')}>
            <Fab
              onClick={scrollToTop}
              style={{
                color: 'rgb(255, 255, 255)',
                backgroundColor: 'rgba(0,0,0,0.4)',
                position: 'absolute',
                bottom: '30px',
                right: '50px',
              }}>
              <KeyboardArrowUpIcon style={{ fontSize: 30 }} />
            </Fab>
          </Tooltip>
        }

      </div>
      <Dialog open={resultStatus} onClose={hideStatusDialog}>
        <DialogTitle id="form-dialog-title">
          {statusDialog.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="span">
            <Grid container spacing={1}>
              <Grid item>
                <img src="images/emoji_worried_face.png" width="32px"/>
              </Grid>
              <Grid item style={{ marginTop: '4px' }}>
                <Box>
                {statusDialog.message}
                </Box>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideStatusDialog} color="primary" variant="outlined">OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ReportLayoutPage;
