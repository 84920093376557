import { useSelector } from 'react-redux';
import 'mapbox-gl/dist/mapbox-gl.css';
import './switcher/switcher.css';
import './magnifyingGlass/magnifyingGlass.css';
import mapboxgl from '!mapbox-gl';
import { SwitcherControl } from './switcher/switcher';
import { MagnifyingGlass } from './magnifyingGlass/magnifyingGlass';
import React, { useRef, useLayoutEffect, useEffect, useState } from 'react';
import { deviceCategories2D, deviceCategories3DStatic, deviceCategories3D } from '../common/deviceCategories';
import { poiCategories } from '../common/poiCategories';
import { otherIcons } from '../common/otherIcons';
import { loadIcon, loadImage, loadSprite } from './mapUtil';
import { styleGoogleMapsRoad, styleGoogleMapsSatellite, styleMapbox, styleOsm } from './mapStyles';
import t from '../common/localization';
import { useAttributePreference } from '../common/preferences';

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const element = document.createElement('div');
element.style.width = '100%';
element.style.height = '100%';

const mapboxAccessToken = 'pk.eyJ1IjoidHJhY2tpbmdpbnRlZ3JhdGlvbiIsImEiOiJja2w2eWU5NmIwYml3Mm9yMm5mM3ZsZzViIn0.7SZ1-O2V3-lgUReU2pW7hQ';
mapboxgl.accessToken = mapboxAccessToken;

export const map = new mapboxgl.Map({
  container: element,
  style: styleMapbox('streets-v11'), //styleOsm()
  center: [
    20,
    10
  ], 
  zoom: 2,
  maxZoom: 19,
  minZoom: 1
});

const iconColors = ['gray', 'green', 'red', 'yellow']

//Geofences
export const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    point: true,
    line_string: true,
    polygon: true
}});

let ready = false;
const readyListeners = new Set();

const addReadyListener = listener => {
  readyListeners.add(listener);
  listener(ready);
};

const removeReadyListener = listener => {
  readyListeners.delete(listener);
};

const updateReadyValue = value => {
  ready = value;
  readyListeners.forEach(listener => listener(value));
  //console.log('update.....')
};

const initMap = async () => {

  console.log(`Loading ${deviceCategories2D.length} 2D vehicles`)
  await Promise.all(deviceCategories2D.map(async category => {
    if (!map.hasImage(`${category.name}_gray`)) {
      const iconSize = 128;
      //console.log(`loading 2D: images/vehicles/${category.name}.png`)
      var sprite = await loadImage(`images/vehicles/${category.name}.png`)
      iconColors.map((color, index) => {
          //console.log(`- addImage ${category.name}_${color}`)
          map.addImage(`${category.name}_${color}`, loadSprite(sprite, iconSize*index, 0, iconSize, category.zoom), { pixelRatio: window.devicePixelRatio });
      })
    }
  }));

  console.log(`Loading ${deviceCategories3DStatic.length} 3D Static vehicles`)
  await Promise.all(deviceCategories3DStatic.map(async category => {
    if (!map.hasImage(`${category.name}_gray`)) {
      const iconSize = 100;
      //console.log(`loading 3DS: images/vehicles/${category.name}.png`)
      var sprite = await loadImage(`images/vehicles/${category.name}.png`)
      iconColors.map((color, index) => {
          //console.log(`- addImage ${category.name}_${color}`)
          map.addImage(`${category.name}_${color}`, loadSprite(sprite, iconSize*index, 0, iconSize, category.zoom), { pixelRatio: window.devicePixelRatio });
      })
    }
  }));

  console.log(`Loading ${deviceCategories3D.length} 3D vehicles`)
  await Promise.all(deviceCategories3D.map(async category => {
    if (!map.hasImage(`${category.name}_gray_0000`)) {
      const iconSize = 128;
      //console.log(`loading 3DS: images/vehicles/${category.name}.png`)
      var sprite = await loadImage(`images/vehicles/${category.name}.png`)
      var deltaX = 0;
      iconColors.map(color => {
        for (let angle = 0; angle < 360; angle += 30) {
          var paddedAngle = (angle+'').padStart(4, "0");
          //console.log(`- addImage ${category.name}_${color}_${paddedAngle}`, deltaX*iconSize, 0)
          map.addImage(`${category.name}_${color}_${paddedAngle}`,
            loadSprite(sprite, deltaX, 0, iconSize, category.zoom),
            { pixelRatio: window.devicePixelRatio });
          deltaX += iconSize;
        }
      })
    }
  }));

  console.log(`Loading POI`)
  await Promise.all(poiCategories.map(async poi => {
    if (!map.hasImage(poi.name)) {
      map.addImage(poi.name, await loadIcon(`pois/${poi.name}.png`, poi.zoom), { pixelRatio: window.devicePixelRatio });
    }
  }));
  
  console.log(`Loading other icons`)
  await Promise.all(otherIcons.map(async other => {
    if (!map.hasImage(other.name)) {
      map.addImage(other.name, await loadIcon(`${other.name}.png`, other.zoom), { pixelRatio: window.devicePixelRatio });
    }
  }));
  
  console.log(`Loading background`)
  await Promise.all(['background'].map(async other => {
    if (!map.hasImage(other)) {
      map.addImage(other, await loadIcon(`${other}.svg`), { pixelRatio: window.devicePixelRatio });
    }
  }));

  updateReadyValue(true);
  console.log('Map ready!')

  var layers = map.getStyle().layers;
 
  for (var i = 0; i < layers.length; i++) {
    if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {

      if (!map.getLayer('3d-buildings') && map.getSource('composite')) 
      map.addLayer(
        {
          'id': '3d-buildings',
          'source': 'composite',
          'source-layer': 'building',
          'filter': ['==', 'extrude', 'true'],
          'type': 'fill-extrusion',
          'minzoom': 15,
          'paint': {
            'fill-extrusion-color': '#aaa',
            
            // use an 'interpolate' expression to add a smooth transition effect to the
            // buildings as the user zooms in
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'height']
            ],
            'fill-extrusion-base': [
              'interpolate',
              ['linear'],
              ['zoom'],
              15,
              0,
              15.05,
              ['get', 'min_height']
            ],
            'fill-extrusion-opacity': 0.6
            }
          },
          layers[i].id
        );


      break;
    }
  }

  global.map_registerGeofenceEvent = true;
  global.map_loadGeofences = true;

  setElementTitle("mapbox-gl-draw_line", t('addPolyLine'));
  setElementTitle("mapbox-gl-draw_polygon", t('addGeofence'));
  setElementTitle("mapbox-gl-draw_point", t('addPoi'));
  setElementTitle("mapboxgl-style-container", 'Tipo de mapa');
  setElementTitle("mapboxgl-directions-container", 'Obter direcções');
  setElementTitle("mapboxgl-ctrl-icon", '');
  setElementTitle("mapboxgl-ctrl-zoom-in", 'Aumentar zoom');
  setElementTitle("mapboxgl-ctrl-zoom-out", 'Diminuir zoom');
  setElementTitle("mapboxgl-ctrl-compass", 'Alinhar mapa');
  setElementTitle("mapboxgl-ctrl-geolocate", 'A minha posição');

};

const setElementTitle = (className, title) => {
  var element = document.getElementsByClassName(className);
  for (let index = 0; index < element.length; index++) {
    element[index].title = title
      if (title == '') element[index].removeAttribute('title');
  }
}

map.on('load', initMap);

//Map search
var coordinatesGeocoder = function (query) {
  // match anything which looks like a decimal degrees coordinate pair
  var matches = query.match(
      /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
  );
  if (!matches) {
      return null;
  }

  function coordinateFeature(lng, lat) {
      return {
          center: [lng, lat],
          geometry: {
              type: 'Point',
              coordinates: [lng, lat]
          },
          place_name: 'Latitude: ' + lat + ' Longitude: ' + lng,
          place_type: ['coordinate'],
          properties: {},
          type: 'Feature'
      };
  }

  var coord1 = Number(matches[1]);
  var coord2 = Number(matches[2]);
  var geocodes = [];

  if (coord1 < -90 || coord1 > 90) {
      // must be lng, lat
      geocodes.push(coordinateFeature(coord1, coord2));
  }

  if (coord2 < -90 || coord2 > 90) {
      // must be lat, lng
      geocodes.push(coordinateFeature(coord2, coord1));
  }

  if (geocodes.length === 0) {
      // else could be either lng, lat or lat, lng
      geocodes.push(coordinateFeature(coord1, coord2));
      geocodes.push(coordinateFeature(coord2, coord1));
  }

  return geocodes;
};

// Add position to the map
function registerControlPosition(map, positionName, style) {
  if (map._controlPositions[positionName]) {
      return;
  }
  var positionContainer = document.createElement('div');
  positionContainer.className = `mapboxgl-ctrl-${positionName}`;
  positionContainer.style.cssText = style;
  map._controlContainer.appendChild(positionContainer);
  map._controlPositions[positionName] = positionContainer;
}
registerControlPosition(map, 'scale', 'position: absolute; right: 45px; top: 10px; border-style: none; background-color: #ffffff00;')


let lngDirections = {"pt": "pt-PT", "es": "es-ES", "fr": "fr-FR", "en": "es-US", "ar": "ar-AE"};

// Map MagnifyingGlass control
map.addControl(new MagnifyingGlass());

// Navigation control
map.addControl(
  new mapboxgl.NavigationControl({
    visualizePitch: true
  }), 'top-right');

map.addControl(
  new mapboxgl.GeolocateControl({
    positionOptions: {
      enableHighAccuracy: true,
      timeout: 5000,
    },
    trackUserLocation: true,
  }), 'top-right');

// Map switcher control
map.addControl(new SwitcherControl(
  [
    { title: t('layerRoad'), uri: styleMapbox('streets-v11') },
    { title: t('layerSatellite'), uri: styleMapbox('satellite-streets-v11') },  
    { title: t('layerOSM'), uri: styleOsm() },
    { title: 'Google Estradas', uri: styleGoogleMapsRoad() },
    { title: 'Google Satélite', uri: styleGoogleMapsSatellite() }
  ],
  t('layerRoad'),
  () => updateReadyValue(false),
  () => {
    const waiting = () => {
      if (!map.loaded()) {
        setTimeout(waiting, 1000);
      } else {
        initMap();
      }
    };
    waiting();
  },
), 'top-right');

// Scale control
map.addControl(
  new mapboxgl.ScaleControl({
    maxWidth: 100, unit: 'metric'
  }), 'bottom-right');

// Draw control
map.addControl(draw, 'top-right');

const Map = ({ children }) => {

  map.resize();

  const classes = useStyles();

  const containerEl = useRef(null);

  const [mapReady, setMapReady] = useState(false);

  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  const mapboxAccessToken = useAttributePreference('mapboxAccessToken');

  const temporaryAccess = useSelector(state => state.session.user && state.session.user.email.startsWith('temp_') );

  useEffect(() => {
    mapboxgl.accessToken = mapboxAccessToken;
  }, [mapboxAccessToken]);

  useEffect(() => {
    map.setLayoutProperty('country-label', 'text-field', ['get', 'name_' + (global.language || 'pt')]);

    //if (!temporaryAccess) map.addControl(draw, 'top-right');
    
    const listener = ready => setMapReady(ready);
    addReadyListener(listener);
    return () => {
      removeReadyListener(listener);
    };
  }, []);

  useLayoutEffect(() => {
    const currentEl = containerEl.current;
    currentEl.appendChild(element);
    if (map) {
      map.resize();
    }
    return () => {
      currentEl.removeChild(element);
    };
  }, [containerEl]);

  return (
    <div style={{ width: '100%', height: '100%' }} ref={containerEl}>
      {mapReady && children}
      {!mapReady &&
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
          <CircularProgress color="inherit" />
        </Backdrop>
      }
    </div>
  );
};

export default Map;