import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Select, MenuItem, InputLabel, FormGroup, FormControl, FormHelperText, Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, isWidthUp, Switch } from '@material-ui/core';
import SelectField from '../form/SelectField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TransferList from '../TransferList';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));


const WeekHandler = ({item, setItem}) => {

  return (
    <Accordion
      defaultExpanded={true}
      style={{ width: 'fit-content', marginBottom: '30px', marginTop: '20px', paddingRight: '20px' }}
      >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {t('workingHours')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <div>
            <span style={{ minWidth: '150px', display: 'inline-block' }}>
              <Switch color="primary"
                checked={item.hasOwnProperty('weekdays_value') ? item.weekdays_value == "1" : false}
                onChange={event => setItem({...item, weekdays_value: event.target.checked})}
                /> {t('week_weekdays')}
            </span>
            <span style={{ marginRight: '20px' }}>
              {t('start')}:&nbsp;
              <TextField
                style={{width: '50px'}}
                InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                value={item.weekdays_start || '09:00'}
                onChange={event => setItem({...item, weekdays_start: event.target.value})}
                />                
            </span>
            <span>
              {t('end')}:&nbsp;
              <TextField
                style={{width: '50px'}}
                InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                value={item.weekdays_end || '18:00'}
                onChange={event => setItem({...item, weekdays_end: event.target.value})}
                />
            </span>
          </div>
          <div>
            <span style={{ minWidth: '150px', display: 'inline-block' }}>
              <Switch color="primary"
                checked={item.hasOwnProperty('saturday_value') ? item.saturday_value == "1" : false}
                onChange={event => setItem({...item, saturday_value: event.target.checked})}
                /> {t('week_saturday')}
            </span>
            <span style={{ marginRight: '20px' }}>
              {t('start')}:&nbsp;
              <TextField
                style={{width: '50px'}}
                InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                value={item.saturday_start || '09:00'}
                onChange={event => setItem({...item, saturday_start: event.target.value})}
                />                
            </span>
            <span>
              {t('end')}:&nbsp;
              <TextField
                style={{width: '50px'}}
                InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                value={item.saturday_end || '13:00'}
                onChange={event => setItem({...item, saturday_end: event.target.value})}
                />
            </span>
          </div>
          <div>
            <span style={{ minWidth: '150px', display: 'inline-block' }}>
              <Switch color="primary"
                checked={item.hasOwnProperty('sunday_value') ? item.sunday_value == "1" : false}
                onChange={event => setItem({...item, sunday_value: event.target.checked})}
                /> {t('week_sunday')}
            </span>
            <span style={{ marginRight: '20px' }}>
              {t('start')}:&nbsp;
              <TextField
                style={{width: '50px'}}
                InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                value={item.sunday_start || '09:00'}
                onChange={event => setItem({...item, sunday_start: event.target.value})}
                />                
            </span>
            <span>
              {t('end')}:&nbsp;
              <TextField
                style={{width: '50px'}}
                InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                value={item.sunday_end || '13:00'}
                onChange={event => setItem({...item, sunday_end: event.target.value})}
                />
            </span>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const ReportSchedulePage = () => {
  const classes = useStyles();

  const [item, setItem] = useState();
  const user = useSelector(state => state.session.user)

  useEffect(() => {
    setItem({
      report_name: '',
      report_type: 'trips',
      report_ids: '',
      report_ids_type: 'V',
      report_periodicity: 'D',
      report_day: 1,
      report_format: 'pdf',
      report_destination: user.email,
      weekdays_value: true,
      weekdays_start: '09:00',
      weekdays_end: '18:00',
      saturday_value: true,
      saturday_start: '09:00',
      saturday_end: '13:00',
      sunday_value: false,
      sunday_start: '09:00',
      sunday_end: '13:00',
      geofences: '',
    })
    return () => {}
  }, []);

  useEffect(() => {
    return () => {}
  }, [item]);


  const [selectedDevices, setSelectedDevices] = useState([]);
  const [availableDevices, setAvailableDevices] = useState([]);
  const [availableDrivers, setAvailableDrivers] = useState([]);
  const [availableGeofences, setAvailableGeofences] = useState([]);
  const [availableGeofencesWithSpeedLimit, setAvailableGeofencesWithSpeedLimit] = useState([]);
  const [allowRoadSpeedLimitInScheduler, setAllowRoadSpeedLimitInScheduler] = useState(false);

  const availableReports = () => {
    var list = [
        {"id": "trips", "name": t('trips'), "idTypes": ["V", "D"]},
        {"id": "activity", "name": t('activity'), "idTypes": ["V", "D"]},
        {"id": "speeding", "name": t('speed'), "idTypes": ["V", "D"]},
        {"id": "km", "name": t('km'), "idTypes": ["V", "D"]},
        {"id": "chart_km", "name": t('chartKm'), "idTypes": ["V", "D"]},
        {"id": "positions", "name": t('positions'), "idTypes": ["V", "D"]},
        {"id": "alerts", "name": t('alerts'), "idTypes": ["V", "D"]},
        {"id": "zones", "name": t('zones'), "idTypes": ["V", "D"]},
      ];

    if (user.attributes.permissions && user.attributes.permissions.includes('custom_reports.luanda_speeding')) {
      list.push({"id": "luanda_speeding", "name": t('luandaSpeeding'), "idTypes": ["V"]})
    }
    if (user.attributes.permissions && user.attributes.permissions.includes('custom_reports.vista_waste')) {
      list.push({"id": "vista_waste", "name": "Actividade Vista Waste", "idTypes": ["V"]})
    }
    if (user.attributes.permissions && user.attributes.permissions.includes('custom_reports.detailed_activity')) {
      list.push({"id": "detailed_activity", "name": t('detailedActivity'), "idTypes": ["V"]})
    }
    if (user.attributes.permissions && user.attributes.permissions.includes('custom_reports.castel_daily')) {
      list.push({"id": "castel_daily", "name": t('reportCastelDaily'), "idTypes": ["V"]})
      list.push({"id": "castel_monthly", "name": t('reportCastelMonthly'), "idTypes": ["V"]})
      list.push({"id": "castel_monthly_detailed", "name": t('reportCastelMonthlyDetailed'), "idTypes": ["V"]})
    }
    if (global.has_canbus) {
      list.push({"id": "fuel_analysis", "name": t('fuelAnalysis'), "idTypes": ["V"]})
    }
    if (global.has_pto) {
      list.push({"id": "pto", "name": t('pto'), "idTypes": ["V"]})
    }

    list.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1);

    return list;
  }

  useEffect(() => {
    (async () => {
      const response = await fetch('/api/devices');
      if (response.ok) {
        setAvailableDevices(await response.json());
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await fetch('/api/devices');
      if (response.ok) {
        setAvailableDevices(await response.json());
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await fetch('/report/api.php/drivers');
      if (response.ok) {
        setAvailableDrivers(await response.json());
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await fetch('/api/geofences');
      if (response.ok) {
        var geofences = await response.json();
        var geofencesWithSpeedLimit = geofences.filter(geofence => geofence.attributes && geofence.attributes.speedLimit && geofence.attributes.speedLimit>0)

        for (let index = 0; index < geofencesWithSpeedLimit.length; index++) {
          var geofenceWithSpeedLimit = geofencesWithSpeedLimit[index];
          if (geofenceWithSpeedLimit.attributes && geofenceWithSpeedLimit.attributes.speedLimit && geofenceWithSpeedLimit.attributes.speedLimit>0) {
            geofencesWithSpeedLimit[index].name = geofencesWithSpeedLimit[index].name + " (" + Math.round((geofenceWithSpeedLimit.attributes.speedLimit * 1.852)) + " km/h)";
          }
        }
          
        setAvailableGeofences(geofences);
        setAvailableGeofencesWithSpeedLimit(geofencesWithSpeedLimit);
      }
    })();
  }, []);

  const updateEvents = (event) => {
    if (event.target.checked) {
      setItem({...item, text1_value: addToCsvString(item.text1_value, event.target.name) })
    } else {
      setItem({...item, text1_value: removeFromCsvString(item.text1_value, event.target.name) })
    }
  }

  const addToCsvString = (csvString, element) => {
    return csvString + (csvString != '' ? ',' : '') + element
  }
  const removeFromCsvString = (csvString, element) => {
    return csvString.replace(',' + element, '').replace(element, '')
  }

  const getReportTypes = (reporId) => {
    var reportTypes = "";
    availableReports().forEach(availableReport => {
      if (availableReport['id'] == reporId) {
        reportTypes = availableReport.idTypes
      }
    });
    return reportTypes;
  }

  return (
    <EditItemView endpoint="../report/api.php/report_scheduler" item={item} setItem={setItem} style={{maxWidth: 'max-content'}}>
      {item &&
        <div>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>

              <Tooltip title={t('scheduleNameTip')}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  value={item.report_name || ''}
                  onChange={event => setItem({...item, report_name: event.target.value})}
                  label={t('scheduleName')}
                  />              
              </Tooltip>
              <Tooltip title={t('scheduleEmailsTip')}>
                <TextField
                  variant="outlined"
                  multiline
                  margin="normal"
                  value={item.report_destination || ''}
                  onChange={event => setItem({...item, report_destination: event.target.value})}
                  label={t('scheduleEmails')}
                  />              
              </Tooltip>
              
              <RadioGroup
                row
                value={item.report_periodicity}
                onChange={(event) => setItem({...item, report_periodicity: event.target.value})}
                color="primary"
                style={{marginTop: '15px'}}
                >
                <Tooltip title={t('dailyTip')}>
                  <FormControlLabel value="D" control={<Radio color="primary"/>} label={t('daily')} />
                </Tooltip>
                <Tooltip title={t('weeklyTip')}>
                  <FormControlLabel value="W" control={<Radio color="primary"/>} label={t('weekly')} />
                </Tooltip>
                <Tooltip title={t('monthlyTip')}>
                  <FormControlLabel value="M" control={<Radio color="primary"/>} label={t('monthly')} />
                </Tooltip>
              </RadioGroup>

              <Typography component={'span'} style={{paddingTop: '10px', paddingBottom: '10px'}}>
              {item.report_periodicity == 'D' &&
                <span>
                  Será enviado um relatório todos os dias
                  </span>
              }
              {item.report_periodicity == 'W' &&
                <span>
                  Será enviado um relatório todos as semanas. Dia da semana:&nbsp;
                  <Select
                    value={item.report_day || 1}
                    onChange={(event) => setItem({...item, report_day: event.target.value})}>
                    <MenuItem value={0}>{t('week_sunday')}</MenuItem>
                    <MenuItem value={1}>{t('week_monday')}</MenuItem>
                    <MenuItem value={2}>{t('week_tuesday')}</MenuItem>
                    <MenuItem value={3}>{t('week_wednesday')}</MenuItem>
                    <MenuItem value={4}>{t('week_thursday')}</MenuItem>
                    <MenuItem value={5}>{t('week_friday')}</MenuItem>
                    <MenuItem value={6}>{t('week_saturday')}</MenuItem>
                  </Select>
                </span>
              }
              {item.report_periodicity == 'M' &&
                <span>
                  Será enviado um relatório todos os meses. Dia do mês:&nbsp;
                  <TextField
                    style={{position: 'relative', top: '-3px', maxWidth: '40px'}}
                    inputProps={{ type: 'number', style: { textAlign: 'center' }}}
                    value={item.report_day || 1}
                    onChange={event => setItem({...item, report_day: event.target.value>=1 && event.target.value<=31 ? event.target.value : 1})}
                    />
                </span>
              }
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion defaultExpanded style={{minWidth: '800px'}}>
            <AccordionDetails className={classes.details}>

              <SelectField
                margin="normal"
                value={item.report_type || 'trips'}
                emptyValue={null}
                onChange={event => setItem({...item, report_type: event.target.value, report_ids: ""})}
                data={availableReports}
                label={t('reportType')}
                />

              { getReportTypes(item.report_type).includes("D") &&
                <RadioGroup
                  row
                  value={item.report_ids_type || 'V'}
                  onChange={event => setItem({...item, report_ids_type: event.target.value, report_ids: ""})}
                  color="primary"
                  style={{marginTop: '10px'}}
                  >
                    <FormControlLabel value="V" control={<Radio color="primary"/>} label={t('byVehicle')} />
                    <FormControlLabel value="D" control={<Radio color="primary"/>} label={t('byDriver')} />
                </RadioGroup>
              }

              { item.report_ids_type == 'V' &&
                <TransferList
                  title={t('vehicles')}
                  allowShowGroups={true}
                  availableItems={availableDevices}
                  selectedItems={item.report_ids.split(',').filter(o=>o).map(item => parseInt(item))}
                  item={item}
                  setItem={setItem}
                  />
              }

              { item.report_ids_type == 'D' &&
                <TransferList
                  title={t('drivers')}
                  allowShowGroups={true}
                  availableItems={availableDrivers}
                  selectedItems={item.report_ids.split(',').filter(o=>o).map(item => parseInt(item))}
                  item={item}
                  setItem={setItem}
                  />
                }

              <div style={{marginTop: '20px', width: '50%'}}>
                {item.report_type == 'trips' &&
                  <FormControl>
                    <InputLabel>{t('tripFilter')}</InputLabel>
                    <Select
                      value={item.select1_value || 0}
                      onChange={event => setItem({...item, select1_value: event.target.value})}
                      style={{minWidth: '200px'}}
                      >
                        {[
                          [0, t('tripFilterShowAll')],
                          [1, t('tripFilterShowWork')],
                          [2, t('tripFilterShowPersonal')]
                        ].map(option => (
                          <MenuItem key={option[0]} value={option[0]}>
                            {option[1]}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                }
                {item.report_type == 'trips' && item.select1_value > 0 &&
                  <WeekHandler
                    item = {item}
                    setItem = {setItem}
                  />
                }
                {item.report_type == 'speeding' &&
                <>
                  <TextField
                    fullWidth
                    variant="outlined"
                    style={{display: 'block', marginBottom: '30px'}}
                    onChange={event => setItem({...item, text1_value: event.target.value})}
                    label={t('tolerance') + ' (km/h)'}
                    value={item.text1_value || 0}
                    />
                  <TextField
                    fullWidth
                    variant="outlined"
                    style={{display: 'block', marginBottom: '30px'}}
                    onChange={event => setItem({...item, text2_value: event.target.value})}
                    label={t('tolerance') + ' (' + t('secondsAbreviation') + ')'}
                    value={item.text2_value || 0}
                    />
                  <FormControlLabel
                    label={t('filterOnlySelectedGeofences')}
                    style={{display: 'block', marginBottom: '30px'}}
                    control={
                      <Switch color="primary"
                        checked={item.geofence_filter_value && item.geofence_filter_value == 1}
                        onChange={event => setItem({...item, geofence_filter_value: event.target.checked})}
                      />}
                    />
                  </>
                }
                {allowRoadSpeedLimitInScheduler && item.report_type == 'speeding' && 
                  <FormControlLabel
                    label={t('useRoadLimits')}
                    control={
                      <Switch color="primary"
                        checked={item.check1_value && item.check1_value == 1}
                        onChange={event => setItem({...item, check1_value: event.target.checked})}
                      />}
                    />
                }
                {item.report_type == 'luanda_speeding' &&
                  <>
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={{display: 'block', marginBottom: '30px'}}
                      onChange={event => setItem({...item, text1_value: event.target.value})}
                      label={t('luandaSpeedingInside')}
                      value={item.text1_value || 60}
                      />
                    <TextField
                      fullWidth
                      variant="outlined"
                      style={{display: 'block', marginBottom: '30px'}}
                      onChange={event => setItem({...item, text2_value: event.target.value})}
                      label={t('luandaOutsideInside')}
                      value={item.text2_value || 90}
                      />

                  </>
                }
                {item.report_type == 'activity' &&
                <>
                  <WeekHandler
                    item = {item}
                    setItem = {setItem}
                  />
                  <FormControlLabel
                    label={t('includeDailyDetails')}
                    control={
                      <Switch color="primary"
                        checked={item.check1_value && item.check1_value == 1}
                        onChange={event => setItem({...item, check1_value: event.target.checked})}
                      />}
                    />
                </>
                } 
                {item.report_type == 'alerts' &&
                  <FormControl component="fieldset" variant="standard" style={{marginTop: '20px'}}>
                    <FormHelperText>{t('eventTypes')}:</FormHelperText>
                    <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('geofenceEnter')} onChange={updateEvents} name="geofenceEnter" />} label={t('eventGeofenceEnter')} />
                    <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('geofenceExit')} onChange={updateEvents} name="geofenceExit" />} label={t('eventGeofenceExit')} />
                    <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('deviceOverspeed')} onChange={updateEvents} name="deviceOverspeed" />} label={t('eventDeviceOverspeed')} />
                    <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('deviceFuelDrop')} onChange={updateEvents} name="deviceFuelDrop" />} label={t('eventDeviceFuelDrop')} />
                    <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('alarmLowBattery')} onChange={updateEvents} name="alarmLowBattery" />} label={t('eventLowBattery')} />
                    <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('alarmSos')} onChange={updateEvents} name="alarmSos" />} label={t('eventSos')} />
                    <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('alarmHardAcceleration')} onChange={updateEvents} name="alarmHardAcceleration" />} label={t('eventHardAcceleration')} />
                    <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('alarmHardBraking')} onChange={updateEvents} name="alarmHardBraking" />} label={t('eventHardBraking')} />
                    <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('alarmHardCornering')} onChange={updateEvents} name="alarmHardCornering" />} label={t('eventHardCornering')} />
                    <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('alarmTampering')} onChange={updateEvents} name="alarmTampering" />} label={t('eventTampering')} />
                    { global.user_details.attributes.permissions && global.user_details.attributes.permissions.includes('global.alert_seatbelt_lights') &&
                      <FormControlLabel control={<Switch color="primary" checked={item.text1_value && item.text1_value.includes('alarmSeatbeltAndLights')} onChange={updateEvents} name="alarmSeatbeltAndLights" />} label={t('eventSeatbeltAndLights')} />
                    }
                  </FormControl>
                } 
                {item.report_type == 'fuel_analysis' &&
                  <FormControl>
                    <InputLabel>{t('analysisType')}</InputLabel>
                    <Select
                      value={item.select1_value || 0}
                      onChange={event => setItem({...item, select1_value: event.target.value})}
                      style={{minWidth: '200px'}}
                      >
                        {[[0, t('refills') + ' + ' + t('drops')], [1, t('refills')], [2, t('drops')]].map(option => (
                          <MenuItem key={option[0]} value={option[0]}>
                            {option[1]}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                }
                { item.report_type == 'zones' &&
                  <TransferList
                    title={t('zones')}
                    allowShowGroups={false}
                    availableItems={availableGeofences}
                    selectedItems={item.geofences ? item.geofences.split(',').filter(o=>o).map(item => parseInt(item)) : []}
                    item={item}
                    setItem={setItem}
                    insertField="geofences"
                    />
                }
                { item.report_type == 'speeding' && item.geofence_filter_value &&
                  <TransferList
                    title={t('zones')}
                    allowShowGroups={false}
                    availableItems={availableGeofencesWithSpeedLimit}
                    selectedItems={item.geofences ? item.geofences.split(',').filter(o=>o).map(item => parseInt(item)) : []}
                    item={item}
                    setItem={setItem}
                    insertField="geofences"
                    />
                }
              </div>
                
              <RadioGroup
                row
                value={item.report_format}
                onChange={(event) => setItem({...item, report_format: event.target.value})}
                style={{marginTop: '15px'}}
                >
                <FormControlLabel value="pdf" control={<Radio color="primary"/>} label="PDF" />
                <FormControlLabel value="excel" control={<Radio color="primary"/>} label="Excel" />
                <FormControlLabel value="csv" control={<Radio color="primary"/>} label="CSV" />
                <FormControlLabel value="xml" control={<Radio color="primary"/>} label="XML" />
              </RadioGroup>

            </AccordionDetails>
          </Accordion>
        </div>
      }
    </EditItemView>
  );
}

export default ReportSchedulePage;
