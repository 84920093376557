import React, { useState, useEffect } from 'react';
import { useEffectAsync } from '../reactHelper';
import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, FormControlLabel, Checkbox, TextField, Box, FormLabel, Container, Switch } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { prefixString, addEditString } from '../common/stringUtils';
import SelectField from '../form/SelectField';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { deployNotification } from "../WebNotification";
import { validateEmail } from '../common/stringUtils';
import TransferList from '../common/TransferList';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const NotificationPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState({
    type: '',
    always: true,
    notificators: ''
  });

  /*
  const [existingNotifications, setExistingNotifications] = useState([]);
  useEffectAsync(async () => {
    var response = await fetch('/api/notifications');
    if (response.ok) {
      setExistingNotifications(
        (await response.json())
        .map(notification => (
          notification.type == "alarm" && notification.attributes && notification.attributes.alarms
          ? notification.attributes.alarms
          : notification.type 
          ))
        );
    }
  }, []);

  console.log("existingNotifications", existingNotifications)
  */

  const testWebNotifications = () => {
    deployNotification(t('notificationSample'));
  }

  useEffect(() => {
    if (typeof(item) != 'undefined') {
      if (item.type == 'alarm') {
        if (item.attributes.hasOwnProperty('alarms')) {
          if (item.attributes.alarms == 'seatbelt,lights,seatbelt+lights') {
            setItem({...item, type: 'seatbeltAndLights'})
          }
          if (
              item.attributes.alarms == 'lowBattery'
              || item.attributes.alarms == 'sos'
              || item.attributes.alarms == 'hardAcceleration'
              || item.attributes.alarms == 'hardBraking'
              || item.attributes.alarms == 'hardCornering'
              || item.attributes.alarms == 'tampering'
              ) {
            setItem({...item, type: item.attributes.alarms})
          }
        }
      }
    }
    return () => {}
  }, [item]);
  

  /*
  {type: "textMessage"}
  {type: "deviceUnknown"}
  {type: "geofenceExit"}
  {type: "deviceOffline"}
  {type: "geofenceEnter"}
  {type: "deviceOnline"}
  {type: "ignitionOff"}
  {type: "deviceMoving"}
  {type: "deviceOverspeed"}
  {type: "ignitionOn"}
  {type: "commandResult"}
  {type: "driverChanged"}
  {type: "deviceStopped"}
  {type: "alarm"}
  {type: "deviceFuelDrop"}
  {type: "maintenance"}
  {type: "deviceInactive"}
  */  

  const notificationTypes = [
    {"type": "deviceOverspeed", "hardware": false},
    {"type": "geofenceEnter", "hardware": false},
    {"type": "geofenceExit", "hardware": false},
    {"type": "lowBattery", "hardware": true},
    {"type": "sos", "hardware": true},
    {"type": "hardAcceleration", "hardware": true},
    {"type": "hardBraking", "hardware": true},
    {"type": "hardCornering", "hardware": true},
    {"type": "tampering", "hardware": true},
  ];

  if (global.has_canbus)
    notificationTypes.push({"type": "deviceFuelDrop", "hardware": true})

  if (global.user_details.attributes.permissions && global.user_details.attributes.permissions.includes('global.alert_seatbelt_lights'))
    notificationTypes.push({"type": "seatbeltAndLights", "hardware": true})

  const setNotificator = (notificator, enabled) => {
    var newNotificators = item.notificators == "" ? [] : item.notificators.split(",")

    if (notificator == 'mail' && enabled && !validateEmail(global.user_details.email)) {
      enabled = false;
    }

    if (enabled) {
      newNotificators.push(notificator)
    } else {
      newNotificators = removeElementFromArray(newNotificators, notificator)
    }

    setItem({...item, notificators: newNotificators.join(',')})
  }

  function removeElementFromArray(array, element) {
    for( var i = 0; i < array.length; i++){
      if ( array[i] === element) {
        array.splice(i, 1); 
      }
    }
    return array;
  }

  return (
    <EditItemView endpoint="notifications" item={item} setItem={setItem} title={t('alertNotifications') + ' > ' + addEditString(t('addAlertNotification'), t('editAlertNotification'))} >
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>
              <SelectField
                margin="normal"
                value={item.type || 'alarm'}
                emptyValue={null}
                onChange={e => setItem({...item, type: e.target.value})}
                //endpoint="/api/notifications/types"
                data={notificationTypes}
                //dataDisabled={existingNotifications}
                keyGetter={it => it.type}
                titleGetter={it => t(prefixString('event', it.type)) + (it.hardware ? "" : "")}
                label={t('alertType')}
                />
                
              <TextField
                margin="normal"
                variant="outlined"
                style={{width: '100%'}}
                value={item.attributes && item.attributes.description ? item.attributes.description : ""}
                onChange={e => setItem({...item, attributes: {...item.attributes, description: e.target.value}})}
                label={t('description')}
                />
                
              <RadioGroup
                column
                value={item.always ? 'always' : 'selected'}
                onChange={(event) => setItem({...item, always: event.target.value == 'always'})}
                color="primary"
                style={{marginTop: '15px'}}
                >

                <FormLabel style={{fontSize: '0.75rem'}}>
                  {t('associatedVehicles')}:
                </FormLabel>

                <Tooltip title={t('allUserVehiclesTip')}>
                  <FormControlLabel
                    value="always"
                    control={<Radio color="primary"/>}
                    label={t('allUserVehicles')}
                    />
                </Tooltip>

                <Tooltip title={t('onlyVehiclesAssociatedToThisAlertTip')}>
                  <FormControlLabel
                    value="selected"
                    control={<Radio color="primary"/>}
                    label={t('onlyVehiclesAssociatedToThisAlert') + ":"}
                    />
                </Tooltip>

                { !item.always &&
                  <div style={{ marginBottom: '10px' }}>
                    <TransferList
                      title={t('associatedVehicles')}
                      allowShowGroups={true}
                      item={item}
                      setItem={setItem}
                      element="associated_devices"
                      available={["store", "devices"]}
                      associated={["api", "/report/api.php/devices", "notificationId"]}
                      fixedWidth={300}
                      />
                  </div>
                }

              </RadioGroup>

              <FormLabel style={{fontSize: '0.75rem', marginTop: '20px'}}>
                {t('deliveryMethod')}:
              </FormLabel>

              <FormControlLabel
                control={
                  <Switch
                    checked={item.notificators && item.notificators.includes('mail') || ''}
                    color="primary"
                    onChange={event => setNotificator('mail', event.target.checked)}
                    />
                }
                label={t('sendAlertByEmail')} />
              
              {
                item.notificators && item.notificators.includes('mail') &&
                (
                  <div style={{marginLeft: '45px'}}>
                    <Tooltip title={t('aditionalEmailsTip')}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        style={{width: '100%'}}
                        value={item.attributes && item.attributes.emailTo ? item.attributes.emailTo : global.user_details.email}
                        onChange={e => setItem({...item, attributes: {...item.attributes, emailTo: e.target.value}})}
                        label={"Para"}
                        />
                    </Tooltip>
                    <Tooltip title={t('aditionalEmailsTip')}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        style={{width: '100%'}}
                        value={item.attributes && item.attributes.emailCc ? item.attributes.emailCc : ""}
                        onChange={e => setItem({...item, attributes: {...item.attributes, emailCc: e.target.value}})}
                        label={"CC"}
                        />
                    </Tooltip>
                  </div>
                )
              }
              
              <FormControlLabel
                disabled={!global.user_details.phone || isNaN(global.user_details.phone) || global.user_details.phone == ''}
                control={
                  <Switch
                    checked={item.notificators && item.notificators.includes('sms') || ''}
                    color="primary"
                    onChange={event => setNotificator('sms', event.target.checked)}
                    />
                }
                label={t('sendAlertBySms') + ' ' + ((!global.user_details.phone || isNaN(global.user_details.phone) || global.user_details.phone == '') ? '('+t('notDefined')+')' : ((global.user_details && global.user_details.phone && global.user_details.phone.charAt(0) == '+' ? '' : '+')) + global.user_details.phone)} />

              {
                item.notificators && item.notificators.includes('sms') &&
                (
                  <div style={{marginLeft: '45px'}}>
                    <div style={{marginTop: '16px', marginBottom: '10px'}}>
                      {t('SmsLimitWarning')}
                    </div>
                  </div>
                )
              }

              <FormControlLabel
                control={
                  <Switch
                    checked={item.notificators && item.notificators.includes('web') || ''}
                    color="primary"
                    onChange={event => setNotificator('web', event.target.checked)}
                    />
                }
                label={t('sendAlertByWebNotification')} />

              {
                item.notificators && item.notificators.includes('web') &&
                (
                  <div style={{marginLeft: '45px'}}>
                    <Button
                      color="primary"
                      variant="outlined"
                      style={{width: 'fit-content', marginTop: '16px'}}
                      onClick={testWebNotifications}>
                      {t('testWebNotifications')}
                    </Button>
                  </div>
                )
              }

            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default NotificationPage;
