import { useEffect, useState, useCallback } from 'react';
import {  useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, InputAdornment, Tooltip, CircularProgress, makeStyles, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Accordion, AccordionSummary, AccordionDetails, Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, Button, TextField, ButtonGroup, IconButton, FormGroup, FormControlLabel, FormLabel, FormHelperText, Switch, Menu, Typography, ImageList, ImageListItem, ImageListItemBar, Snackbar } from '@material-ui/core';
import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import Pagination from '@material-ui/lab/Pagination';

import { getDeviceName } from './mapUtil';
import t from '../common/localization';
import RemoveDialog from '../RemoveDialog';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import GetAppIcon from '@material-ui/icons/GetApp';
import VideocamIcon from '@material-ui/icons/Videocam';
import LaunchIcon from '@material-ui/icons/Launch';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';

const CameraMap = ({cameraDialogOpen, setCameraDialogOpen}) => {

    const poolingTimeout = 10; //Time between AJAX requests
    const galleryPageSize = 10; //Gallery items per page

    const devices = useSelector(state => state.devices.items);
    const positions = useSelector(state => state.positions.items);
    const device = devices[cameraDialogOpen]

    const [downloadDialog, setDownloadDialog] = useState(false);
    const [cameraSelection, setCameraSelection] = useState(3);
    const [videoDateTime, setVideoDateTime] = useState(moment());
    const [videoDuration, setVideoDuration] = useState(10);
    const [fullScreen, setFullScreen] = useState(false);
    const [galleryPage, setGalleryPage] = useState(1);
    const [dialogTab, setDialogTab] = useState('latest');
    const [mediaList, setMediaList] = useState([]);
    const [lastPhotoFront, setLastPhotoFront] = useState(false);
    const [lastPhotoRear, setLastPhotoRear] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userMessage, setUserMessage] = useState(false);
    const [day, setDay] = useState(moment().startOf('day'));
    const [mediaToRemove, setMediaToRemove] = useState(false);

    moment.locale((global && global.language) ? global.language.replace('pt', 'pt-br').replace('en', 'en-gb').replace('ar', 'en-gb') : 'en-gb');

    const mediaStatus = [
      "",
      "Iniciou",
      "Em descarga",
      "A gravar",
      "Pronto",
      "Erro",
      "Eliminado",
    ];

    const eventStatus = [
      "Utilizador",
      "Ignição",
      "DIN2",
      "Acidente",
      "Reboque",
      "Ralenti",
      "Geofence",
      "Alimentação",
      "Condução",
      "Programado",
    ];

    const getCamera = (media) => {
      return media.camera == "F" ? t('road') : t('driver');
    }

    const getType = (media) => {
      return media.type == "P" ? "Foto" : "Vídeo";
    }

    useEffect(() => {
      if (cameraDialogOpen) {
        setGalleryPage(1);
        getMediaList(cameraDialogOpen);
        const interval = setInterval(() => {
          getMediaList(cameraDialogOpen);
        }, poolingTimeout*1000);
        return () => clearInterval(interval);
      }
    }, [cameraDialogOpen, day])


    const getMediaList = async (deviceId) => {
      setLoading(true);
      const response = await fetch(`/report/api.php/camera/${deviceId}/${day.format('YYYY-MM-DD')}`, { method: 'GET' });
      if (response.ok) {
        setMediaList(await response.json());
        setLoading(false);
      }
    }

    useEffect(() => {
      var mediaList_PhotoFront = mediaList.filter(media => media.status == 4 && media.type == 'P' && media.camera == "F")
      if (mediaList_PhotoFront.length>0) {
        setLastPhotoFront(mediaList_PhotoFront[0]);
       } else {
        setLastPhotoFront(false)
       }

      var mediaList_PhotoRear = mediaList.filter(media => media.status == 4 && media.type == 'P' && media.camera == "R")
      if (mediaList_PhotoRear.length>0) {
        setLastPhotoRear(mediaList_PhotoRear[0]);
      } else {
        setLastPhotoRear(false)
      }
    }, [mediaList])

    const sendCameraCommand = async () => {
      setLoading(true);
      const response = await fetch(`/report/api.php/camera`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          deviceId: cameraDialogOpen,
          type: downloadDialog,
          camera: cameraSelection,
          videoDateTime: Math.round(videoDateTime.valueOf()/1000),
          videoDuration: videoDuration,
        })
      });
      if (response.ok) {
        setUserMessage({
          message: "Comando enviado com sucesso",
          icon: "ok"
        })
        setLoading(false);
        setDownloadDialog(false);
      } else {
        setUserMessage({
          message: "Erro ao enviar comando",
          icon: "error"
        })
        setLoading(false);
      }
    }

    const NoImage = () => {
      return (
        <div style={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          width: '100%',
          height: '100%',
        }}>
          <div style={{
            position: 'relative',
            top: '45%',
            textAlign: 'center',
            color: 'white',
            fontSize: '1.5em'
          }}>
            {t('noImage')}
          </div>
        </div>
      )
    }
    
    const getCameraStatus = () => {

      if (cameraDialogOpen && positions[cameraDialogOpen]) {
        var attributes = positions[cameraDialogOpen].attributes
        
        const status = [
          'Camera not detected',
          'No SD Card',
          'SD Card failed',
          'SD Card OK',
          'SD Card faulty',
        ]

        if (!isNaN(attributes.io498) && !isNaN(attributes.io497)) {
          return "Road: " + status[attributes.io498] + " / Driver: " + status[attributes.io497];
        }
        if (attributes.result) {
          return attributes.result;
        }
      }

      return "";
    }
    
    const removeMedia = (mediaId) => {
      setMediaToRemove(mediaId)
    }
    
    const removeMediaResult = (result) => {
      if (result) {
        setMediaToRemove(false)
        getMediaList(cameraDialogOpen);
      } else {
        setMediaToRemove(false)
      }
    }
    
    return (
      <>
      <Dialog
        open={!!cameraDialogOpen}
        fullScreen={!global.isLandscape}
        onClose={() => setCameraDialogOpen(false)}
        maxWidth="xl">

        <DialogTitle style={{ paddingTop: '10px', paddingBottom: '0px' }}>
          <VideocamIcon style={{color: '#78A9FF', position: 'relative', top: '5px', fontSize: '1.5rem' }} /> { getDeviceName(device) }

          <div style={{ width: '110px', display: 'inline', right: '30px', position: 'absolute' }}>
            <FormControl margin="normal" style={{ display: 'inline' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  format="DD/MM/yyyy"
                  variant="inline"
                  autoOk={true}
                  value={day}
                  onChange={event => setDay(event)}

                  inputVariant="outlined"

                  InputProps={{
                    style: {
                      cursor: 'pointer',
                      borderRadius: '25px',
                      height: '30px',
                      width: '105%',
                      cursor: 'pointer',
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </div>

        </DialogTitle>

        <DialogContent>

          <Tabs
            value={dialogTab}
            onChange={(event, newValue) => setDialogTab(newValue)}
            variant="fullWidth"
            TabIndicatorProps={{style: {background: '#78A9FF'}}}
            textColor="primary"
            style={{minHeight: '0px'}}
            >
              <Tab label="Últimas imagems" value="latest"/>
              <Tab label="Histórico" value="history"/>
              <Tab label="Galeria" value="gallery"/>
          </Tabs>

          <div style={{ marginTop: '20px' }}>

            { dialogTab == 'latest' && (
              <div style={{ display: 'flex' }}>
                <ImageList rowHeight={300}>
                  
                  <ImageListItem key="front" style={{ width: '540px' }}>
                    { lastPhotoFront ?
                      <img src={"/report/api.php/media/" + lastPhotoFront.id} />
                      :
                      <NoImage />
                    }

                    <ImageListItemBar
                      title={t('road') + (lastPhotoFront ? `: (${lastPhotoFront.camera_date})` : '')}
                      actionIcon={ lastPhotoFront &&
                        <IconButton>
                          <Tooltip title="Remover">
                            <DeleteIcon
                              style={{ color: '#FF5555', cursor: 'pointer', marginBottom: '6px'}}
                              onClick={() => removeMedia(lastPhotoFront.id)}
                            />
                          </Tooltip>
                          <Tooltip title="Ver em ecrã inteiro">
                            <FullscreenIcon style={{ marginLeft: '10px', color: 'white', position: 'relative', top: '-3px', fontSize: '1.2em' }}
                            onClick={() => setFullScreen(lastPhotoFront)}
                            />
                          </Tooltip>
                          <Tooltip title="Abrir em novo separador">
                            <a href={"/report/api.php/media/" + lastPhotoFront.id + "/" + getDeviceName(device)} target="_blank" style={{ color: 'white' }}>
                              <LaunchIcon style={{ marginLeft: '10px' }}/>
                            </a>
                          </Tooltip>
                          <Tooltip title="Download">
                            <a href={"/report/api.php/media/" + lastPhotoFront.id + "/" + getDeviceName(device)} download style={{ color: 'white' }}>
                              <GetAppIcon style={{ marginLeft: '10px' }}/>
                            </a>
                          </Tooltip>
                        </IconButton>
                      }
                    />
                  </ImageListItem>

                  <ImageListItem key="rear" style={{ width: '540px' }}>
                    { lastPhotoRear ?
                      <img src={"/report/api.php/media/" + lastPhotoRear.id} />
                      :
                      <NoImage />
                    }
                    <ImageListItemBar
                      title={t('driver') + (lastPhotoRear ? `: (${lastPhotoRear.camera_date})` : '')}
                      actionIcon={ lastPhotoRear &&
                        <IconButton>
                          <Tooltip title="Remover">
                            <DeleteIcon
                              style={{ color: '#FF5555', cursor: 'pointer', marginBottom: '6px'}}
                              onClick={() => removeMedia(lastPhotoRear.id)}
                            />
                          </Tooltip>
                          <Tooltip title="Ver em ecrã inteiro">
                            <FullscreenIcon style={{ marginLeft: '10px', color: 'white', position: 'relative', top: '-3px', fontSize: '1.2em' }}
                            onClick={() => setFullScreen(lastPhotoRear)}
                            />
                          </Tooltip>
                          <a href={"/report/api.php/media/" + lastPhotoRear.id + "/" + getDeviceName(device)} target="_blank" style={{ color: 'white' }}>
                            <LaunchIcon style={{ marginLeft: '10px' }}/>
                          </a>
                          <a href={"/report/api.php/media/" + lastPhotoRear.id + "/" + getDeviceName(device)} download style={{ color: 'white' }}>
                            <GetAppIcon style={{ marginLeft: '10px' }}/>
                          </a>
                        </IconButton>
                      }
                    />
                  </ImageListItem>

                </ImageList>
              </div>
            )}

            { dialogTab == 'history' && (
                  <TableContainer style={{ height: '400px' }}>
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell>Data da imagem</TableCell>
                          <TableCell>Tipo</TableCell>
                          <TableCell>Vista</TableCell>
                          <TableCell>Origem</TableCell>
                          <TableCell>Estado</TableCell>
                          <TableCell>Duração</TableCell>
                          <TableCell>Tamanho</TableCell>
                          <TableCell>Data de descarga</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {mediaList.map((media, index) => (
                          <TableRow key={media.id}>
                            
                            <TableCell style={{ padding: '0px' }}>
                              { media.status == 4 &&
                              <>
                                <Tooltip title="Remover">
                                  <DeleteIcon
                                    style={{ color: '#FF5555', cursor: 'pointer', marginBottom: '2px'}}
                                    onClick={() => removeMedia(media.id)}
                                  />
                                </Tooltip>
                                <Tooltip title="Ver em ecrã inteiro">
                                  <FullscreenIcon style={{ position: 'relative', top: '1px', fontSize: '2em', color: '#78A9FF', cursor: 'pointer' }}
                                  onClick={() => setFullScreen(media)}
                                  />
                                </Tooltip>
                                <Tooltip title="Abrir em novo separador">
                                  <a href={"/report/api.php/media/" + media.id + "/" + getDeviceName(device)} target="_blank" style={{ }}>
                                    <LaunchIcon style={{ }}/>
                                  </a>
                                </Tooltip>
                                <Tooltip title="Download">
                                  <a href={"/report/api.php/media/" + media.id + "/" + getDeviceName(device)} download style={{ }}>
                                    <GetAppIcon style={{ }}/>
                                  </a>
                                </Tooltip>
                              </>
                              }
                            </TableCell>
                            <TableCell>{media.camera_date}</TableCell>
                            <TableCell>{ getType(media) }</TableCell>
                            <TableCell>{ getCamera(media) }</TableCell>
                            <TableCell>{ eventStatus[media.event] }</TableCell>
                            <TableCell>{ mediaStatus[media.status] + (media.status == 2 ? ` (${media.progress} %)` : "") + (media.status == 6 ? ` (${media.removed_by})` : "")}</TableCell>
                            <TableCell>{ media.duration }</TableCell>
                            <TableCell>{Math.round(media.size/1024, 1)} kB</TableCell>
                            <TableCell>{media.creation_date}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
            )}
            
            { dialogTab == 'gallery' && (
              <>
                <ImageList rowHeight={300} style={{ maxWidth: '1080px' }}>
                  {mediaList.filter(media => media.status == 4).slice((galleryPage-1) * galleryPageSize, galleryPage * galleryPageSize).map((media) => (
                    <ImageListItem key={media.id} style={{ width: '540px' }}>


                      { media.type == "P" &&
                        <img src={'/report/api.php/media/'+ media.id}></img>
                      }
                      { media.type == "V" &&
                        <span style={{ height: '100%', display: 'block', width: '100%', textAlign: 'center' }}>
                          <video controls height="84%" >
                            <source src={'/report/api.php/media/'+ media.id} type="video/mp4" />
                            Sorry, your browser doesn't support embedded videos.
                          </video>
                        </span>
                      }




                      <ImageListItemBar
                        title={getCamera(media) + ": " + media.camera_date}
                        actionIcon={
                          <IconButton>
                            <Tooltip title="Remover">
                              <DeleteIcon
                                style={{ color: '#FF5555', cursor: 'pointer', marginBottom: '6px'}}
                                onClick={() => removeMedia(media.id)}
                              />
                            </Tooltip>
                            { media.type == "P" &&
                              <Tooltip title="Ver em ecrã inteiro">
                                <FullscreenIcon style={{ marginLeft: '10px', color: 'white', position: 'relative', top: '-3px', fontSize: '1.2em' }}
                                onClick={() => setFullScreen(media)}
                                />
                              </Tooltip>
                            }
                            <a href={"/report/api.php/media/" + media.id + "/" + getDeviceName(device)} target="_blank" style={{ color: 'white' }}>
                              <LaunchIcon style={{ marginLeft: '10px' }}/>
                            </a>
                            <a href={"/report/api.php/media/" + media.id + "/" + getDeviceName(device)} download style={{ color: 'white' }}>
                              <GetAppIcon style={{ marginLeft: '10px' }}/>
                            </a>
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  ))}
                </ImageList>

                { false &&

                  <ImageListItem key="more" style={{ height: '50px', marginTop: '20px', marginBottom: '20px' }} cols={2}>
                    <div style={{ textAlign: 'center' }}>
                      <Button color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}
                        onClick={() => setGalleryPage(galleryPage + 18)}>
                        {t('loadMore')}
                      </Button>
                    </div>
                  </ImageListItem>
                }
                <div style={{
                  marginTop: '20px',
                  marginBottom: '20px',
                  justifyContent: 'center',
                  display: 'flex'
                  }}>
                  <Pagination
                    count={ Math.ceil(mediaList.filter(media => media.status == 4 && media.type == 'P').length / galleryPageSize) }
                    color="primary"
                    onChange={(e, value) => setGalleryPage(value)}
                    showFirstButton={true}
                    showLastButton={true}
                    siblingCount={2}
                    />
                </div>
              </>
            )}

          </div>

        </DialogContent>

        <DialogActions>

          { loading && false &&
            <span style={{ position: 'absolute', left: '25px', bottom: '15px' }}>
              <Tooltip title="Em actualização...">
                <CircularProgress size={23} thickness={7}/>
              </Tooltip>
            </span>
          }

          {
            <div style={{ position: 'absolute', left: '0px', marginLeft: '25px', fontSize: '0.75em' }}>
              {getCameraStatus()}
            </div>
          }

          <Button color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }} onClick={() => setDownloadDialog('photo')}>
            {t('takePhoto')}
          </Button>
          { 
            <Button color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }} onClick={() => setDownloadDialog('video')}>
              {t('takeVideo')}
            </Button>
          }
          <Button color="primary" variant="contained" style={{paddingLeft: '40px', paddingRight: '40px' }} onClick={() => setCameraDialogOpen(false)}>
            {t('back')}
          </Button>
        </DialogActions>

    </Dialog>

    <Dialog
      open={fullScreen}
      fullScreen={true}
      PaperProps={{
        style: {
          padding: '0px',
          textAlign: 'center',
        }}}>
        <DialogContent style= {{ padding: '0px', overflow: 'auto' }}>
          { fullScreen.type == "P" &&
            <img src={'/report/api.php/media/'+ fullScreen.id} height="99%"></img>
          }
          { fullScreen.type == "V" &&
            <video controls height="99%">
              <source src={'/report/api.php/media/'+ fullScreen.id} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          }
          { fullScreen && false &&
            <div>
              { getDeviceName(device) + " / " + getCamera(fullScreen) + " / " + fullScreen.camera_date}
              <IconButton>
                <Tooltip title="Download">
                  <a href={"/report/api.php/media/" + fullScreen.id + "/" + getDeviceName(device)} download style={{ color: 'white' }}>
                    <GetAppIcon style={{ marginLeft: '10px' }}/>
                  </a>
                </Tooltip>
              </IconButton>
            </div>
          }
          <Button
            color="primary"
            variant="contained"
            style={{
              padding: '5px 15px 5px 15px',
              position: 'absolute',
              right: '10px',
              bottom: '10px',
            }}
            onClick={() => setFullScreen(false)}>
            {t('back')}
          </Button>
        </DialogContent>
    </Dialog>

      <Dialog open={userMessage} onClose={() => setUserMessage(false)}>
        <DialogContent>
          <DialogContentText component={'span'}>
            <Grid container spacing={1}>
              { userMessage.icon == 'ok' &&
                <CheckCircleIcon  fontSize="medium" style={{color: '#00bb00'}}/>
              }
              { userMessage.icon == 'error' &&
                <ErrorIcon fontSize="medium" style={{color: '#bb0000'}}/>
              }
              &nbsp;{userMessage.message}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setUserMessage(false) }} color="primary" variant="outlined">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={downloadDialog} onClose={() => setDownloadDialog(false)}>
        <DialogContent>
          <DialogContentText component={'span'}>

            <Typography>
              { downloadDialog == 'photo' && t('takePhoto')}
              { downloadDialog == 'video' && t('takeVideo')}
            </Typography>
            <br />

            { downloadDialog == 'video' &&
              <>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <DateTimePicker
                    label={t('date')}
                    format="DD/MM/yyyy HH:mm:ss"
                    views={["hours", "minutes", "seconds"]}
                    ampm={false}
                    variant="outlined"
                    value={videoDateTime}
                    onChange={setVideoDateTime}
                  />
                </MuiPickersUtilsProvider>
                <br />
                <br />

                <TextField
                  value={videoDuration}
                  onChange={(event) => event.target.value<5 ? 5 : event.target.value>10 ? 10 : setVideoDuration(event.target.value)}
                  variant="outlined"
                  type="number"
                  label={t('duration') + " (s)"}
                />
                <br />
                <br />
              </>
            }

            <TextField
              select
              value={cameraSelection}
              onChange={(event) => setCameraSelection(event.target.value)}
              variant="outlined"
              label={t('cameras')}
            >
              <MenuItem value={1}>{t('road')}</MenuItem>
              <MenuItem value={2}>{t('driver')}</MenuItem>
              <MenuItem value={3}>{t('road') + ' + ' + t('driver')}</MenuItem>
            </TextField>
            <br />


          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { sendCameraCommand() }} color="primary" variant="outlined">
            OK
          </Button>
          <Button onClick={() => { setDownloadDialog(false) }} color="primary" variant="outlined">
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <RemoveDialog
        open={mediaToRemove>0}
        endpoint='../report/api.php/media'
        itemId={mediaToRemove}
        onResult={removeMediaResult}
        itemName='imagem'
      />

    </>
    );
}

export default CameraMap;