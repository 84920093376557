import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import MainToolbar from './MainToolbar';
import { Permissions } from './Permissions';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
    overflow: 'auto',
    padding: theme.spacing(2),
    padding: '5px'
  },
  center: {
    transform: 'translate(-50%, -50%)',
    top: '50%',
    position: 'absolute',
    left: '50%',
  },
}));

const Blank = () => {
  const history = useHistory();

  for (const permission of Permissions(global.user).filter(permission => permission.enabled)) {
    const defaultPage = permission.items.find(item => item.enabled);
    if (defaultPage) {
      history.push(defaultPage.menu.path);
      break;
    }
  }

  const classes = useStyles();

  global.selectedMenu = "/blank"

  const backgrounds = 9;

  const backgroundIndex = Math.floor(Math.random()*backgrounds)+1;

  return (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}
        style={{
          background: 'url(images/backgrounds/'+backgroundIndex+'.jpg) no-repeat center center fixed',
          backgroundSize: 'cover',
          height: '100%',
          width: '100%',
          position: 'absolute',
          left: '0px',
          top: '0px',
          overflow: 'hidden',
        }}
        >
        <img src="/logo.png" width={200}
          style={{
            top: '70px',
            right: '40px',
            position: 'absolute',
          }}
          />
      </div>
    </div>
  )
}

export default Blank;
