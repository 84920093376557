import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import { getDeviceName } from './map/mapUtil';
import ExportButton from './common/ExportButton';
import MenuTitle from './MenuTitle';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tabs, Tab, Snackbar, InputAdornment, Tooltip, CircularProgress, makeStyles, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box, Accordion, AccordionSummary, AccordionDetails, Grid, Checkbox, FormControl, InputLabel, Select, MenuItem, Button, TextField, ButtonGroup, IconButton, FormGroup, FormControlLabel, FormLabel, FormHelperText, Switch, Menu, Typography, RadioGroup, Radio, Backdrop } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ErrorIcon from '@material-ui/icons/Error';

import { DataGrid, ptBR } from '@material-ui/data-grid';

//https://github.com/Hacker0x01/react-datepicker/
//https://reactdatepicker.com/
//https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt';

import moment from 'moment';
import MomentUtils from '@date-io/moment';

import MainToolbar from './MainToolbar';

import t from './common/localization';
import { validateEmail } from './common/stringUtils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'

const colorRed = 'rgba(255, 0, 0, 0.3)';
const colorYellow = 'rgba(255, 255, 0, 0.3)';
const colorGreen = 'rgba(0, 255, 0, 0.3)';
const colorBlue = 'rgba(0, 0, 255, 0.3)';

const useStyles = makeStyles(theme => ({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: 1,
      overflow: 'auto',
      padding: theme.spacing(2),
      padding: '5px'
    },
    dataGrid: {
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': { outline: 'none' },
    },
  }));


const EventPanel = () => {

    const classes = useStyles();

    const [mapStyle, setMapStyle] = useState("streets-v12");
    const [infoDialog, setInfoDialog] = useState(false);
    const [eventList, setEventList] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState({});
    const [selectedEventOriginal, setSelectedEventOriginal] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterStatus, setFilterStatus] = useState(['all']);
    const [filterEvent, setFilterEvent] = useState(['all']);
    const [filterGroup, setFilterGroup] = useState(['all']);
    const [filterDevice, setFilterDevice] = useState(['all']);
    const [filterDriver, setFilterDriver] = useState(['all']);
    const [filterText, setFilterText] = useState('');
    const [updateResult, setUpdateResult] = useState(false);
    const [dialogTab, setDialogTab] = useState('details');
    const [eventHistory, setEventHistory] = useState([]);
    const [loadingEventHistory, setLoadingEventHistory] = useState(false);
    const [eventHistoryLoaded, setEventHistoryLoaded] = useState(false);
    const [emailDialog, setEmailDialog] = useState(false);
    const [emailTo, setEmailTo] = useState("");
    const [emailCc, setEmailCc] = useState(validateEmail(useSelector(state => state.session.user.email)) ? useSelector(state => state.session.user.email) : '');
    const groups = useSelector(state => state.groups.items);

    const groupsArray = Object.values(global.groups);
    const devicesArray = Object.values(global.devices);
    const driversArray = Object.values(global.drivers);

    const filterColor = '#78A9FF50'

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);

    const maximumPeriodDays = 31;

    const [resultStatus, setResultStatus] = useState(false);
    const [statusDialog, setStatusDialog] = useState({title: '', message: ''});
  
    const showStatusDialog = (title, message) => {
      setStatusDialog({title: title, message: message});
      setResultStatus(true);
    }
  
    const hideStatusDialog = () => {
      setResultStatus(false);
    };

    const dateButtonLabel = () => {
      var label = moment(startDate).format('DD/MM/YYYY')
      if (endDate) {
        if (moment(endDate).format('DD/MM/YYYY') != moment(startDate).format('DD/MM/YYYY')) label += ' - ' + moment(endDate).format('DD/MM/YYYY')
      } else {
        label += ' - ...'
      }
      return label
    }

    useEffect(() => {
      if (!showCalendar && !endDate) {
        setEndDate(startDate);
      }
    }, [showCalendar])

    const handleFilterStatusChange = (event) => {
      var newValue = event.target.value;
      if (newValue.includes('all') && filterStatus.includes('all') && newValue.length>1) { //Retira o "all"
        newValue = event.target.value.filter(item => item != 'all');
      }
      if ((newValue.includes('all') && !filterStatus.includes('all')) || newValue.length==0) { //Deixa só o "all"
        newValue = ['all'];
      }
      setFilterStatus(newValue)
    }

    const handleFilterEventChange = (event) => {
      var newValue = event.target.value;
      if (newValue.includes('all') && filterEvent.includes('all') && newValue.length>1) { //Retira o "all"
        newValue = event.target.value.filter(item => item != 'all');
      }
      if ((newValue.includes('all') && !filterEvent.includes('all')) || newValue.length==0) { //Deixa só o "all"
        newValue = ['all'];
      }
      setFilterEvent(newValue)
    }
    
    const handleFilterGroupChange = (event) => {
      var newValue = event.target.value;
      if (newValue.includes('all') && filterGroup.includes('all') && newValue.length>1) { //Retira o "all"
        newValue = event.target.value.filter(item => item != 'all');
      }
      if ((newValue.includes('all') && !filterGroup.includes('all')) || newValue.length==0) { //Deixa só o "all"
        newValue = ['all'];
      }
      setFilterGroup(newValue)
    }
    
    const handleFilterDeviceChange = (event) => {
      var newValue = event.target.value;
      if (newValue.includes('all') && filterDevice.includes('all') && newValue.length>1) { //Retira o "all"
        newValue = event.target.value.filter(item => item != 'all');
      }
      if ((newValue.includes('all') && !filterDevice.includes('all')) || newValue.length==0) { //Deixa só o "all"
        newValue = ['all'];
      }
      setFilterDevice(newValue)
    }
    
    const handleFilterDriverChange = (event) => {
      var newValue = event.target.value;
      if (newValue.includes('all') && filterDriver.includes('all') && newValue.length>1) { //Retira o "all"
        newValue = event.target.value.filter(item => item != 'all');
      }
      if ((newValue.includes('all') && !filterDriver.includes('all')) || newValue.length==0) { //Deixa só o "all"
        newValue = ['all'];
      }
      setFilterDriver(newValue)
    }
    
    useEffect(() => {
      if (endDate) getEvents();
    }, [endDate])

    useEffect(() => {
      if (dialogTab == "history" && !eventHistoryLoaded ) {
        getEventHistory();
      }
    }, [dialogTab])

    const getEventHistory = async (auto) => {
      setLoadingEventHistory(true)
      const response = await fetch(`/report/api.php/event_details/${selectedEvent.id}`, { method: 'GET' });
      if (response.ok) {
        setEventHistory(await response.json());
        setLoadingEventHistory(false)
        setEventHistoryLoaded(true)
      }
    }

    global.selectedMenu = "/events"

    var availableEvents = [
      {id: 'alarmlowBattery', name: 'Bateria Baixa'},
      {id: 'alarmsos', name: 'Botão de Pânico'},
      {id: 'alarmhardBraking', name: 'Travagem Brusca'},
      {id: 'alarmhardCornering', name: 'Curva Brusca'},
      {id: 'alarmhardAcceleration', name: 'Aceleração Brusca'},
      {id: 'alarmtampering', name: 'Manipulação de equipamento'},

      {id: 'blockRequest', name: 'Bloqueio'},
      {id: 'unBlockRequest', name: 'Desbloqueio'},
      {id: 'deviceOverspeed', name: 'Velocidade'},
      {id: 'geofenceEnter', name: 'Entrada em Zona'},
      {id: 'geofenceExit', name: 'Saída de Zona'},
      {id: 'deviceFuelDrop', name: 'Combustível'}      
    ]

    if (global.user_details.attributes.permissions && global.user_details.attributes.permissions.includes('global.alert_seatbelt_lights')) {
      availableEvents.push({id: 'alarmseatbelt', name: 'Cinto'})
      availableEvents.push({id: 'alarmlights', name: 'Luzes'})
      availableEvents.push({id: 'alarmseatbelt+lights', name: 'Cinto+Luzes'})
    }

    const availableStatus = [
      {id: 0, name: 'Por resolver', color: colorRed},
      {id: 1, name: 'Em resolução', color: colorYellow},
      {id: 2, name: 'Resolvido', color: colorGreen},
      {id: 3, name: 'Ignorado', color: colorBlue},
    ]

    const getStatusColor = (statusId) => {
      const statusDetails = availableStatus.filter(status => status.id == statusId)
      return statusDetails.length>0 ? statusDetails[0].color : '';
    }

    const getEventName = (id) => {
      if (id == "all") return "Todos os alertas";
      const eventDetails = availableEvents.filter(event => event.id == id)
      return eventDetails.length>0 ? eventDetails[0].name : '';
    }

    const getStatusName = (id) => {
      if (id == "all") return "Todos os estados";
      return [id] ? availableStatus[id].name : '';
    }

    const getGroupName = (id) => {
      if (id == "all") return "Todos os grupos";
      const item = groupsArray.filter((item) => item.id == id);
      return item && item[0] ? item[0].name : '';
    }

    const getDeviceName = (id) => {
      if (id == "all") return "Todos os veículos";
      const item = devicesArray.filter((item) => item.id == id);
      return item && item[0] ? item[0].name : '';
    }

    const getDriverName = (id) => {
      if (id == "all") return "Todos os condutores";
      const item = driversArray.filter((item) => item.id == id);
      return item && item[0] ? item[0].name : '';
    }

    const openInfoDialog = (event, item) => {
      item.newStatusDetails = '';
      setSelectedEvent(item)
      setSelectedEventOriginal(item)
      setDialogTab('details')
      setEventHistoryLoaded(false)
      setEventHistory([])
      setInfoDialog(true);
    }

    const closeInfoDialog = (event) => {
      setInfoDialog(false);
    }

    const columns = [
        {
          field: 'id',
          headerName: 'Estado',
          parser: (item) => item.lastStatusName,
          sortable: false,
          minWidth: 110,
          renderCell: (item) => (
            <Button
              onClick={(event) => {openInfoDialog(event, item.row)}}
              style={{
                backgroundColor: getStatusColor(item.row.lastStatusId),
                height: '25px',
                fontSize: '0.7em',
                border: 'none',
                minWidth: '90px'
              }}
              variant="outlined">
                {item.row.lastStatusName}
            </Button>
          ),
        },
        {
          field: 'eventTime',
          headerName: 'Data',
          parser: (item) => item.eventTime,
          sortable: false,
          flex: 1.25,
          minWidth: 100,
        },
        {
          field: 'eventType',
          headerName: 'Tipo',
          parser: (item) => getEventName(item.eventType),
          sortable: false,
          flex: 1,
          width: 130,
          renderCell: (item) => (
            <>
              {getEventName(item.value)}
            </>
          ),
        },
        {
          field: 'details',
          headerName: 'Detalhes',
          parser: (item) => item.details,
          sortable: false,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'groupId',
          headerName: 'Grupo',
          parser: (item) => groups[item.groupId] ? groups[item.groupId].name : '',
          sortable: false,
          flex: 1,
          minWidth: 100,
          renderCell: (item) => (
            <>
              {groups[item.value] ? groups[item.value].name : ''}
            </>
          ),
        },
        {
          field: 'deviceLicensePlate',
          headerName: 'Matrícula',
          parser: (item) => item.deviceLicensePlate,
          sortable: false,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'deviceName',
          headerName: 'Veículo',
          parser: (item) => item.deviceName,
          sortable: false,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'driverName',
          headerName: 'Condutor',
          parser: (item) => item.driverName,
          sortable: false,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'lastStatusUser',
          headerName: 'Operador',
          parser: (item) => item.lastStatusUser,
          sortable: false,
          flex: 1,
          minWidth: 100,
        },
        {
          field: 'lastStatusDetails',
          headerName: 'Observações',
          parser: (item) => item.lastStatusDetails,
          sortable: false,
          flex: 1,
          minWidth: 100,
        },
      ];

    const getEvents = async () => {
      if (moment(endDate).diff(moment(startDate), 'days')+1 > maximumPeriodDays) {
        showStatusDialog('Oops!', `Por favor seleccione um perído máximo de ${maximumPeriodDays} dias`)
      } else {
        setLoading(true);
        const response = await fetch(`/report/api.php/events/${moment(startDate).format('YYYY-MM-DD')}/${moment(endDate).format('YYYY-MM-DD')}`, { method: 'GET' });
        if (response.ok) {
          setEventList(await response.json());
          setLoading(false);
        }        
      }
    }

    async function updateEvent() {

      const response = await fetch('/report/api.php/event/' + selectedEvent.id, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(
            {
              status: selectedEvent.lastStatusId,
              details: selectedEvent.newStatusDetails
            }),
        });

      
      if (response.ok) {
        setUpdateResult({message: "Evento alterado com sucesso", color: 'green'})
      } else {
        setUpdateResult({message: "Erro ao alterar evento", color: 'red'})
      }

      getEvents(false);
      setInfoDialog(false);
    }

    const filterEvents = (eventList) => {
      
      return eventList.filter(event => 

          (!filterStatus.includes('all') ? filterStatus.includes(parseInt(event.lastStatusId)) : true)
          && (!filterEvent.includes('all') ? filterEvent.includes(event.eventType) : true)
          && (!filterGroup.includes('all') ? filterGroup.includes(parseInt(event.groupId)) : true)
          && (!filterDevice.includes('all') ? filterDevice.includes(parseInt(event.deviceId)) : true)
          && (!filterDriver.includes('all') ? filterDriver.includes(parseInt(event.driverId)) : true)
          && (filterText != '' ?
            (event.deviceName && event.deviceName.toLowerCase().includes(filterText.toLowerCase()))
            || (event.deviceLicensePlate && event.deviceLicensePlate.toLowerCase().includes(filterText.toLowerCase()))
            || (event.driverName && event.driverName.toLowerCase().includes(filterText.toLowerCase()))
            || (event.driverPhone && event.driverPhone.toLowerCase().includes(filterText.toLowerCase()))
            : true)
        );
    }

    const sendemail = async () => {
      if (validateEmail(emailTo)) {
        if (validateEmail(emailCc) || emailCc == '') {

          const response = await fetch('/report/api.php/share_event/' + selectedEvent.id, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(
              {
                emailTo: emailTo,
                emailCc: emailCc,
              }),
          });

          if (response.ok) {
            setUpdateResult({message: "Evento enviado por e-mail!", color: 'green'})
          } else {
            setUpdateResult({message: "Erro a enviar e-mail!", color: 'red'})
          }
          setEmailDialog(false)
        } else {
          setUpdateResult({message: "Endereço de e-mail de CC inválido", color: 'red'})
        }
      } else {
        setUpdateResult({message: "Endereço de e-mail de destino inválido", color: 'red'})
      }
    }

    /*
    https://v4.mui.com/api/data-grid/data-grid/
    https://v4.mui.com/components/data-grid/columns/#data-grid-columns
    https://v4.mui.com/components/data-grid/rows/#data-grid-rows
    */

    return (
      <div className={classes.root}>
        
        <MainToolbar />

        <MenuTitle title="Central de alertas" style={{ margin: '0px', marginTop: '5px' }} />

        <div style={{ display: 'flex', marginBottom: '5px' }}>
          
          <div style={{ display: 'flex' }}>
            
            <Select
                value={filterStatus}
                renderValue={(selected) => (
                  <div>
                    {getStatusName(selected[0])}
                    {selected.length > 1 ? " +" + (selected.length-1) : ""}
                  </div>
                )}
                onChange={handleFilterStatusChange}
                multiple={true}
                variant="outlined"
                style={{
                  minWidth: 'fit-content',
                  marginLeft: '5px',
                  width: '50px',
                  height: '30px',
                  borderRadius: '50px',
                  position: 'relative',
                  top: '5px',
                  fontSize: '0.8rem',
                  backgroundColor: filterStatus == "all" ? '' : filterColor,
                }}
                >
                  <MenuItem key="all" value="all">
                    <Checkbox color='primary' checked={filterStatus.indexOf("all") > -1} />
                    <ListItemText primary="Todos os estados" />
                  </MenuItem>
                  { availableStatus.filter(item => item.id != "all").sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox color='primary' checked={filterStatus.indexOf(item.id) > -1} />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
            </Select>

            <Select
              value={filterEvent}
              renderValue={(selected) => (
                <div>
                  {getEventName(selected[0])}
                  {selected.length > 1 ? " +" + (selected.length-1) : ""}
                </div>
              )}
              onChange={handleFilterEventChange}
              multiple={true}
              variant="outlined"
              style={{
                minWidth: 'fit-content',
                marginLeft: '5px',
                width: '50px',
                height: '30px',
                borderRadius: '50px',
                position: 'relative',
                top: '5px',
                fontSize: '0.8rem',
                backgroundColor: filterEvent == "all" ? '' : filterColor,
              }}
              >
                <MenuItem key="all" value="all">
                  <Checkbox color='primary' checked={filterEvent.indexOf("all") > -1} />
                  <ListItemText primary="Todos os eventos" />
                </MenuItem>
                { availableEvents.filter(item => item.id != "all").sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox color='primary' checked={filterEvent.indexOf(item.id) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
            </Select>

            <Select
              value={filterGroup}
              renderValue={(selected) => (
                <div>
                  {getGroupName(selected[0])}
                  {selected.length > 1 ? " +" + (selected.length-1) : ""}
                </div>
              )}
              onChange={handleFilterGroupChange}
              multiple={true}
              variant="outlined"
              style={{
                minWidth: 'fit-content',
                marginLeft: '5px',
                width: '50px',
                height: '30px',
                borderRadius: '50px',
                position: 'relative',
                top: '5px',
                fontSize: '0.8rem',
                backgroundColor: filterGroup == "all" ? '' : filterColor,
              }}
              >
                <MenuItem key="all" value="all">
                  <Checkbox color='primary' checked={filterGroup.indexOf("all") > -1} />
                  <ListItemText primary="Todos os grupos" />
                </MenuItem>
                { groupsArray.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox color='primary' checked={filterGroup.indexOf(item.id) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
            </Select>
            
            <Select
              value={filterDevice}
              renderValue={(selected) => (
                <div>
                  {getDeviceName(selected[0])}
                  {selected.length > 1 ? " +" + (selected.length-1) : ""}
                </div>
              )}
              onChange={handleFilterDeviceChange}
              multiple={true}
              variant="outlined"
              style={{
                minWidth: 'fit-content',
                marginLeft: '5px',
                width: '50px',
                height: '30px',
                borderRadius: '50px',
                position: 'relative',
                top: '5px',
                fontSize: '0.8rem',
                backgroundColor: filterDevice == "all" ? '' : filterColor,
              }}
              >
                <MenuItem key="all" value="all">
                  <Checkbox color='primary' checked={filterDevice.indexOf("all") > -1} />
                  <ListItemText primary="Todos os veículos" />
                </MenuItem>
                { devicesArray.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox color='primary' checked={filterDevice.indexOf(item.id) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
            </Select>
            
            <Select
              value={filterDriver}
              renderValue={(selected) => (
                <div>
                  {getDriverName(selected[0])}
                  {selected.length > 1 ? " +" + (selected.length-1) : ""}
                </div>
              )}
              onChange={handleFilterDriverChange}
              multiple={true}
              variant="outlined"
              style={{
                minWidth: 'fit-content',
                marginLeft: '5px',
                width: '50px',
                height: '30px',
                borderRadius: '50px',
                position: 'relative',
                top: '5px',
                fontSize: '0.8rem',
                backgroundColor: filterDriver == "all" ? '' : filterColor,
              }}
              >
                <MenuItem key="all" value="all">
                  <Checkbox color='primary' checked={filterDriver.indexOf("all") > -1} />
                  <ListItemText primary="Todos os condutores" />
                </MenuItem>
                { driversArray.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    <Checkbox color='primary' checked={filterDriver.indexOf(item.id) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                ))}
            </Select>

            
            <TextField
              style={{
                marginLeft: '5px',
                minWidth: '200px',
                position: 'relative',
                top: '5px'
              }}
              variant="outlined"
              value={filterText}
              onChange={(event) => setFilterText(event.target.value)}
              placeholder={t('search')}
              InputProps={{
                style: {
                  height: '30px',
                  padding: '0px 0px 0px 10px',
                  borderRadius: '50px',
                  fontSize: '0.8rem',
                  backgroundColor: filterText == "" ? '' : filterColor,
                },
                startAdornment: (
                  <div>
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                    <InputAdornment style={{right: '0px', position: 'absolute'}} position='end'>
                      <IconButton
                        onClick={() => setFilterText('')}
                        onMouseDown={() => setFilterText('')}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  </div>
                )
              }}
            />

            <ExportButton
              title='Central de alertas'
              columns={columns}
              data={filterEvents(eventList)}
              filename='Events'
              style={{marginTop: '5px'}}
            />

            <Tooltip title="Actualizar dados">
              <IconButton
                onClick={() => { getEvents(false) }}
                style={{padding: '0px', color: '#78A9FF', marginLeft: '10px', top: '3px'}}
                >
                  <FontAwesomeIcon icon={faSyncAlt} style={{color: '#78A9FF', fontSize: '0.8em'}}/>
              </IconButton>
            </Tooltip>
            
            <span style={{ marginLeft: '10px', position: 'relative', top: '10px', whiteSpace: 'nowrap' }}>
              { filterEvents(eventList).length + " Eventos" }
            </span>

            <Button
              onClick={ (e) => { e.preventDefault(); setShowCalendar(!showCalendar); } }
              color="primary"
              variant="outlined"
              style={{ backgroundColor: 'white', height: '30px', right: '5px', position: 'fixed', marginTop: '5px'}}
            >
              {dateButtonLabel()}
            </Button>

              {showCalendar &&
                <div style= {{ position: 'fixed', marginTop: '40px', right: '5px', zIndex: 999 }}>
                  <DatePicker
                    inline
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(item) => { setStartDate(item[0]); setEndDate(item[1]); if (item[1]) setShowCalendar(false) }}
                    dateFormat="dd/MM/yyyy"
                    todayButton="Hoje"
                    calendarStartDay={1}
                    title="qwertwetwe"
                    isClearable={true}
                    locale={pt}
                    customInput={ () => (<button>xxxxxxxxxx</button>) }
                  />
                </div>
              }
            
          </div>

        </div>


        <div className={classes.content}>

          <Dialog open={infoDialog} onClose={closeInfoDialog} maxWidth="xl" style={{ minWidth: '400px' }}>
            <DialogContent>
              <DialogContentText>

                <Tabs
                  value={dialogTab}
                  onChange={(event, newValue) => setDialogTab(newValue)}
                  variant="fullWidth"
                  TabIndicatorProps={{style: {background: '#78A9FF'}}}
                  textColor="primary"
                  style={{minHeight: '0px'}}
                  >
                    <Tab label="Detalhes" value="details"/>
                    <Tab label="Novo estado" value="newStatus"/>
                    <Tab label="Histórico" value="history"/>
                </Tabs>

                <div style={{ marginTop: '20px' }}>

                  { dialogTab == 'details' && (
                    <div style={{ display: 'flex' }}>
                      <span>
                        <a href={`https://www.google.com/maps/place/${selectedEvent.latitude},${selectedEvent.longitude}`} target="_blank">
                          <img src={`https://api.mapbox.com/styles/v1/mapbox/${mapStyle}/static/pin-l+00b3ff(${selectedEvent.longitude},${selectedEvent.latitude})/${selectedEvent.longitude},${selectedEvent.latitude},15,0/300x300?access_token=pk.eyJ1IjoidHJhY2tpbmdpbnRlZ3JhdGlvbiIsImEiOiJja2t0cGJiZmYwcXFnMnFwYmFsczNwdjAyIn0.jFlT6lSwgMBsyIKvSEIqGQ`} />
                        </a><br></br>
                        <FormControl component="fieldset">
                          <RadioGroup row value={mapStyle} onChange={(e, value) => setMapStyle(value)}>
                            <FormControlLabel value="streets-v12" control={<Radio color="primary" />} label={t('layerRoad')} />
                            <FormControlLabel value="satellite-streets-v12" control={<Radio color="primary" />} label={t('layerSatellite')} />
                          </RadioGroup>
                        </FormControl>
                      </span>
                      <table
                      style={{
                        paddingLeft: '10px'
                      }}>
                        <tbody>
                          <tr>
                            <td><b>{t('type')}:</b></td>
                            <td>{getEventName(selectedEvent.eventType)}</td>
                          </tr>
                          <tr>
                            <td><b>{t('status')}:</b></td>
                            <td>{selectedEvent.lastStatusName}</td>
                          </tr>
                          <tr>
                            <td><b>Detalhes:</b></td>
                            <td>{selectedEvent.details}</td>
                          </tr>
                          <tr>
                            <td><b>Evento:</b></td>
                            <td>{selectedEvent.eventTime}</td>
                          </tr>
                          <tr>
                            <td><b>{t('group')}:</b></td>
                            <td>{groups[selectedEvent.groupId] ? groups[selectedEvent.groupId].name : ''}</td>
                          </tr>
                          <tr>
                            <td><b>{t('vehicle')}:</b></td>
                            <td>{selectedEvent.deviceName}</td>
                          </tr>
                          <tr>
                            <td><b>{t('licensePlate')}:</b></td>
                            <td>{selectedEvent.deviceLicensePlate}</td>
                          </tr>
                          <tr>
                            <td><b>Posição:</b></td>
                            <td>{selectedEvent.fixTime}</td>
                          </tr>
                          <tr>
                            <td><b>{t('address')}:</b></td>
                            <td>{selectedEvent.address}</td>
                          </tr>
                          <tr>
                            <td><b>{t('speed')}:</b></td>
                            <td>{selectedEvent.speed} km/h</td>
                          </tr>
                          <tr>
                            <td><b>{t('driver')}:</b></td>
                            <td>{selectedEvent.driverName}</td>
                          </tr>
                          <tr>
                            <td><b>{t('phone')}:</b></td>
                            <td>{selectedEvent.driverPhone}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  { dialogTab == 'newStatus' && (
                    <div>
                      <FormControl variant="outlined">
                        <InputLabel>Novo estado</InputLabel>
                        <Select
                          value={selectedEvent.lastStatusId}
                          onChange={(event) => setSelectedEvent({...selectedEvent, lastStatusId: event.target.value})}
                          label="Novo estado"
                          >
                          { availableStatus.filter(status => status.id > -1).map(status => (
                              <MenuItem value={status.id}>{status.name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <TextField
                        value={selectedEvent.newStatusDetails}
                        onChange={(event) => setSelectedEvent({...selectedEvent, newStatusDetails: event.target.value})}
                        variant="outlined"
                        autoFocus
                        label="Observações"
                        type="text"
                        fullWidth
                        style={{ marginTop: '10px'}}
                      />
                    </div>
                  )}

                  { dialogTab == 'history' && (
                    <div>

                      { loadingEventHistory &&
                        <CircularProgress
                          size={30}
                          thickness={5}
                          style={{
                            marginTop: '20px',
                            position: 'relative',
                            left: '45%',
                          }}
                          />
                      }

                      { !loadingEventHistory && eventHistory.length > 0 &&
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Estado</TableCell>
                                <TableCell align="left">Data</TableCell>
                                <TableCell align="left">Operador</TableCell>
                                <TableCell align="left">Observações</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {eventHistory.map((event) => (
                                <TableRow key={event.id}>
                                  <TableCell align="left">{event.status}</TableCell>
                                  <TableCell align="left">{event.date}</TableCell>
                                  <TableCell align="left">{event.user}</TableCell>
                                  <TableCell align="left">{event.details}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      }

                      { !loadingEventHistory && eventHistory.length == 0 &&
                        <Typography style={{ width: '100%', textAlign: 'center' }}>
                          Sem histórico.
                        </Typography>
                      }
                    </div>
                  )}
                </div>

              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Tooltip title={t('sendByEmail')}>
                <Button onClick={() => setEmailDialog(true)} color="primary" variant="outlined" style={{ position: 'absolute', left: '25px' }}>
                  <MailOutlineIcon />  
                </Button>
              </Tooltip>
              <Button onClick={closeInfoDialog} color="primary" variant="outlined" style={{paddingLeft: '40px', paddingRight: '40px' }}>
                {t('cancel')}
              </Button>
              { selectedEvent != selectedEventOriginal &&
                <Button onClick={updateEvent} color="primary" variant="contained" style={{paddingLeft: '40px', paddingRight: '40px' }}>
                  {t('save')}
                </Button>
              }
            </DialogActions>

          </Dialog>

          <Dialog open={emailDialog} onClose={() => setEmailDialog(false)}>
            <DialogContent style={{ minWidth: '500px' }}>
              <DialogContentText component={'span'}>

                <Typography>
                  Enviar evento por e-mail
                </Typography>
                <br />

                <TextField
                  value={emailTo}
                  onChange={(event) => setEmailTo(event.target.value)}
                  style={{ minWidth: '100%' }}
                  variant="outlined"
                  label="Para"
                />
                <br />
                <br />

                <TextField
                  value={emailCc}
                  onChange={(event) => setEmailCc(event.target.value)}
                  style={{ minWidth: '100%' }}
                  variant="outlined"
                  label="CC"
                />
                
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={sendemail} color="primary" variant="outlined">
                Enviar
              </Button>
              <Button onClick={() => { setEmailDialog(false) }} color="primary" variant="outlined">
                {t('cancel')}
              </Button>
            </DialogActions>
          </Dialog>
          
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={updateResult}
            autoHideDuration={3000}
            onClose={() => setUpdateResult(false)}
            message={updateResult.message}
            ContentProps={{
              style: {
                backgroundColor: updateResult.color
              }
            }}
            action={
              <React.Fragment>
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => setUpdateResult(false)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />

          <Backdrop className={classes.backdrop} open={loading} onClick={e => setLoading(false)} style={{ zIndex: 999 }}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <Dialog open={resultStatus} onClose={hideStatusDialog}>
            <DialogTitle id="form-dialog-title">
              {statusDialog.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText component="span">
                <Grid container spacing={1}>
                  <Grid item>
                    <img src="images/emoji_worried_face.png" width="32px"/>
                  </Grid>
                  <Grid item style={{ marginTop: '4px' }}>
                    <Box>
                    {statusDialog.message}
                    </Box>
                  </Grid>
                </Grid>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={hideStatusDialog} color="primary" variant="outlined">OK</Button>
            </DialogActions>
          </Dialog>

          <DataGrid
            className={classes.dataGrid}
            rows={filterEvents(eventList)}
            columns={columns}
            pageSize={100}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            rowHeight={30}
            headerHeight={30}
            disableColumnMenu={true}
            localeText={ptBR.props.MuiDataGrid.localeText}
            />

            <span style={{ position: 'absolute', bottom: '20px', left: '20px' }}>
              { availableStatus.filter(status => status.id>-1).map(status => (
                  <span style={{ backgroundColor: status.color, padding: '5px 10px 5px 10px', borderRadius: '20px', marginRight: '10px' }}>
                    {status.name}
                  </span>
                ))
              }
            </span>

        </div>
      </div>
    );
}

export default EventPanel;
