import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from './store';
import { Permissions } from './Permissions';
import packageJson from '../package.json';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import WarningIcon from '@material-ui/icons/Warning';
import WifiOffIcon from '@material-ui/icons/WifiOff';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tooltip from '@material-ui/core/Tooltip';
import t from './common/localization';
import SettingsIcon from '@material-ui/icons/Settings';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';

import Contacts from './Contacts';
import SetDialog from './SetDialog';

import CookieBanner from "react-cookie-banner"
import { isMobile } from './common/common';

const useStyles = makeStyles(theme => ({
  flex: {
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '40px',
    color: 'white',

    backgroundColor: '#78A9FF',
    borderRadius: '20px',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
  appBarMap: {
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '40px',
    color: 'white',

    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))',
  },
  list: {
    width: 'auto'
  },
  listHeader: {
    color: '#EB8F3A',
    backgroundColor: '#F7F7F7',
    borderRadius: '20px',
    paddingLeft: '5px',
    paddingRight: '5px'
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10,

  },
  menuButtonMap: {
    width: 32,
    height: 32,
    marginLeft: 0,
    
  },
  extraMarginLeft: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: '10px',
    }
  },
  blink: {
    animationName: '$blinker',
    animationDuration: '0.5s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
  },
  '@keyframes blinker': {
    from: { opacity: 1 },
    to: { opacity: 0 }, 
  },
}));

const MainToolbar = ({mapView = false}) => {
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const user = useSelector(state => state.session.user);

  const temporaryAccess = user && user.email.startsWith('temp_');

  const openDrawer = () => {
    setDrawer(true)
    setTimeout(() => {
      var element = document.getElementById(global.selectedMenu);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 50);    
  }

  const closeDrawer = () => {
    setDrawer(false)
  }

  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [isOffline, SetIsOffline] = useState(false);
  const [hasMessage, setHasMessage] = useState(false);
  const [profileMenu, setProfileMenu] = useState(null);

  const [showContacts, setShowContacts] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState(
    {
      title: global.client_details ? global.client_details.title : '',
      message: global.client_details ? global.client_details.message : '',
      icon: ''
    });

  useEffect(() => {
    if (global.client_details == null) getClientDetails();
    checkUpdate();
    const interval = setInterval(() => {
      checkUpdate();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  async function checkUpdate() {
    global.currentVersion = packageJson.version;
    setHasMessage(global.client_details && global.client_details.message)
    try {
      const response = await fetch('/meta.json');
      if (response.ok) {
        SetIsOffline(false)
        var latestVersion = await response.json()
        global.latestVersion = latestVersion.version;
        setUpdateAvailable(global.latestVersion != global.currentVersion)
      } else {
        SetIsOffline(true)
      }
    } catch (e) {
      SetIsOffline(true)
    }
  }

  const getClientDetails = async () => {
    const response = await fetch('/report/api.php/client_details');
    if (response.ok) {
      global.client_details = await response.json();
      global.currency = global.client_details.currency_symbol
      global.driving_categories = JSON.parse(global.client_details.driving_categories);

      if (global.client_details && global.client_details.message != null) {
        setMessageContent(
          {
            title: global.client_details.title,
            message: global.client_details.message,
            icon: '',
          })
        setShowMessage(true)
        setHasMessage(true)
      }
    }
  }

  const handleLogout = async () => {
    const response = await fetch('/api/session', { method: 'DELETE' });
    if (response.ok) {
      dispatch(sessionActions.updateUser(null));
      window.location.href = "/";
    }
  }

  //Fullscreen button
  function toggleFullScreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  //Refresh button
  const handleRefresh = async () => {
    if('caches' in window)
    {
      console.log('Clearing cache')
      caches.keys().then((names) => {
        names.forEach(async (name) => {
          await caches.delete(name)
        })
      })
    }
    console.log('Reloading...')
    window.location.reload()
  }

  const menuStyle = (item) => {
    if (item == global.selectedMenu) return {backgroundColor: 'rgba(120, 169, 255, 0.3)', scrollMargin: '75px'};
    return {}
  }

  return (
    <>
      <div id="AppBar" position="static" className={mapView ? classes.appBarMap: classes.appBar}>

        <CookieBanner
          message="Este site utiliza cookies. Ao prosseguir, concorda com a sua utilização."
          link={<></>}
          buttonMessage='Aceitar'
        />

        <Contacts open={showContacts} setOpen={setShowContacts} />
        <SetDialog open={showMessage} setOpen={setShowMessage} dialogContent={messageContent} />

        <Toolbar style={{minHeight: '0px', padding: '0px 10px 0px 10px'}}>
          <Tooltip title={t('menu')}>
            <IconButton
              style={{marginRight: '0px', backgroundColor: mapView ? 'rgba(0,0,0,0.2)' : ''}}
              className={mapView ? classes.menuButtonMap : classes.menuButton}
              onClick={openDrawer}>
              <MenuIcon style={{ color: '#ffffff' }}/>
            </IconButton>          
          </Tooltip>

          <img src="/images/logo_bw.png" width={30} height={30} style={{cursor: 'pointer', marginLeft: mapView ? '10px' : '0px', marginRight: '10px'}} onClick={() => setShowContacts(true)}/>
          <Typography variant="h5" color="inherit" className={classes.flex}>
            <span style={{cursor: 'pointer'}} onClick={() => setShowContacts(true)}>
              ecooGPS
            </span>
          </Typography>

          { hasMessage &&
            <Tooltip title="Aviso">
            <Button color="inherit" onClick={() => setShowMessage(true)} style={{minWidth: '0px', padding: '4px', backgroundColor: 'red'}} className={`${classes.extraMarginLeft} ${classes.blink}`}>
              <ListItemIcon style={{minWidth: '0px', color: 'yellow', position: 'relative', top: '-2px'}}>
                <WarningIcon />
              </ListItemIcon>
            </Button>
            </Tooltip>
          }

          { isOffline &&
          <Tooltip title={t('offline')}>
            <Button id="offlineWarning" color="inherit" onClick={handleRefresh} style={{minWidth: '0px', padding: '4px 15px 4px 15px', backgroundColor: 'rgb(255,0,0)'}} className={classes.extraMarginLeft}>
              <ListItemIcon style={{minWidth: '0px', color: 'inherit'}} >
                <WifiOffIcon style={{paddingRight: '5px'}}/>OFFLINE
              </ListItemIcon>
            </Button>
          </Tooltip>
          }

          { !isOffline && !hasMessage &&

          <Button color="inherit" onClick={handleRefresh} style={{minWidth: '0px'}} >
            v{global.currentVersion}
            {isMobile && <>&nbsp;M</>}
          </Button>
          }

          { !isOffline && updateAvailable &&
          <Tooltip title={t('newVersionAvailable')}>
            <Button id="updateButton" color="inherit" onClick={handleRefresh} style={{minWidth: '0px', padding: '4px', backgroundColor: mapView ? 'rgba(0,0,0,0.2)' : ''}} className={classes.extraMarginLeft}>
              <ListItemIcon style={{minWidth: '0px', color: 'inherit'}}>
                <ErrorOutlineIcon />
              </ListItemIcon>
            </Button>
          </Tooltip>
          }

          <Tooltip title={t('fullscreen')}>
            <Button color="inherit" onClick={toggleFullScreen} style={{minWidth: '0px', padding: '4px', backgroundColor: mapView ? 'rgba(0,0,0,0.2)' : ''}} className={classes.extraMarginLeft}>
              <ListItemIcon style={{minWidth: '0px', color: 'inherit'}} >
                <FullscreenIcon />
              </ListItemIcon>
            </Button>
          </Tooltip>

          { !temporaryAccess &&
            <>
              <Tooltip title={t('settings')}>
                <Button color="inherit" onClick={() => history.push(`/settings/interface/${user.id}`)} style={{minWidth: '0px', padding: '4px', backgroundColor: mapView ? 'rgba(0,0,0,0.2)' : ''}} className={classes.extraMarginLeft}>
                  <ListItemIcon style={{minWidth: '0px', color: 'inherit'}}>
                    <SettingsIcon />
                  </ListItemIcon>
                </Button>
              </Tooltip>

              <Tooltip title={profileMenu ? '' : global.user_details.email}>
                <div>
                  <Button color="inherit" onClick={(event) => setProfileMenu(event.currentTarget)} style={{minWidth: '0px', padding: '4px', backgroundColor: mapView ? 'rgba(0,0,0,0.2)' : ''}} className={classes.extraMarginLeft}>
                    <ListItemIcon style={{minWidth: '0px', color: 'inherit'}} >
                      <PersonIcon />
                    </ListItemIcon>
                  </Button>
                  <Menu
                    anchorEl={profileMenu}
                    keepMounted
                    open={Boolean(profileMenu)}
                    onClose={() => setProfileMenu(null)}
                  >
                    <MenuItem onClick={() => history.push(`/settings/user/${user.id}`)}>
                      <ListItemIcon style={{minWidth: '30px'}}>
                        <EditIcon style={{color: '#78A9FF' }} />
                      </ListItemIcon>
                      <ListItemText primary={t('profile')}/>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <ListItemIcon style={{minWidth: '30px'}}>
                        <ExitToAppIcon style={{color: '#78A9FF' }} />
                      </ListItemIcon>
                      <ListItemText primary={t('logout')}/>
                    </MenuItem>
                  </Menu>
                </div>
              </Tooltip>
            </>
          }
          { temporaryAccess && 
            <Tooltip title={t('logout')}>
              <Button color="inherit" onClick={handleLogout} style={{minWidth: '0px', padding: '4px', backgroundColor: mapView ? 'rgba(0,0,0,0.2)' : ''}} className={classes.extraMarginLeft}>
                <ListItemIcon style={{minWidth: '0px', color: 'inherit'}}>
                  <ExitToAppIcon />
                </ListItemIcon>
              </Button>              
            </Tooltip>
          }
        </Toolbar>
      </div>
      <Drawer open={drawer} onClose={closeDrawer}>
        <div
          tabIndex={0}
          className={classes.list}
          role="button"
          onClick={closeDrawer}
          onKeyDown={closeDrawer}
          style={{ backgroundColor: '#f7f7f7' }}>

          {
            Permissions(user).filter(permission => permission.enabled).map(permission =>
              <List subheader={
                <ListSubheader style={{style: 'fontWeight: bold !important'}}>
                  <span style={{fontWeight: 'bold'}} className={classes.listHeader}>{permission.name}</span>
                </ListSubheader>
              }>
                {
                  permission.items.map(item => ( item.enabled &&
                    <ListItem id={item.menu.path} button onClick={() => history.push(item.menu.path)} style={menuStyle(item.menu.path)}>
                      <ListItemIcon style={{minWidth: '40px'}}>
                        {item.menu.icon}
                      </ListItemIcon>
                      <Tooltip title={item.menu.tooltip}>
                        <ListItemText primary={item.menu.name} />
                      </Tooltip>
                    </ListItem>
                  ))
                }
            </List>           
            )
          }

          <List
            subheader={
              <ListSubheader style={{style: 'fontWeight: bold'}}>
                <span style={{fontWeight: 'bold'}} className={classes.listHeader}>{t('help')}</span>
              </ListSubheader>
            }>
            <ListItem button style={menuStyle(`/manual`)}>
              <a href="/manual/pt/" target="_blank" style={{display: 'inherit', textDecoration: 'none', color: 'black'}}>
                <ListItemIcon style={{minWidth: '40px'}}>
                  <MenuBookIcon style={{color: '#78A9FF'}}/>
                </ListItemIcon>
                <Tooltip title={t('manualTip')}>
                  <ListItemText primary={t('manual')} />
                </Tooltip>
              </a>
            </ListItem>

            <ListItem button onClick={() => setShowContacts(true)} style={menuStyle(`/manual`)}>
              <ListItemIcon style={{minWidth: '40px'}}>
                <MailOutlineIcon style={{color: '#78A9FF'}}/>
              </ListItemIcon>
              <ListItemText primary={t('contacts')} />
            </ListItem>

          </List>
        </div>
      </Drawer>
    </>
  );
}

export default MainToolbar;
