import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getDeviceIconPath, getCategoryDetails } from './../map/mapUtil';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, Radio, RadioGroup, FormControlLabel, FormLabel, Grid, IconButton, Switch } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {SelectWithSearch} from '../form/SelectField';
import { deviceCategories2D, deviceCategories3D } from '../common/deviceCategories';
import TransferList from '../common/TransferList';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const DevicePage = () => {
  const classes = useStyles();

  const [item, setItem] = useState();
  const [showIcons, setShowIcons] = useState(false);

  const [groups, setGroups] = useState(global.groups);
  const [drivers, setDrivers] = useState(global.drivers);

  return (
    <EditItemView endpoint="devices" item={item} setItem={setItem} title={t('vehicles') + ' > ' + t('editVehicle') + ((item && item.attributes && item.attributes.license_plate) ? ` (${item.attributes.license_plate})` : '')}>
      {item &&
        <>
          <Accordion defaultExpanded>

            <AccordionDetails className={classes.details}>
              
              <TextField
                margin="normal"
                variant="outlined"
                value={item.name || ''}
                onChange={event => setItem({...item, name: event.target.value})}
                label={t('name')}
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.contact || ''}
                onChange={event => setItem({...item, contact: event.target.value})}
                label={t('notes')}
                />

              <TextField
                margin="normal"
                variant="outlined"
                value={item.model || ''}
                onChange={event => setItem({...item, model: event.target.value})}
                label={t('model')}
                />
                
              <TextField
                margin="normal"
                variant="outlined"
                value={item.attributes && item.attributes.speedLimit ? Math.round(item.attributes.speedLimit*1.852) : 0}
                onChange={e => setItem({...item, attributes: {...item.attributes, speedLimit: e.target.value/1.852}})}
                label={t('speedLimit')}
                />

              <SelectWithSearch
                label={t('group')}
                items={groups}
                item={item.groupId}
                onChange={value => setItem({...item, groupId: value})}
                style={{marginTop: '10px'}}
                >
              </SelectWithSearch>

              <span style={{
                position: 'relative',
                width: 'fit-content',
                top: '13px',
                left: '8px',
                backgroundColor: 'white',
                paddingLeft: '5px',
                paddingRight: '5px',
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.8rem',
                fontFamily: 'Proxima Nova Regular,Roboto,Arial',
              }}>
                {t('icon')}
              </span>

              <div style={{
                border: 'solid 1px rgba(0,0,0,0.2)',
                borderRadius: '5px',
                marginLeft: '0px',
                marginTop: '5px',
                padding: '20px',
                width: 'fit-content',
              }}>

                { item.category && 
                  <>
                    <Grid container spacing={1} style={{ maxWidth: '900px' }}>
                      <Grid item key={1}>
                        <span style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100px',
                          width: '100px'
                        }}>
                          <img
                            src={getDeviceIconPath(item.category, false)}
                            alt=''
                            style={{
                              width: 70*getCategoryDetails(item.category).zoom,
                              height: 70*getCategoryDetails(item.category).zoom,
                              maxHeight: '100px',
                              maxWidth: '100px',
                              position: 'relative'
                              }}
                          />
                        </span>
                      </Grid>
                      <Grid item key={2} style={{ display: 'flex', alignItems: 'center', paddingLeft: '10px', paddingRight: '10px' }}>
                        <div style={{textAlign: 'center', fontSize: '0.85rem', marginTop: '10px'}}>
                          { t('vehicle_' + getCategoryDetails(item.category).name) }
                        </div>
                      </Grid>
                    </Grid>
                  
                    
                    <Accordion expanded={showIcons} onChange={(event) => setShowIcons(!showIcons)} style={{ marginTop: '20px' }}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1">
                          {t('selectIcon')}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.details}>
                        {showIcons &&
                          [deviceCategories2D, deviceCategories3D].map((deviceCategory, index) => (
                            <>
                              { index == 0 ? '2D:' : '' }
                              { index == 1 ? '3D:' : '' }
                              <br></br>
                              <Grid container spacing={1} style={{ maxWidth: '900px' }}>
                                {deviceCategory.map((category, index) => (
                                  <Grid item key={index}>
                                    <IconButton
                                      onClick={() => { setShowIcons(false); setItem({...item, category: category.name}) }}
                                      style={{
                                        border: category.name == item.category ? 'solid 1px #78A9FF' : 'solid 3px #ffffff',
                                        backgroundColor: category.name == item.category ? 'rgba(120, 169, 255, 0.15)' : '#ffffff',
                                        borderRadius: '150px',
                                        width: 120,
                                        height: 120,
                                      }}
                                    >
                                      <span style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}>
                                        <img
                                          src={getDeviceIconPath(category.name, false)}
                                          alt=''
                                          style={{
                                            width: 45*category.zoom,
                                            maxWidth: '120%',
                                            position: 'relative'
                                            }}
                                        />
                                      </span>
                                    </IconButton>
                                        <div style={{textAlign: 'center', width: '120px', fontSize: '0.7rem'}}>
                                          {t('vehicle_' + category.name)}
                                        </div>
                                  </Grid>
                                ))}
                              </Grid>
                              <br></br>
                            </>
                          ))
                        }
                      </AccordionDetails>
                    </Accordion>
                  </>
                }
              </div>

              <TransferList
                title={t('associatedZones')}
                item={item}
                setItem={setItem}
                element="associated_geofences"
                available={["store", "geofences"]}
                associated={["api", "/api/geofences", "deviceId"]}
                searchKey="deviceId"
                />
              
              <br></br>

              <SelectWithSearch
                label={t('associatedDriver')}
                items={drivers}
                item={item.attributes ? item.attributes.driverId : 0}
                onChange={value => setItem({...item, attributes: {...item.attributes, driverId: value}})}
                style={{marginTop: '10px'}}
                >
              </SelectWithSearch>

{ false &&
              <span style={{ minWidth: '150px', display: 'inline-block', marginTop: '20px' }}>
                <Switch color="primary"
                  checked={item.attributes.week}
                  onChange={event => setItem({...item, attributes: {...item.attributes, week: event.target.checked}})}
                  /> Definir horário profissional
              </span>
}

              { item.attributes.week &&





                <Accordion
                  defaultExpanded={true}
                  style={{ width: 'fit-content', marginBottom: '30px', paddingRight: '20px' }}
                  >
                  <AccordionSummary>
                    <Typography>
                      {t('workingHours')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div>
                        <span style={{ minWidth: '150px', display: 'inline-block' }}>
                          <Switch color="primary"
                            checked={item.attributes.week.weekdays_value}
                            onChange={event => setItem({...item, attributes: {...item.attributes, week: {...item.attributes.week, weekdays_value: event.target.checked}}})}
                            /> {t('week_weekdays')}
                        </span>
                        <span style={{ marginRight: '20px' }}>
                          {t('start')}:&nbsp;
                          <TextField
                            style={{width: '50px'}}
                            InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                            value={item.attributes.week.weekdays_start}
                            onChange={event => setItem({...item, attributes: {...item.attributes, week: {...item.attributes.week, weekdays_start: event.target.value}}})}
                            />                
                        </span>
                        <span>
                          {t('end')}:&nbsp;
                          <TextField
                            style={{width: '50px'}}
                            InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                            value={item.attributes.week.weekdays_end}
                            onChange={event => setItem({...item, attributes: {...item.attributes, week: {...item.attributes.week, weekdays_end: event.target.value}}})}
                            />
                        </span>
                      </div>

                      <div>
                        <span style={{ minWidth: '150px', display: 'inline-block' }}>
                          <Switch color="primary"
                            checked={item.attributes.week.saturday_value}
                            onChange={event => setItem({...item, attributes: {...item.attributes, week: {...item.attributes.week, saturday_value: event.target.checked}}})}
                            /> {t('week_saturday')}
                        </span>
                        <span style={{ marginRight: '20px' }}>
                          {t('start')}:&nbsp;
                          <TextField
                            style={{width: '50px'}}
                            InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                            value={item.attributes.week.saturday_start}
                            onChange={event => setItem({...item, attributes: {...item.attributes, week: {...item.attributes.week, saturday_start: event.target.value}}})}
                            />                
                        </span>
                        <span>
                          {t('end')}:&nbsp;
                          <TextField
                            style={{width: '50px'}}
                            InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                            value={item.attributes.week.saturday_end}
                            onChange={event => setItem({...item, attributes: {...item.attributes, week: {...item.attributes.week, saturday_end: event.target.value}}})}
                            />
                        </span>
                      </div>

                      <div>
                        <span style={{ minWidth: '150px', display: 'inline-block' }}>
                          <Switch color="primary"
                            checked={item.attributes.week.sunday_value}
                            onChange={event => setItem({...item, attributes: {...item.attributes, week: {...item.attributes.week, sunday_value: event.target.checked}}})}
                            /> {t('week_sunday')}
                        </span>
                        <span style={{ marginRight: '20px' }}>
                          {t('start')}:&nbsp;
                          <TextField
                            style={{width: '50px'}}
                            InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                            value={item.attributes.week.sunday_start}
                            onChange={event => setItem({...item, attributes: {...item.attributes, week: {...item.attributes.week, sunday_start: event.target.value}}})}
                            />                
                        </span>
                        <span>
                          {t('end')}:&nbsp;
                          <TextField
                            style={{width: '50px'}}
                            InputProps={{ inputProps: { style: { textAlign: 'center' }} }}
                            value={item.attributes.week.sunday_end}
                            onChange={event => setItem({...item, attributes: {...item.attributes, week: {...item.attributes.week, sunday_end: event.target.value}}})}
                            />
                        </span>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              }

            </AccordionDetails>

          </Accordion>


          {item.id &&
          <>
            <Accordion defaultExpanded={false} style={{marginBottom: '20px'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('technicalDetails')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>

                <TextField
                  margin="normal"
                  variant="outlined"
                  value={(item && item.attributes && item.attributes.chassis) ? item.attributes.chassis : ''}
                  onChange={e => setItem({...item, attributes: {...item.attributes, chassis: e.target.value}})}
                  label={t('chassis')}
                  style={{marginTop: '15px', width: '25%'}}
                  />

                <Grid container spacing={1}>
                  <Grid key="length" item>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      value={(item && item.attributes && item.attributes.chassis) ? item.attributes.length : ''}
                      onChange={e => setItem({...item, attributes: {...item.attributes, length: e.target.value}})}
                      label={t('length')}
                      />
                  </Grid><Grid key="width" item>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      value={(item && item.attributes && item.attributes.chassis) ? item.attributes.width : ''}
                      onChange={e => setItem({...item, attributes: {...item.attributes, width: e.target.value}})}
                      label={t('width')}
                      />
                  </Grid>
                  <Grid key="height" item>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      value={(item && item.attributes && item.attributes.chassis) ? item.attributes.height : ''}
                      onChange={e => setItem({...item, attributes: {...item.attributes, height: e.target.value}})}
                      label={t('height')}
                      />
                  </Grid>
                  <Grid key="weight" item>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      value={(item && item.attributes && item.attributes.chassis) ? item.attributes.weight : ''}
                      onChange={e => setItem({...item, attributes: {...item.attributes, weight: e.target.value}})}
                      label={t('weight')}
                      />
                  </Grid>
                </Grid>

                <TextField
                  margin="normal"
                  variant="outlined"
                  value={(item && item.attributes && item.attributes.occupancy) ? item.attributes.occupancy : 2}
                  onChange={e => setItem({...item, attributes: {...item.attributes, occupancy: e.target.value}})}
                  label={t('occupancy')}
                  style={{marginTop: '15px', width: '25%'}}
                  />

                <TextField
                  margin="normal"
                  variant="outlined"
                  value={(item && item.attributes && item.attributes.emissions) ? item.attributes.emissions : null}
                  onChange={e => setItem({...item, attributes: {...item.attributes, emissions: e.target.value}})}
                  label={t('emissions') + " (CO2, g/km)"}
                  style={{marginTop: '15px', width: '25%'}}
                  />

                <SelectWithSearch
                  label="Categoria"
                  items={global.driving_categories}
                  item={item.attributes && item.attributes.driving_category ? item.attributes.driving_category : ""}
                  onChange={value => setItem({...item, attributes: {...item.attributes, driving_category: value}})}
                  style={{marginTop: '15px', width: '50%'}}
                  originalOrder={true}
                  >
                </SelectWithSearch>

                <Grid item component='fieldset' style={{ borderWidth: '1px', borderRadius: '4px', marginTop: '15px' }}>
                  <FormLabel component="legend" style={{ fontSize: '0.75rem', padding: '0px 5px 0px 5px' }}>Contador de actividade pré-definido</FormLabel>
                  <RadioGroup
                    row
                    value={item && item.attributes && item.attributes.show_hours ? "true" : "false"}
                    onChange={e => setItem({...item, attributes: {...item.attributes, show_hours: e.target.value == "true"}})}
                    defaultValue="false"
                    color="primary"
                    style={{marginTop: '15px'}}
                    >
                    <FormControlLabel value="false" control={<Radio color="primary"/>} label="Km" />
                    <FormControlLabel value="true" control={<Radio color="primary"/>} label={t('hours')} />
                  </RadioGroup>
                </Grid>

              </AccordionDetails>
            </Accordion>
          </>
          }
        </>
      }
    </EditItemView>
  );
}

export default DevicePage;
