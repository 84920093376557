import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useEffectAsync } from '../reactHelper';
import TextField from '@material-ui/core/TextField';

import t from '../common/localization';
import EditItemView from '../EditItemView';
import { Permissions } from '../Permissions';
import { Accordion, AccordionSummary, AccordionDetails, makeStyles, Typography, MenuItem, ListItemText, FormControl, InputLabel, Select, Grid, RadioGroup, FormLabel, FormControlLabel, Radio, Checkbox, Card, Switch } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkField from '../form/LinkField';
import SelectField from '../form/SelectField';
import { addEditString } from '../common/stringUtils';
import { getDeviceName } from '../map/mapUtil';
import TransferList from '../common/TransferList';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const UserPage = () => {
  const classes = useStyles();

  const [item, setItem] = useState({
    attributes: {
      timezone: global.user && global.user.attributes && global.user.attributes.timezone ? global.user.attributes.timezone : 'Europe/Lisbon',
      language: global.user && global.user.attributes && global.user.attributes.language ? global.user.attributes.language : 'pt',
    }
  });

  const [permissions, setPermissions] = useState([]);
  const allowAddRemove = !item.id || (item.id && item.id != global.user.id);

  const languages = [
    {"id": "pt", "name": "Português"},
    {"id": "en", "name": "English"},
    {"id": "es", "name": "Español"},
    {"id": "fr", "name": "Français"},
    {"id": "ar", "name": "Arabic"}
  ];

  const timezones = [
    {"id": "Africa/Luanda", "name": "Africa/Luanda"},
    {"id": "Africa/Maputo", "name": "Africa/Maputo"},
    {"id": "Africa/Blantyre", "name": "Africa/Blantyre"},
    {"id": "Europe/Lisbon", "name": "Europe/Lisbon"},
    {"id": "Europe/Paris", "name": "Europe/Paris"},
    {"id": "Europe/Madrid", "name": "Europe/Madrid"},
    {"id": "Atlantic/Cape_Verde", "name": "Atlantic/Cape_Verde"},
    {"id": "Africa/Sao_Tome", "name": "Africa/Sao_Tome"},
  ];

  const extra_permissions = [
    {
      "permission": "global.block",
      "name": "Bloquear/Desbloquear veículos"
    },
    {
      "permission": "global.show_gsm",
      "name": "Mostrar número GSM na lista de veículos"
    },
    {
      "permission": "global.alert_seatbelt_lights",
      "name": "Alerta de luzes e cinto de segurança"
    },
  ];

  useEffect(() => {
    var majorPermissions = []
    Permissions(user).filter(permission => permission.enabled).forEach(majorPermission => {
      var minorPermissions = [];
      majorPermission.items.filter(permission => permission.enabled).forEach(minorPermission => {
        minorPermissions.push({
          permission: minorPermission.permission,
          menu: { name: minorPermission.menu.name }
        })
      });
      if (minorPermissions.length>0) {
        majorPermissions.push({
          permission: majorPermission.permission,
          name: majorPermission.name,
          items: minorPermissions,
        })        
      }
    });
    setPermissions(majorPermissions);
  }, [])

  const isPermissionAvailable = (permission) => {
    return user && user.attributes && user.attributes.permissions && user.attributes.permissions.includes(permission)
  }

  const getPermission = (permission) => {
    return item && item.attributes && item.attributes.permissions && item.attributes.permissions.includes(permission)
  }

  const setPermission = (permission, checked) => {
    var newPermissions = item && item.attributes && item.attributes.permissions ? item.attributes.permissions : [];
    if (checked) {
      if (!newPermissions.includes(permission)) {
        newPermissions.push(permission);
      }
    } else {
      newPermissions = newPermissions.filter(newPermission => newPermission != permission);
    }
    
    setItem({...item, attributes: {...item.attributes, permissions: newPermissions}})
  }

  const getPermissionStyle = (MajorPermission, MajorIndex, MinorPermission, MinorIndex) => {
    var result = {
      borderLeftStyle: 'solid',
      borderLeftColor: '#aaaaaa',
      borderLeftWidth: '2px',
      borderLeftRadius: '20px',
      
      borderRightStyle: 'solid',
      borderRightColor: '#aaaaaa',
      borderRightWidth: '2px',
      borderRightRadius: '20px',
    }

    if (MinorIndex == 0) result = Object.assign({}, result, {
      borderTopStyle: 'solid',
      borderTopColor: '#aaaaaa',
      borderTopWidth: '2px',
      borderTopRadius: '20px',
    });

    if (MajorIndex+1 == permissions.length && MinorIndex+1 == MajorPermission.items.length) result = Object.assign({}, result, {
      borderBottomStyle: 'solid',
      borderBottomColor: '#aaaaaa',
      borderBottomWidth: '2px',
      borderBottomRadius: '20px',
    });
    return result;
  }

  const toggleMajorPermission = (MajorPermissionToToggle) => {
    var newPermissions = item && item.attributes && item.attributes.permissions ? item.attributes.permissions : [];
    var permissionFound = false;

    permissions.filter(a => a.permission == MajorPermissionToToggle.permission)[0].items.forEach(element => {
      if (newPermissions.includes(MajorPermissionToToggle.permission + "." + element.permission)) {
        permissionFound = true;
      }
    });

    permissions.filter(a => a.permission == MajorPermissionToToggle.permission)[0].items.forEach(element => {
      if (permissionFound) {
        newPermissions = newPermissions.filter(newPermission => newPermission != (MajorPermissionToToggle.permission + "." + element.permission));
      } else {
        if (!newPermissions.includes(MajorPermissionToToggle.permission + "." + element.permission)) {
          newPermissions.push(MajorPermissionToToggle.permission + "." + element.permission);
        }
      }
    });

    setItem({...item, attributes: {...item.attributes, permissions: newPermissions}})
  }

  return (
    <EditItemView endpoint="users" item={item} setItem={setItem} title={ t('users') + ' > ' + addEditString(t('addUser'), t('editUser')) } >
      {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionDetails className={classes.details}>
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.name || ''}
                onChange={event => setItem({...item, name: event.target.value})}
                label={t('name')}
                />
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.email || ''}
                onChange={event => setItem({...item, email: event.target.value})}
                label={t('email')}
                />
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                type="password"
                onChange={event => setItem({...item, password: event.target.value})}
                label={t('password')}
                />
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth={true}
                value={item.phone || ''}
                onChange={event => setItem({...item, phone: event.target.value})}
                label={t('phone')}
                />

              <SelectField
                margin="normal"
                value={item.attributes && item.attributes.timezone ? item.attributes.timezone : 'Africa/Luanda'}
                emptyValue={null}
                onChange={event => setItem({...item, attributes: {...item.attributes, timezone: event.target.value}})}
                data={timezones}
                label={t('timezone')}
                />

             <TextField
                select
                style={{marginTop: '15px'}}
                value={item.attributes && item.attributes.language ? item.attributes.language : 'pt'}
                onChange={event => setItem({...item, attributes: {...item.attributes, language: event.target.value}})}
                label={t('language')}
              >
                {languages.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    <img width="20" src={'images/' + item.id + '.svg'} />
                    <ListItemText primary={ item.name } style={{display: 'inline-block', paddingLeft: '10px'}} />
                  </MenuItem>
                ))}
              </TextField>

            </AccordionDetails>
          </Accordion>

          { user.id != item.id &&
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('permissions')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                
                <FormControlLabel
                  label={t('canLogin')}
                  style={{display: 'block'}}
                  control={
                    <Switch
                      color="primary"
                      checked={!item.disabled || false}
                      onChange={event => setItem({...item, disabled: !event.target.checked})}
                    />}
                />
                <FormControlLabel
                  label={t('canMakeChanges')}
                  style={{display: 'block'}}
                  control={
                    <Switch
                      color="primary"
                      checked={!item.readonly || false}
                      onChange={event => setItem({...item, readonly: !event.target.checked})}
                    />}
                />

                {
                  extra_permissions.map(permission => 
                    isPermissionAvailable(permission.permission) &&
                    <FormControlLabel
                      label={permission.name}
                      style={{display: 'block'}}
                      control={
                        <Switch
                          color="primary"
                          checked={ getPermission(permission.permission) || false }
                          onChange={ event => setPermission(permission.permission, event.target.checked) }
                        />}
                    />
                  )
                }
                <br></br>

                <InputLabel>Menus</InputLabel>
                <table style={{ width: 'fit-content', borderCollapse: 'collapse', marginTop: '10px' }}>
                  { permissions.map((MajorPermission, MajorIndex) => (
                    <>
                      { MajorPermission.items.map((MinorPermission, MinorIndex) => (
                        <tr style={getPermissionStyle(MajorPermission, MajorIndex, MinorPermission, MinorIndex)}>
                          { MinorIndex == 0 &&
                            <td rowspan={MajorPermission.items.length} style={{ paddingLeft: '20px', fontWeight: 'bold' }}>
                              <span
                                style={{ cursor: 'pointer', fontWeight: 'bold'}}
                                onClick={() => toggleMajorPermission(MajorPermission)}
                                >
                                { MajorPermission.name }
                              </span>
                            </td>
                          }
                          <td style={{ marginTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
                            <FormControlLabel
                              label={ MinorPermission.menu.name }   
                              style={{paddingTop: '0px', display: 'block'}}
                              control={
                                <Switch
                                  color="primary"
                                  checked={ getPermission(MajorPermission.permission + "." + MinorPermission.permission) || false }
                                  onChange={ event => setPermission(MajorPermission.permission + "." + MinorPermission.permission, event.target.checked) }
                                />}
                            />
                          </td>
                        </tr>
                      ))}
                    </>
                  ))}
                </table>
                <br></br>

              </AccordionDetails>
            </Accordion>
          }

          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('associations')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>

              <TransferList
                title={t('associatedVehicles')}
                item={item}
                setItem={setItem}
                element="associated_devices"
                available={["store", "devices"]}
                associated={["api", "/api/devices", "userId"]}
                allowAdd={allowAddRemove}
                allowRemove={allowAddRemove}
                />

              <TransferList
                title={t('associatedGroups')}
                item={item}
                setItem={setItem}
                element="associated_groups"
                available={["store", "groups"]}
                associated={["api", "/api/groups", "userId"]}
                allowAdd={allowAddRemove}
                allowRemove={allowAddRemove}
                />

              <TransferList
                title={t('associatedZones')}
                item={item}
                setItem={setItem}
                element="associated_geofences"
                available={["store", "geofences"]}
                associated={["api", "/api/geofences", "userId"]}
                allowAdd={allowAddRemove}
                allowRemove={allowAddRemove}
                />

            </AccordionDetails>
          </Accordion>
        </>
      }
    </EditItemView>
  );
}

export default UserPage;
