import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainToolbar from './MainToolbar';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorIcon from '@material-ui/icons/Error';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { getItemGroups } from './common/apiHelper';
import t from './common/localization';
import { useEffectAsync } from './reactHelper';
import { positionsActions, devicesActions, groupsActions, driversActions, geofencesActions, sessionActions } from './store';
import { validateEmail, generateRandomString } from './common/stringUtils';

import MenuTitle from './MenuTitle';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
}));

const EditItemView = ({ children, endpoint, item, setItem, style = {}, title = false }) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();

  document.getElementById('root').style.overflow = 'auto';

  const [warningVisible, setWarningVisible] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  const handleWarningCancel = () => {
    setWarningVisible(false);
  };

  useEffectAsync(async () => {
    if (id) {
      var actualEndpoint = endpoint;
      if (endpoint == 'temporary_links') actualEndpoint = 'users';

      const response = await fetch(`/api/${actualEndpoint}/${id}`);
      if (response.ok) {
        var newItem = await response.json();

        if (endpoint.includes('report_scheduler')) {
          if (newItem.geofences != '') {
            newItem.geofence_filter_value = 1;
          }
        }

        setItem(newItem);
      }
    }
  }, [id]);
  
  const handlePermissions = async (add, objects) => {
    if (objects.length>0) {
      return fetch('/api/permissions/bulk', {
        method: add ? 'POST' : 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(objects),
      });
    }
  }

  const handleSave = async () => {

    var errorMessage = false;

    if (global.user_details.readonly) {
      errorMessage = t('readOnlyUser')
    }

    const elementsToDelete = [
      'automaticMaintenance',
      'automaticMaintenancePeriodicity'
    ]

    elementsToDelete.forEach(elementToDelete => {
      if (item.hasOwnProperty(elementToDelete)) {
        delete item[elementToDelete];
      }
    });

    if (endpoint == 'users')
    {
      if (item.id == global.userId) {
        global.user_details = item;
        global.language = item['attributes']['language'] || 'pt';       
      }      
      if (!item.attributes) {
        item.attributes = {}
        if (!item.attributes.language) {
          item.attributes.language = 'pt'
        }
        if (!item.attributes.timezone) {
          item.attributes.timezone = 'Africa/Luanda'
        }
      }
      if (item.name == '' || item.name == null) {
        errorMessage = t('invalidName')
      }
      if (item.attributes && item.attributes.language && item.attributes.language == "") {
        item.attributes.language = 'pt'
      }
      item.token = generateRandomString(128);

      item.userLimit = item && item.attributes && item.attributes.permissions && item.attributes.permissions.includes("settings.users") ? 100 : 0;
    }

    if (endpoint == 'temporary_links')
    {
      item = {
        id: item.id,
        name: item.name,
        expirationTime: typeof(item.expirationTime) == "string" ? item.expirationTime : item.expirationTime.toISOString(),
        email: "temp_" + generateRandomString(30),
        password: generateRandomString(30),
        readonly: true,
        administrator: false,
        token: item.hasOwnProperty('token') ? item.token : generateRandomString(40),
        associated_devices: item.associated_devices,
        associated_geofences: item.associated_geofences,
        attributes: {
          language: 'pt',
          timezone: 'Africa/Luanda',
          allowHistory: item.attributes.allowHistory,
          allowHistoryFrom: typeof(item.attributes.allowHistoryFrom) == "string" ? item.attributes.allowHistoryFrom : item.attributes.allowHistoryFrom.toISOString(),
          allowHistoryTo: typeof(item.attributes.allowHistoryTo) == "string" ? item.attributes.allowHistoryTo : item.attributes.allowHistoryTo.toISOString(),
          map_showGeofences: true,
          map_showGeofenceNames: true,
        }
      }
      
      if (item.attributes.devices == "")  errorMessage = "Deve seleccionar pelo menos 1 veículo"
      if (!item.name)                     errorMessage = "Deve preencher o nome do acesso temporário"
    }

    if (endpoint == 'notifications')
    {
      if (item.notificators && item.notificators.includes("mail")) {
        if (item.attributes && item.attributes.hasOwnProperty('emailTo')) {
          if (item.attributes.emailTo != "") {
            item.attributes.emailTo = item.attributes.emailTo.replaceAll(' ', '').replaceAll("\t", '').replaceAll("\n", '').replaceAll(';', ',')
            item.attributes.emailTo.split(',').forEach((emailAddress) => {
              if (!validateEmail(emailAddress)) {
                errorMessage = t('verifySchedulerInvalidEmail') + (emailAddress != '' ? ": " + emailAddress : '')
              }
            });
            item.attributes.emailTo = item.attributes.emailTo.replaceAll(',', ', ')
          } else {
            delete item.attributes.emailTo;
          }
        }
        if (item.attributes && item.attributes.hasOwnProperty('emailCc')) {
          if (item.attributes.emailCc != "") {
            item.attributes.emailCc = item.attributes.emailCc.replaceAll(' ', '').replaceAll("\t", '').replaceAll("\n", '').replaceAll(';', ',')
            item.attributes.emailCc.split(',').forEach((emailAddress) => {
              if (!validateEmail(emailAddress)) {
                errorMessage = t('verifySchedulerInvalidEmail') + (emailAddress != '' ? ": " + emailAddress : '')
              }
            });
            item.attributes.emailCc = item.attributes.emailCc.replaceAll(',', ', ')
          } else {
            delete item.attributes.emailCc;
          }
        }
      }

      if (!item.hasOwnProperty('attributes')) {
        item.attributes = {}
      }

      if (
          item.type == 'lowBattery'
          || item.type == 'sos'
          || item.type == 'hardAcceleration'
          || item.type == 'hardBraking'
          || item.type == 'hardCornering'
          || item.type == 'tampering'
          ) {
        item.attributes.alarms = item.type;
        item.type = 'alarm';
      }

      if (item.type == 'seatbeltAndLights') {
        item.attributes.alarms = 'seatbelt,lights,seatbelt+lights';
        item.type = 'alarm';
      }

      if (item.notificators == '') {
        errorMessage = t('verifyAlertNotificators')
      }

      if (item.type == '') {
        errorMessage = t('verifyAlertType')
      }
    }

    if (endpoint == 'drivers')
    {
      if (item.hasOwnProperty('attributes')
          && item.attributes.hasOwnProperty('email')
          && item.attributes.email!=""
          && !validateEmail(item.attributes.email)) {
        errorMessage = t('verifySchedulerInvalidEmail') + ": " + item.attributes.email
      }
      if (item.uniqueId == "" || !item.hasOwnProperty('uniqueId')) {
        item.uniqueId = "x."+generateRandomString(14);
      }
    }

    if (endpoint.includes('report_scheduler'))
    {
      //Parse e-mail list
      item.report_destination = item.report_destination.replaceAll(' ', '')
      item.report_destination = item.report_destination.replaceAll("\t", '')
      item.report_destination = item.report_destination.replaceAll("\n", '')
      item.report_destination = item.report_destination.replaceAll(';', ',')
      item.report_destination.split(',').forEach((emailAddress) => {
        console.log(emailAddress + " -> " + validateEmail(emailAddress))
        if (!validateEmail(emailAddress)) {
          errorMessage = t('verifySchedulerInvalidEmail') + (emailAddress != '' ? ": " + emailAddress : '')
        }
      });
      item.report_destination = item.report_destination.replaceAll(',', ', ')

      if (!item.geofence_filter_value && item.report_type == 'speeding') delete item.geofences;

      if (item.report_ids == "") {
        errorMessage = t('verifySchedulerVehicles')
      }

    }

    if (endpoint.includes('api.php/maintenance'))
    {
      //if (!item.cost)                 errorMessage = "Deve indicar o custo da manutenção"
      //if (!item.periodicity_km)       errorMessage = "Deve indicar a periodicidade futura da manutenção"
      if (!item.km)                   errorMessage = "Deve indicar os km em que ocorreu a manutenção"
      if (!item.description)          errorMessage = "Deve indicar a descrição da manutenção"
      if (item.complete==1 && !item.date_end) errorMessage = "Deve indicar a data de fim da manutenção"
      if (item.complete==0)           item.date_end = null
      if (!item.date_start)           errorMessage = "Deve indicar a data de início da manutenção"

      if (item.cost) item.cost = item.cost.replaceAll(',', '.')
      if (item.cost && isNaN(item.cost))           errorMessage = "O custo deve ser numérico"
      if (item.periodicity_km && isNaN(item.periodicity_km)) errorMessage = "A periodicidade deve ser numérica"
      if (isNaN(item.km))             errorMessage = "Os km devem ser numéricos"

      if (item.cost && item.cost == '') delete item.cost;
      if (item.periodicity_km && item.periodicity_km == '') delete item.periodicity_km;
    }

    if (endpoint.includes('api.php/cost'))
    {
      if (item.cost) item.cost = item.cost.replaceAll(',', '.')
      if (isNaN(item.cost))  errorMessage = "O valor do custo deve ser numérico"
      if (!item.cost || item.cost == '')        errorMessage = "Deve indicar o valor do custo"
      if (!item.designation)                    errorMessage = "Deve indicar a descrição do custo"
      if (!item.cost_date)                      errorMessage = "Deve indicar a data do custo"
      if (item.cost_type==null)                 errorMessage = "Deve indicar o tipo de custo"
      if (item.warning==1 && !item.expiry_date) errorMessage = "Deve indicar a data de expiração do custo"
      if (item.warning==0)                      item.expiry_date = null
    }

    if (endpoint.includes('api.php/document'))
    {
      if (!item.designation || item.designation == "")  errorMessage = "Deve indicar a descrição do documento"
      if (item.document_type==null)                     errorMessage = "Deve indicar o tipo do documento"
      if (item.warning==1 && !item.expiry_date)         errorMessage = "Deve indicar a data de expiração do documento"
      if (item.warning==0)                              item.expiry_date = null
    }

    if (endpoint.includes('api.php/refill') && !endpoint.includes('api.php/refill_'))
    {
      if (item.cost) item.cost = item.cost.replaceAll(',', '.')
      if (isNaN(item.cost))  errorMessage = "O valor do custo deve ser numérico"
      if (!item.cost || item.cost == '')        errorMessage = "Deve indicar o valor do custo"
      if (!item.cost_date)                      errorMessage = "Deve indicar a data do custo"
    }

    var originalItem = { ...item }
    
    if (endpoint == 'groups')
    {
      delete item.devices;
      delete item.users;
      delete item.geofences;
      delete item.drivers;
    }
    
    if (item.associated_devices) delete item.associated_devices;
    if (item.associated_groups) delete item.associated_groups;
    if (item.associated_geofences) delete item.associated_geofences;
    if (item.associated_users) delete item.associated_users;
    if (item.associated_drivers) delete item.associated_drivers;
    if (item.associated_notifications) delete item.associated_notifications;
    if (item.selectedGroups) delete item.selectedGroups;
    
    if (errorMessage) {
      setWarningMessage(errorMessage)
      setWarningVisible(true)
    } else {
      var actualEndpoint = endpoint;
      if (endpoint == 'temporary_links') actualEndpoint = 'users';

      let url = `/api/${actualEndpoint}`;
      if (id) {
        url += `/${id}`;
      }

      const response = await fetch(url, {
        method: !id ? 'POST' : 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(item),
      });

      if (response.ok) {

        var newItem = null;
        
        try {
          newItem = await response.json()
        } catch (error) {
          endpoint = null;
        }
        
        if (endpoint == 'devices') {
          if (originalItem.associated_geofences) {
            await handlePermissions(true, originalItem.associated_geofences.toAdd.map((geofenceId) => {return {deviceId: newItem.id, geofenceId}}));
            await handlePermissions(false, originalItem.associated_geofences.toDelete.map((geofenceId) => {return {deviceId: newItem.id, geofenceId}}));
          }

          const response_devices = await fetch('/api/devices');
          if (response_devices.ok) dispatch(devicesActions.refresh(await response_devices.json()));
        }
  
        if (endpoint == 'users' && newItem.id != global.user.id) {
          if (originalItem.associated_devices) {
            await handlePermissions(true, originalItem.associated_devices.toAdd.map((deviceId) => {return {userId: newItem.id, deviceId}}));
            await handlePermissions(false, originalItem.associated_devices.toDelete.map((deviceId) => {return {userId: newItem.id, deviceId}}));
          }
          
          if (originalItem.associated_geofences) {
            await handlePermissions(true, originalItem.associated_geofences.toAdd.map((geofenceId) => {return {userId: newItem.id, geofenceId}}));
            await handlePermissions(false, originalItem.associated_geofences.toDelete.map((geofenceId) => {return {userId: newItem.id, geofenceId}}));
          }

          if (originalItem.associated_groups) {
            await handlePermissions(true, originalItem.associated_groups.toAdd.map((groupId) => {return {userId: newItem.id, groupId}}));
            await handlePermissions(false, originalItem.associated_groups.toDelete.map((groupId) => {return {userId: newItem.id, groupId}}));
          }
        }
  
        if (endpoint == 'temporary_links') {
          if (originalItem.associated_devices) {
            await handlePermissions(true, originalItem.associated_devices.toAdd.map((deviceId) => {return {userId: newItem.id, deviceId}}));
            await handlePermissions(false, originalItem.associated_devices.toDelete.map((deviceId) => {return {userId: newItem.id, deviceId}}));
          }

          if (originalItem.associated_geofences) {
            await handlePermissions(true, originalItem.associated_geofences.toAdd.map((geofenceId) => {return {userId: newItem.id, geofenceId}}));
            await handlePermissions(false, originalItem.associated_geofences.toDelete.map((geofenceId) => {return {userId: newItem.id, geofenceId}}));
          }
        }

        if (endpoint == 'drivers') {
          if (originalItem.associated_groups) {
            await handlePermissions(true, originalItem.associated_groups.toAdd.map((groupId) => {return {groupId, driverId: newItem.id}}));
            await handlePermissions(false, originalItem.associated_groups.toDelete.map((groupId) => {return {groupId, driverId: newItem.id}}));
          }
        }

        if (endpoint == 'geofences') {
          if (originalItem.associated_devices) {
            await handlePermissions(true, originalItem.associated_devices.toAdd.map((deviceId) => {return {deviceId, geofenceId: newItem.id}}));
            await handlePermissions(false, originalItem.associated_devices.toDelete.map((deviceId) => {return {deviceId, geofenceId: newItem.id}}));
          }

          if (originalItem.associated_groups) {
            await handlePermissions(true, originalItem.associated_groups.toAdd.map((groupId) => {return {groupId, geofenceId: newItem.id}}));
            await handlePermissions(false, originalItem.associated_groups.toDelete.map((groupId) => {return {groupId, geofenceId: newItem.id}}));
          }
        }

        if (endpoint == 'notifications') {
          if (originalItem.associated_devices) {
            await handlePermissions(true, originalItem.associated_devices.toAdd.map((deviceId) => {return {deviceId, notificationId: newItem.id}}));
            await handlePermissions(false, originalItem.associated_devices.toDelete.map((deviceId) => {return {deviceId, notificationId: newItem.id}}));
          }
        }

        if (endpoint == 'groups') {

          //Add devices one by one. Don't allow to remove devices from group.
          if (originalItem.associated_devices && originalItem.associated_devices.toAdd.length>0) {
            var response_devices = await fetch('/api/devices');
            if (response_devices.ok) {
              const devices = await response_devices.json();
              devices.forEach(async device => {
                if (originalItem.associated_devices.toAdd.includes(device.id) && device.groupId != newItem.id) {
                  
                  device.groupId = newItem.id;

                  await fetch(`/api/devices/${device.id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(device),
                  });
                }
              });
            }            
          }
          if (originalItem.associated_devices && originalItem.associated_devices.toDelete.length>0) {
            alert('Não serão removidos veículos do grupo actual. Para remover veículos do grupo por favor adicione-os a outro grupo.')
          }
          
          //Prevent remove current user association
          if (originalItem.associated_users) {
            if (originalItem.associated_users.toDelete.indexOf(userId) !== -1) 
              originalItem.associated_users.toDelete.splice(originalItem.associated_users.toDelete.indexOf(userId), 1);
            await handlePermissions(true, originalItem.associated_users.toAdd.map((userId) => {return {userId, groupId: newItem.id}}));
            await handlePermissions(false, originalItem.associated_users.toDelete.map((userId) => {return {userId, groupId: newItem.id}}));
          }

          if (originalItem.associated_geofences) {
            await handlePermissions(true, originalItem.associated_geofences.toAdd.map((geofenceId) => {return {groupId: newItem.id, geofenceId}}));
            await handlePermissions(false, originalItem.associated_geofences.toDelete.map((geofenceId) => {return {groupId: newItem.id, geofenceId}}));
          }
          
          if (originalItem.associated_drivers) {
            await handlePermissions(true, originalItem.associated_drivers.toAdd.map((driverId) => {return {groupId: newItem.id, driverId}}));
            await handlePermissions(false, originalItem.associated_drivers.toDelete.map((driverId) => {return {groupId: newItem.id, driverId}}));
          }
        }
        
        history.goBack();
      }
      else
      {
        response.text().then(function (text) {
          var error_details = 'Error ' + response.status + ': ' + response.statusText
          error_details += text.split('(')[0].split('-')[0]
          alert(error_details)
        });
        
      }
    }
  };


  return (
    <>

      <Dialog open={warningVisible} onClose={handleWarningCancel}>
        <DialogTitle id="form-dialog-title">
          Erro
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'span'}>

            <Grid container spacing={1}>
                <Box>
                  <ErrorIcon fontSize="medium" style={{color: '#bb0000'}}/>
                </Box>
                &nbsp;{warningMessage}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWarningCancel} color="primary" variant="outlined">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <MainToolbar />
      <Container className={classes.container} style={{ maxWidth: '100%', paddingLeft: '21px', marginTop: '13px' }}>

        { title &&
          <MenuTitle title={title} />
        }

        {children}
        <FormControl margin='normal' fullWidth={true}>
          <div className={classes.buttons} style={{paddingTop: '20px', paddingBottom: '10px' }}>
            <Button type='button' color='primary' variant='outlined' onClick={() => history.goBack()} style={{paddingLeft: '40px', paddingRight: '40px' }}>
              {t('cancel')}
            </Button>
            <Button type='button' color='primary' variant='contained' onClick={handleSave} style={{paddingLeft: '40px', paddingRight: '40px' }}>
              {t('save')}
            </Button>
          </div>
        </FormControl>
      </Container>
    </>
  );
}

export default EditItemView;
